import React, { Component } from 'react';
import Header from '../../components/header/Header';
import SetTitle from '../../components/shared/SetTitle';

class VersionIndex extends Component {
  render() {
    return (
      <div>
        <SetTitle title={"Version"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">App Version</h2>

          <div className="widgets">
            <br />
            Running version: <strong>{process.env.REACT_APP_COMMIT_HASH}</strong><br />
            Last build date: <strong>{process.env.REACT_APP_BUILD_DATE}</strong>
          </div>
        </div>

      </div>
    );
  }
}

export default VersionIndex;
