import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateTicket from '../../components/support/UpdateTicket';
import CreateTicket from '../../components/support/CreateTicket';


import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfTickets extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
  }

  state = {
    tickets: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/support/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/support/${id}`)
  }

  renderTicketTitle(unread, title, id) {
    if (unread) {
      return (
        <div className="td" id={id}>
          <div className="unread-ticket"></div>
          {title}
        </div>
      )
    }
    else {
      return (
        <div className="td">{title}</div>
      )
    }
  }

  render() {
    const { tickets, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of tickets</h3>

          <Link to={'/support/new'} onClick={this.handleNewPanel}>
            <div className="button new-button">New Ticket</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Title</div>
              <div className="th">Status</div>
              <div className="th">Category</div>
              <div className="th">Priority</div>
              <div className="th">Body</div>
              <div className="th">Created at</div>
            </div>
            {tickets.map((ticket) => (
                <Link to={`/support/${ticket.id}`} onClick={this.handleEditPanel} id={ticket.id} key={ticket.id} className="tr">
                  {this.renderTicketTitle(ticket.unread, ticket.title, ticket.id)}
                  <div className="td">{ticket.status}</div>
                  <div className="td">{ticket.category}</div>
                  <div className="td">{ticket.priority}</div>
                  <div className="td">{ticket.body}</div>
                  <div className="td">{ticket.created_at}</div>
                </Link>
            ))}
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Ticket" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/support');
              this.componentDidMount();
            }}>
            <CreateTicket />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Ticket" width="60%"
            onRequestClose={() => {
              this.setState({ editPanelToggle: false });
              this.setState({ editId: null });
              this.props.history.push('/support');
              this.componentDidMount();
            }}>
            <UpdateTicket id={this.state.editId} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/support")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ tickets: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfTickets);
