import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateUser from '../../components/users/UpdateUser';
import CreateUser from '../../components/users/CreateUser';

import { combine, hideElementForNonAdmins } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfUsers extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);

    this.handleCheckbox = this.handleCheckbox.bind(this);

    this.handleInviteSupport = this.handleInviteSupport.bind(this);
    this.handleRevokeSupport = this.handleRevokeSupport.bind(this);

    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
  }

  state = {
    users: [],
    partial: "",
    searchedUsers: null,
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false,
    showInactive: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });

    this.props.history.push(`/settings/users/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editId: null,
      editPanelToggle: false,
    });
    this.props.history.push('/settings/users');
    this.componentDidMount();
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleInviteSupport(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/invite_support`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ users: data, loaded: true })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handleRevokeSupport(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/revoke_support`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ users: data, loaded: true })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandlePersonSearch(value)
  }

  handlePersonSearch(partial) {
    if (partial === "") {
      return this.setState({ searchedUsers: null })
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/?user[search]=${partial}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ searchedUsers: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  supportUserSection(users) {
    let supportUser = users.filter((user) => user.support === true)[0]

    if (supportUser === undefined) {
      return (
        <div className="support-user" style={{marginTop: "30px", marginBottom: "100px"}}>
          <div className={`button new-button ${hideElementForNonAdmins()}`} onClick={this.handleInviteSupport}>Invite Support</div>
        </div>
      )
    }
    else {
      return (
        <div className="support-user">
          <h3 className="inline-block top-padding">Support User</h3>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Last Login</div>
            </div>

            <div className="tr">
              <div className="td">{supportUser.first_name} {supportUser.last_name}</div>
              <div className="td">{supportUser.last_login}</div>
            </div>
          </div>

          <div className={`button new-button red-button ${hideElementForNonAdmins()}`}onClick={this.handleRevokeSupport}>Revoke Support</div>
        </div>
      )
    }
  }

  renderInactiveUsers(users) {
    // check that at least one user is inactive. Otherwise, don't render anything
    if (users.map((user) => (user.inactive)).includes(true)) {
      if (this.state.showInactive) {
        return (
          <div>
            {this.inactiveUsersToggle()}

            <div className="table big-table">
              <div className="tr heading">
                <div className="th">Name</div>
                <div className="th">Email</div>
                <div className="th">Access Level</div>
                <div className="th">Last Login</div>
              </div>
              {users.filter((user) => user.support !== true && user.inactive === true).map((user) => (
                <Link onClick={this.handleEditPanel} to={`/settings/users/${user.id}`} id={user.id} key={user.id} className="tr">
                  <div className="td"><em>{user.first_name} {user.last_name}</em></div>
                  <div className="td"><em>{user.email}</em></div>
                  <div className="td center"><em>{combine(user.access_level)}</em></div>
                  <div className="td"><em>{user.last_login}</em></div>
                </Link>
              ))}
            </div>

          </div>
        )
      }
      else {
        return (
          <div>
            {this.inactiveUsersToggle()}
          </div>
        )
      }
    }
  }

  inactiveUsersToggle() {
    return (
      <div className="toggle-hidden-locations">
        <input type="checkbox" name="showInactive" id="showInactive" checked={this.state.showInactive} onChange={this.handleCheckbox} />
        <label htmlFor="showInactive">{this.state.showInactive === true ? "Hide" : "Show"} inactive users</label>
      </div>
    )
  }

  render() {
    const { users, partial, searchedUsers, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="list-of-users">
          <div className="logged-in-as">
            Currently logged in as: <strong>{users.find(user => user.current_user === true).email}</strong>
          </div>

          <h3 className="inline-block">List of users</h3>

          <Link to={'/settings/users/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New User</div>
          </Link>

          <input name="partial" placeholder="Enter text to search" className="search-text small-bottom-margin" onChange={this.handlePartialChange} value={partial} />

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Email</div>
              <div className="th">Access Level</div>
              <div className="th">SSO Required?</div>
              <div className="th">Last Login</div>
            </div>
            {(searchedUsers || users).filter((user) => user.support !== true && user.inactive === false).map((user) => (
              <Link onClick={this.handleEditPanel} to={`/settings/users/${user.id}`} id={user.id} key={user.id} className="tr">
                <div className="td">{user.first_name} {user.last_name}</div>
                <div className="td">{user.email}</div>
                <div className="td center">{combine(user.access_level)}</div>
                <div className="td center">{user.external_sign_on_only ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                <div className="td">{user.last_login}</div>
              </Link>
            ))}
          </div>

          {this.renderInactiveUsers(users)}
          {this.supportUserSection(users)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New User" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/settings/users');
              this.componentDidMount();
            }}>
            <CreateUser />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit User" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateUser id={this.state.editId} closeEditPanel={this.closeEditPanel} updatePopupCallback={this.props.updatePopupCallback} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.userId !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.userId
      }
    }
    else if (state.editPanelToggle && props.userId === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/users")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.userId !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.userId
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.userId !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.userId,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ users: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfUsers);
