import React, { Component } from 'react';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class Page extends Component {
  render() {
    const title = this.props.title || "Digital Reception";

    return (
      <div>
        <SetTitle title={title} />
        <PopupBox />
        <Header />
        <div className="main-page">
          <h2 className="page-title">{title}</h2>
          <OrganisationName />
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Page;
