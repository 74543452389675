import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';

import ViewEvent from '../../components/exports/ViewEvent';
import { arrayParamBuilder, datify } from '../../utilities/Generic.js'
import { renderCheckOutWithContext, renderVisitedPeopleWithContext } from '../../utilities/Events.js'

import { CSVLink } from "react-csv";

import { faFileDownload, faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ExportEvents extends Component {
  constructor(props) {
    super(props);

    this.handleEventPanel = this.handleEventPanel.bind(this);
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  emptyEvents(events) {
    if (events.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyEventsText() }
          </div>
        </div>
      )
    }
  }

  emptyEventsText() {
    if (this.props.selected_location !== "" && this.props.type_of_person !== "" && this.props.page > 1) {
      return "No events to show on this page for this Location and Type in this time range."
    }
    else if (this.props.selected_location !== "" && this.props.type_of_person !== "") {
      return "No events to show for this Location and Type in this time range."
    }
    else if (this.props.selected_location !== "" && this.props.page > 1) {
      return "No events to show on this page for this Location in this time range."
    }
    else if (this.props.selected_location !== "") {
      return "No events to show for this Location in this time range."
    }
    else if (this.props.type_of_person !== "" && this.props.page > 1) {
      return "No events to show on this page for this Type in this time range."
    }
    else if (this.props.type_of_person !== "") {
      return "No events to show for this Type in this time range."
    }
    else if (this.props.page > 1) {
      return "No events to show on this page in this time range."
    }
    else {
      return "No events to show for this time range."
    }
  }

  customAttributeFinder(custom_attribute_id){
    return this.props.custom_attributes.find(custom_attribute => custom_attribute.id === custom_attribute_id)
  }

  personCustomAttributeFinder(person, custom_attribute_id){
    // returns empty object if person custom attribute is not available
    return person.custom_attributes.find(custom_attribute => custom_attribute.custom_attribute_id === custom_attribute_id) || {}
  }

  renderCustomAttributeHeaders() {
    let activeCustomAttributes = []

    this.props.custom_attribute_ids.forEach((custom_attribute_id) =>
      activeCustomAttributes.push(this.customAttributeFinder(custom_attribute_id))
    )

    return (
      <React.Fragment>
        {activeCustomAttributes.map((custom_attribute, index) => (
          <div className="th" key={"custom_attribute"+index}>{custom_attribute.name}</div>
        ))}
      </React.Fragment>
    )
  }

  renderPersonCustomAttributes(person) {
    return (
      <React.Fragment>
        {this.props.custom_attribute_ids.map((custom_attribute_id, index) => {
          let custom_attribute = this.personCustomAttributeFinder(person, custom_attribute_id)
          return (
            <div className="td" key={"custom_attribute"+index}>{custom_attribute.value}</div>
          )
        })}
      </React.Fragment>
    )
  }

  customAttributeCSV() {
    let array = []
    this.props.custom_attribute_ids.forEach((custom_attribute_id) => {
      const custom_attribute = this.customAttributeFinder(custom_attribute_id)

      array.push({ label: custom_attribute.name, key: custom_attribute.id.toString()})
    })
    return array
  }

  downloadCSV(events) {
    let filename = `events-export-${this.formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "Name", key: "person_name" },
      { label: "Type", key: "type_of_person" },
      { label: "Arrived", key: "check_in" },
      { label: "Left", key: "check_out" },
      { label: "Location", key: "location" },
      { label: "Visited People", key: "visited_people" }
    ];

    if (this.props.showDuration) {
      headers.splice(4, 0, { label: "Duration (hours)", key: "duration"})
    }

    headers = headers.concat(this.customAttributeCSV())

    // mapping custom attributes back into people using the custom_attribute_id as a key to match the keys in customAttributeCSV()
    events.forEach((event) => {
      event.custom_attributes.forEach((custom_attribute) => {
        event[custom_attribute.custom_attribute_id.toString()] = custom_attribute.value
      })
    })

    if (events.length === 0) {
      events = [{[headers[0].key]: this.emptyEventsText()}]
    }

    return (
      <CSVLink data={events} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  handleEventPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      eventId: id,
      eventPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push(`/exports/events/${id}`)
  }

  renderPageButtons(page, records, cool_records) {
    if (parseInt(page) === 1 && records.length < 250 && cool_records.length < 250) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.props.handleNextPage} disabled={(records.length < 250 && cool_records.length < 250)}>Next Page</button>
          <button className="button new-button" onClick={this.props.handlePreviousPage} disabled={(this.props.page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  renderSortColumn(title, attribute){
    if (this.props.sort === attribute) {
      let directionArrow = "▲"
      if (this.props.direction === "desc") {
        directionArrow = "▼"
      }
      return (
        <div className="th sortable sorted" onClick={() => this.props.handleSortChange(attribute)}>
          <strong>{title}</strong>
          <div><strong>{directionArrow}</strong></div>
        </div>
      )
    }
    else {
      return (
        <div className="th sortable" onClick={() => this.props.handleSortChange(attribute)}>
          {title}
        </div>
      )
    }
  }

  renderEventRow(event) {
    if (event.cool_event === false) {
      return (
        <Link to={`/exports/events/${event.id}`} onClick={this.handleEventPanel} id={event.id} key={event.id} className="tr event">
          <div className="td">{event.person_name}</div>
          <div className="td">{event.type_of_person}</div>
          <div className="td">{event.check_in}</div>
          { renderCheckOutWithContext(event) }
          { this.props.showDuration && <div className="td">{event.duration}</div>}
          <div className="td">{event.location}</div>
          { renderVisitedPeopleWithContext(event) }
          <div className="td center">{event.photo_taken ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
          {this.renderPersonCustomAttributes(event)}
        </Link>
      )
    }
    else {
      return (
        <Link to={`/exports/events/${event.id}`} onClick={this.handleEventPanel} id={event.id} key={event.id} className="tr event">
          <div className="td">{event.person_name}</div>
          <div className="td">{event.type_of_person}</div>
          <div className="td">{event.check_in}</div>
          { renderCheckOutWithContext(event) }
          { this.props.showDuration && <div className="td">{event.duration}</div>}
          <div className="td">{event.location}</div>
          <div className="td center">{event.visited_people}</div>
          <div className="td center">N/A</div>
          {this.renderPersonCustomAttributes(event)}
        </Link>
      )
    }
  }

  determineOrder(firstEntry, secondEntry) {
    if (this.props.sort === "default") {
      return 0
    }

    if (["check_in", "check_out"].includes(this.props.sort)) {
      return datify(firstEntry[this.props.sort]) - datify(secondEntry[this.props.sort])
    }
    let [parsedFirstEntry, parsedSecondEntry] = [firstEntry[this.props.sort], secondEntry[this.props.sort]];


    if (parsedFirstEntry > parsedSecondEntry) {
      return 1
    }

    if (parsedSecondEntry > parsedFirstEntry) {
      return -1
    }

    return 0
  }

  render() {
    const { events, cool_events, eventsLoaded, coolEventsLoaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (eventsLoaded === false || coolEventsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (eventsLoaded && coolEventsLoaded) {
      let allEvents = events.concat(cool_events)

      // only sort on render when both events and coolevents are included
      if (events.length > 0 && cool_events.length > 0) {
        if (this.props.direction === "asc") {
          allEvents = allEvents.sort((firstEntry, secondEntry) => this.determineOrder(firstEntry, secondEntry));
        }
        else if (this.props.direction === "desc") {
          allEvents = allEvents.sort((firstEntry, secondEntry) => this.determineOrder(secondEntry, firstEntry));
        }
      }

      return (
        <div className="relative">
          {this.downloadCSV(allEvents)}

          <div className="table big-table top-padding" data-testid="table">
            <div className="tr heading">
              {this.renderSortColumn("Name", "person")}
              {this.renderSortColumn("Type", "type_of_person")}
              {this.renderSortColumn("Arrived", "check_in")}
              {this.renderSortColumn("Left", "check_out")}
              { this.props.showDuration && <div className="th">Duration (hours)</div> }
              {this.renderSortColumn("Location", "location")}
              <div className="th not-sortable">Visited People</div>
              <div className="th not-sortable">Photo Taken?</div>
              {this.renderCustomAttributeHeaders()}
            </div>

            {allEvents.map((event) => (
              this.renderEventRow(event)
            ))}
          </div>

          {this.emptyEvents(allEvents)}
          {this.renderPageButtons(this.props.page, events, cool_events)}

          <SlidingPane isOpen={this.state.eventPanelToggle} title="View Event" width="60%"
            onRequestClose={() => {
              this.setState({ eventPanelToggle: false });
              this.setState({ eventId: null });
              this.props.history.push(this.state.previousURL);
              this.componentDidMount();
            }}>
            <ViewEvent id={this.state.eventId} />
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    events: [],
    error: null,
    unauthorized: null,
    invalid_params: false,

    eventsLoaded: false,
    coolEventsLoaded: false,

    eventId: null,
    eventPanelToggle: false,
    previousURL: "/exports/events"
  };

  static getDerivedStateFromProps(props, state) {
    if (props.history.action === "POP" && props.id !== undefined) {
      return {
        eventPanelToggle: true,
        eventId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id !== undefined && this.state.eventPanelToggle === false) {
      this.setState({
        eventId: this.props.id,
        eventPanelToggle: true
      });
    }

    if (this.props.date_from !== "" && this.props.date_to !== "") {

      let modifiedDateFrom = new Date(this.props.date_from)
      modifiedDateFrom.setMonth(modifiedDateFrom.getMonth() + 3)
      modifiedDateFrom.setDate(modifiedDateFrom.getDate() - 3)

      let modifiedDateTo = new Date(this.props.date_to)
      modifiedDateTo.setMonth(modifiedDateTo.getMonth() + 3)
      modifiedDateTo.setDate(modifiedDateTo.getDate() + 3)

      const dateFromPastThreeMonths = modifiedDateFrom < Date.now();
      const dateToPastThreeMonths = modifiedDateTo < Date.now();

      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let params = `events[date_from]=${this.props.date_from}&events[date_to]=${this.props.date_to}&events[location]=${this.props.selected_location}&events[person]=${this.props.type_of_person}${arrayParamBuilder("events", "tag_ids", this.props.tag_ids)}${arrayParamBuilder("events", "custom_attribute_ids", this.props.custom_attribute_ids)}&events[search]=${this.props.search}&events[visited_person_search]=${this.props.visited_person_search}&page=${this.props.page}&sort=${this.props.sort}&direction=${this.props.direction}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      if (dateFromPastThreeMonths) {
        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/cool_events?${params}`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.setState({ cool_events: data, coolEventsLoaded: true })
          })
          .catch(error => this.setState({ error, coolEventsLoaded: true }))
      }

      if (dateToPastThreeMonths === false) {
        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/events?${params}`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.setState({ events: data, eventsLoaded: true })
          })
          .catch(error => this.setState({ error, eventsLoaded: true }))
      }

      if (dateToPastThreeMonths) {
        // Events call has not triggered. Manually set the state and continue with just CoolEvents
        this.setState({ events: [], eventsLoaded: true })
      }

      if (dateFromPastThreeMonths === false) {
        // CoolEvents call has not triggered. Manually set the state and continue with just Events
        this.setState({ cool_events: [], coolEventsLoaded: true })
      }
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(ExportEvents);
