import React, { Component } from 'react';
import Header from '../../components/header/Header';
import ListOfFaqs from '../../components/support/ListOfFaqs';
import ListOfTickets from '../../components/support/ListOfTickets';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

class SupportIndex extends Component {
  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Support"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Support</h2>
          <OrganisationName />

          <div>
            <div className="support-resources">
              <h3>Help Section</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/quick-start-guide.pdf'>Quick Start Guide</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/link-to-mobile-care-monitoring.pdf'>Linking to Mobile Care Monitoring PDF</a>
            </div>
            <div className="support-resources">
              <h3>Useful guides</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vSpqnt07KMa8uFQQOW7hnvTRudEt32QOvZnZox8u1BycwMApW4QdJotCRXKHLQIZq3EzZEbssMRfduT/pub?embedded=true'>Managing people</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vRCYoJTELYp0KhoPvcLT0hNDyt1HwQ91J8s23DRleHyvrxQy0eoHvuXAktR5vruqBWQQ5l-RJAWluyt/pub'>Staff time management</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vTAcXhqwNEPAUPNxFexNohbpyXf3fHPLa6zoTzZn_8KshvTMIQmhYIhgK3nxrXB9qLJ5GCf75puU361/pub?embedded=true'>Firelog</a>
            </div>
            <div className="support-resources">
              <h3>Videos</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/watch?v=OdHs5qTNsaU'>Training video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/link-to-mobile-care-monitoring-video'>Linking to Mobile Care Monitoring Video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/interactive-android-device-setup-video'>Interactive Android Device Setup Video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/interactive-apple-device-setup-video'>Interactive Apple Device Setup Video</a>
            </div>
          </div>
          <div>
            <ListOfFaqs id={params.faq_id} />
            <ListOfTickets id={params.id} />
          </div>
        </div>

      </div>
    );
  }
}

export default SupportIndex;
