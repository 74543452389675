import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import NoticeBox from '../../components/shared/NoticeBox';
import HelpText from '../../components/help/HelpText';

import { capitalize, adminUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'

class CreateUser extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    email: "",
    access_levels: [],
    external_sign_on_only: false,
    receive_critical_alerts: localStorage.license === "base" ? true : false,
    location_ids: [],
    fire_log_location_ids: [],
    customer_api: false,

    locations: [],

    updated: false,
    updating: false,
    locationsLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name
    const useInteger = event.target.dataset.useInteger;

    if (useInteger === "true") {
      name = parseInt(event.target.name);
    }
    else {
      name = event.target.name;
    }

    const value = event.target.checked;
    const key = event.target.dataset.key;

    var state = this.state[key]

    if (value === true) {
      if (!state.includes(name)) {
        state.push(name)
      }
    }
    else if (value === false) {
      if (state.includes(name)) {
        const index = state.indexOf(name)
        state.splice(index, 1)
      }
    }

    this.setState({key: state});
  }

  handleSubmit(event) {
    this.setState({
      updated: "",
      updating: true
    })

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "user": {
        "first_name": this.state.first_name,
        "last_name": this.state.last_name,
        "email": this.state.email,
        "access_level": this.state.access_levels,
        "external_sign_on_only": this.state.external_sign_on_only,
        "receive_critical_alerts": this.state.receive_critical_alerts,
        "location_ids": this.state.location_ids,
        "fire_log_location_ids": this.state.fire_log_location_ids,
        "customer_api": this.state.customer_api,
        "inactive": this.state.inactive
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderFireLogLocationsSelection(assigned_locations, fire_log_location_ids) {
    if (assigned_locations.length > 0) {
      return (
        <table className="checkbox-table bottom-margin">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Send Emergency Fire Log Emails?</th>
            </tr>
            {assigned_locations.map((location) => (
              <tr key={location.id}>
                <td>
                  <Link to={`/location/${location.id}`}>{location.name}</Link>
                </td>
                <td className="center-cell">
                  <input className="column" type="checkbox" data-name={"fire_log" + location.name} name={location.id} data-key="fire_log_location_ids" data-use-integer="true" checked={fire_log_location_ids.includes(location.id)} onChange={this.handleMultiCheckbox} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }
    else {
      return (
        <NoticeBox type={"info"} text={"Only assigned Locations can be added as Fire Log Locations!"} />
      )
    }
  }

  render() {

    const { first_name, last_name, email, access_levels, external_sign_on_only, receive_critical_alerts, location_ids, fire_log_location_ids, customer_api, inactive, locations, locationsLoaded, id, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/settings/users/${id}`}/>
    }

    if (locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <h3>Add new user</h3>

            <div className="row">
              <HelpText page={'user'} section={'firstName'} />

              <label className="column">First Name:</label>{ renderErrors(errors, 'first_name') }
              <input className="column" type="text" name="first_name" value={first_name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'lastName'} />

              <label className="column">Last Name:</label>{ renderErrors(errors, 'last_name') }
              <input className="column" type="text" name="last_name" value={last_name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'email'} />

              <label className="column">Email Address:</label>{ renderErrors(errors, 'email') }
              <input className="column" type="email" name="email" value={email} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'accessLevel'} />

              <label className="column">Access Level:</label>{ renderErrors(errors, 'access_level') }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Enable</th>
                  </tr>
                  {["admin", "manager", "reporting", "emergency"].map((access_level) => (
                    <tr key={access_level}>
                      <td>
                        {capitalize(access_level)}
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" name={access_level} data-key="access_levels" checked={access_levels.includes(access_level)} onChange={this.handleMultiCheckbox} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="row">
              <HelpText page={'user'} section={'external_sign_on_only'} />

              <input className="column" type="checkbox" name="external_sign_on_only" id="external_sign_on_only" checked={external_sign_on_only} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="external_sign_on_only">SSO required?</label>{ renderErrors(errors, 'external_sign_on_only') }
            </div>

            {baseLicense && (
              <div className="row">
                <HelpText page={'user'} section={'receive_critical_alerts'} />

                <input className="column" type="checkbox" name="receive_critical_alerts" id="receive_critical_alerts" checked={receive_critical_alerts} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="receive_critical_alerts">Receive critical alerts?</label>{ renderErrors(errors, 'receive_critical_alerts') }
              </div>
            )}

            <div className="row">
              <HelpText page={'user'} section={'locations'} />

              <label className="column">Locations:</label>{ renderErrors(errors, 'location') }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Allow Access?</th>
                  </tr>
                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" data-name={location.name} name={location.id} data-key="location_ids" data-use-integer="true" checked={location_ids.includes(location.id)} onChange={this.handleMultiCheckbox} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {baseLicense && (
              <>
                <div className="row">
                  <HelpText page={'user'} section={'fire_log_locations'} />

                  <label className="column">Fire Log Locations:</label>{ renderErrors(errors, 'fire_log_location') }
                  {this.renderFireLogLocationsSelection(locations.filter(location => location_ids.includes(location.id)), fire_log_location_ids)}
                </div>

                <div className="row">
                  <HelpText page={'user'} section={'customer_api'} />

                  <input className="column small-bottom-margin" type="checkbox" name="customer_api" id="customer_api" checked={customer_api} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="customer_api">Enable Customer API?</label>{ renderErrors(errors, 'customer_api') }

                  {customer_api && (
                    <NoticeBox type={"info"}>API Keys will be generated once the new User is created. Check back to this section after saving.</NoticeBox>
                  )}
                </div>
              </>
            )}

            <div className="row">
              <HelpText page={'user'} section={'inactive'} />

              <input className="column" type="checkbox" name="inactive" id="inactive" checked={inactive} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="inactive">Inactive?</label>{ renderErrors(errors, 'inactive') }
            </div>

            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/all_locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default CreateUser;
