import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import FileLink from '../../components/shared/FileLink';
import HelpText from '../../components/help/HelpText';

import { renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import { formatNewLines, nonImageExtensionsArray } from '../../utilities/Generic.js'

class UpdateTicket extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  state = {
    title: "",
    body: "",
    image: null,
    category: "",
    priority: "",
    user_name: "",
    created_at: "",
    reply: "",
    upload_file: null,

    ticketReplies: [],

    loaded: false,
    ticketRepliesLoaded: false,
    scrolled: false,

    updated: "",
    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    this.setState({
      updated: "",
      updating: true
    })

    let id = this.props.id;

    const formData = new FormData()
    formData.append('ticket[body]', this.state.reply)

    if (this.state.upload_file !== null) {
      formData.append('ticket[image]', this.state.upload_file)
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/${id}/add_reply`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({
          updated: true,
          updating: false,
          errors: "",
          reply: "",
          ticketReplies: data
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Ticket updated!
        </div>
      );
    }
  }

  formatNewLines(text) {
    let key = 0
    return text.split('\n').map(line => <p key={key += 1}>{line}</p>)
  }

  displayFirstUnreadReply(index, firstUnreadReplyIndex) {
    if (index === firstUnreadReplyIndex) {
      return (
        <div id="start-of-unread" className={"first-unread-reply"} >
          Unread replies start here:
        </div>
      )
    }
  }

  displayStatusChange(ticketReply) {
    if (ticketReply.status !== null) {
      const name = ticketReply.user_name || ticketReply.admin_name
      const unread = ticketReply.unread ? " unread-status" : ""
      let status = ticketReply.status.toLowerCase()

      if (status === "open") {
        status = "reopened"
      }

      return (
        <div className={"ticket-status" + unread} >
          {name} {status} this ticket ({ticketReply.created_at})
        </div>
      )
    }
  }

  displayResponseBody(ticketReply) {
    if (ticketReply.body !== null || ticketReply.image !== null) {
      const unread = ticketReply.unread ? " unread" : ""
      return (
        <div className={(ticketReply.admin_response ? "admin-response settings big-settings" : "user-response settings big-settings") + unread}>
          <div key={ticketReply.id} className="row">
            <label className="column"><span className="strong">Submitted by:</span> {ticketReply.user_name} at {ticketReply.created_at}</label>
            <br></br>
            <div className="ticket-body">
              <div className="body-title">
                <label className="column strong">Message:</label>
              </div>
              <div className="body-content">
                {formatNewLines(ticketReply.body)}
              </div>
              {this.renderFile(ticketReply.image)}
            </div>
          </div>
        </div>
      )
    }
  }

  renderFile(attachment) {
    if (attachment) {
      let extension = attachment.split('.').pop().toLowerCase();
      if (nonImageExtensionsArray.includes(extension)) {
       return (
        <div>
          <FileLink fontSize={44} href={`${process.env.REACT_APP_ROOT_DOMAIN}/${attachment}`} title="Click to see attached file" />
        </div>
      )
      }
      else {
        return (
          <div>
            <a href={`${process.env.REACT_APP_ROOT_DOMAIN}/${attachment}`}>
              <img className="image-content" src={`${process.env.REACT_APP_ROOT_DOMAIN}/${attachment}`} alt={`${this.state.title}`}></img>
            </a>
          </div>
        )
      }
    }
  }

  render() {
    const { title, status, body, image, category, priority, user_name, created_at, reply, loaded, updated, unauthorized, errors, error, ticketReplies, ticketRepliesLoaded } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || ticketRepliesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && ticketRepliesLoaded) {
      const firstUnreadReplyIndex = ticketReplies.findIndex(x => x.unread === true)

      return (
        <div>
          <SetTitle title={`View Ticket | Tickets`} />

          <h3 className="ticket-title">Ticket View</h3>
          <form className="settings big-settings">
            <div className="row">
              <HelpText page={'support'} section={'created_at'} />

              <label className="column strong">Ticket Summary:</label>
              <label className="rightcolumn"><span className="strong">Created at: </span>{created_at}</label>
              <br></br>
            </div>


            <div className="row">
              <HelpText page={'support'} section={'title'} />

              <label className="column">{title}</label>
              <br></br>
            </div>

            <div className="row">
              <HelpText page={'support'} section={'category/submit'} />

              <label className="column"><span className="strong">Category: </span>{category}</label>
              <label className="rightcolumn"><span className="strong">Status: </span>{status}</label>
              <br></br>
            </div>

            <div className="row">
              <HelpText page={'support'} section={'priority/user_name'} />

              <label className="column"><span className="strong">Priority: </span>{priority}</label>
              <label className="rightcolumn"><span className="strong">Submitted by: </span>{user_name}</label>
              <br></br>
            </div>
            <br></br>

            <div className="row">
              <HelpText page={'support'} section={'body'} />

              <div className="ticket-body">
                <div className="body-title">
                  <label className="column strong">Ticket Details:</label>
                </div>
                <div className="body-content">
                  {formatNewLines(body)}
                </div>
                {this.renderFile(image)}
              </div>

              <br></br>
            </div>
          </form>

          {ticketReplies.map((ticketReply, index) => (
            <div key={index}>
              {this.displayFirstUnreadReply(index, firstUnreadReplyIndex)}
              {this.displayResponseBody(ticketReply)}
              {this.displayStatusChange(ticketReply)}
            </div>
          ))}

          <form className="settings big-settings">
            <div className="row">
              <HelpText page={'support'} section={'body'} />

              <label className="column">Add a new reply:</label>{ renderErrors(errors, 'body', 'reply') }
              <textarea aria-required="true" name="reply" value={reply} onChange={this.handleChange} />
              <label className="column">Upload an image for context (optional):</label>
              <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} accept="image/*, video/*" />
            </div>

            <input type="submit" value="Submit" disabled={this.state.updating} onClick={this.handleSubmit}/>
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>

        </div>
      );
    }
  }

  componentDidUpdate() {
    const element = document.getElementById("start-of-unread");

    if (element !== null && this.state.scrolled === false){
      element.scrollIntoView({behavior: 'smooth'});
      this.setState({ scrolled: true })
    }
  }

  componentDidMount() {
    let id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          title: data.title,
          status: data.status,
          body: data.body,
          image: data.image,
          category: data.category,
          priority: data.priority,
          user_name: data.user_name,
          created_at: data.created_at,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/${id}/replies`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ ticketReplies: data, ticketRepliesLoaded: true })
        localStorage.removeItem("last_replies_get")
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default UpdateTicket;
