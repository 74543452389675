import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

import HelpText from '../../components/help/HelpText';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Devices extends Component {
  renderOnlineDeviceCount(online_device_count) {
    // show zeroes in red
    if (online_device_count === 0) {
      return <span className="red-text">{online_device_count}</span>
    }

    return online_device_count
  }

  renderPhotoStatus(photo_status) {
    if (photo_status === "no devices") {
      return <span title="No physical devices have been contacted recently, so photo status cannot be obtained." >n/a</span>
    }

    if (photo_status === "ok") {
      return <FontAwesomeIcon icon={faCheckCircle} className="green-text" title="None of the physical devices contacted recently appear to have photo taking issues at the moment." />
    }

    let title = "One or more of the physical devices contacted recently have issues taking photos: "

    if (photo_status.includes("too_many_photos")) {
      title += "too many photos are stored locally"
    }

    if (photo_status.includes("too_many_photos") && photo_status.includes("rejected_photos")) {
      title += " and "
    }

    if (photo_status.includes("rejected_photos"))  {
      title += "our app does not have permission to use the camera"
    }

    title += "."

    return <FontAwesomeIcon icon={faTimes} className="red-text" title={title} />
  }

  render() {
    const { devices, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="widget">
          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Device name</div>
              <div className="th">Last updated</div>
              <div className="th">Last contacted</div>
              <div className="th">Online Devices<HelpText className="th-helptext" page={'device'} section={'online_device_count'} /></div>
              <div className="th">Photo Status<HelpText className="th-helptext" page={'device'} section={'photo_status'} /></div>
            </div>
            {devices.map((device) => (
               <Link to={`/configuration/devices/${device.id}`} key={device.id} className="tr">
                <div className="td bold">{device.name}</div>
                <div className="td">{device.last_updated}</div>
                <div className="td">{device.last_contacted}</div>
                <div className="td center">{this.renderOnlineDeviceCount(device.online_device_count)}</div>
                <div className="td center">{this.renderPhotoStatus(device.photo_status)}</div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
  }

  state = {
    devices: [],
    loaded: false,
    error: null
  };

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/devices`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ devices: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default Devices;
