import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

import SuggestedMergeComponent from '../../components/people/SuggestedMerges';

class SuggestedMerges extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    locations: [],

    location: "",
    page: 1,

    loaded: false,
    errors: [],
    error: "",
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random()
    });

    if (value === "") {
      this.props.history.push(`/suggested-merge-people`);
    }
    else {
      this.props.history.push(`/suggested-merge-people?location=${value}`);
    }
  }

  render() {
    const { locations, location, page, key, loaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return (
        <div>
          <SetTitle title={"People"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Suggested People Merges</h2>
            <OrganisationName />

            <label className="column">Location:</label>
            <select name="location">
              <option value="">Loading...</option>
            </select>
          </div>
        </div>
      )
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={"Suggested Merges | People"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Suggested People Merges</h2>
            <OrganisationName />

            <div className="export-selection">
              <div className="selection-block">
                <label className="column">Location:</label>
                <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                  <option value="">All</option>
                  {locations.map((location) => (
                    <option value={location.id} key={location.id}>{location.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <SuggestedMergeComponent location={location} page={page} key={key} history={this.props.history} />
          </div>
        </div>
      )
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Handle back/forward buttons
    if (props.history.action === "POP") {
      const params = new URLSearchParams(props.location.search)
      const location = params.get('location') || ""
      const page = params.get('page') || 1

      return {
        location: location,
        page: page,
        key: Math.random()
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)
      const location = params.get('location') || ""
      const page = params.get('page') || 1

      this.setState({
        location: location,
        page: page,
        key: Math.random()
      });
    }

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default SuggestedMerges;
