import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class StafFulfilmentFlow extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    loaded: false,
    unauthorized: "",
    errors: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  renderHelpText(page) {
    // eslint-disable-next-line
    switch (page) {
      case "Required Allocation Template":
        return (
          <p>
            The first step is to set up your Required Allocation levels, which are the necessary number of staff of each Role you need per hour. These levels will be used to highlight where you have gaps in your Staff Fulfilment. These levels will be repeated each week and will become your normal Required Allocation levels, but you can add Exceptions for when these needs to change for a particular day, but not permanently, in the next step.
          </p>
        )

      case "Exceptions":
        return (
          <p>
            These are one-off exceptions for days where your Required Allocation levels need to be higher or lower. Required Allocation levels are used to highlight where you have under fulfilled or overfulfilled the Shifts.
          </p>
        )

      case "Working Pattern Template":
        return (
          <p>
            These are staff normal working patterns which are repeated on a weekly basis, for each member of staff. Exceptions to the working pattern can be added on the Assigned Shifts steps. This page makes use of the Required Allocation levels. Red means under fulfilled, Orange means overfulfilled, and Green means perfect fulfilment.
          </p>
        )

      case "Unassigned Shifts Template":
        return (
          <p>
            Here you can set up any Templated Shifts that you know need to happen each week, but you aren't sure who will work the Shift. If you already know who is going to work each shift, you can ignore this and proceed to the next step.
          </p>
        )

      case "Assigned Shifts by Role":
        return (
          <p>
            This is your main view of Staff Fulfilment and shows the combination of Required Allocations and Shifts. You can make one-off changes to Working Patterns if needed. If you need to adjust the Required Allocation levels, you can go back to the Template or Exception steps. This also shows historically which Shifts were performed, and who worked them. You will be able to see which Staff Events were attached to the historical Shifts. Red again means under fulfilled, Orange means overfulfilled, and Green means perfect fulfilment.
          </p>
        )

      case "Assigned Shifts by Person":
        return (
          <p>
            This shows the allocated Shifts from a staff member perspective, giving you a quick view of who is working which hours. It synchronises with the Assigned Shifts by Role but does not detail whether the Required Allocation levels have been met or not, so if changes are made here then it is important to revisit the Assigned Shifts by Role page.
          </p>
        )
    }
  }

  renderFlowButton(title, link, page, display) {
    if (display === true) {
      let classes = "box"

      if (page === title) {
        classes += " current"
      }

      return (
        <li><a href={link}><div className={classes}>{title}</div></a></li>
      )
    }
    else {
       return (
        <li>
          <div className="box disabled tooltip-container">
            <div className="tooltip above">
              This page is blocked until you create data on the previous.
            </div>
            {title}
          </div>
        </li>
      )
    }
  }

  render() {
    const { loaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    let allocationsCreated = this.state.flow.allocations
    let shiftsCreated = this.state.flow.shifts

    if (loaded) {
      let dynamicPart

      if (this.props.rota_id !== undefined) {
        dynamicPart = `${this.props.location_id}/rotas/${this.props.rota_id}`
      }
      else {
        dynamicPart = `${this.props.location_id}`
      }

      return (
        <>
          <div id="staff-fulfilment-flow">
            <ul>
              {this.renderFlowButton("Required Allocation Template", `/staff-fulfilment/${dynamicPart}/required-allocation/template`, this.props.page, true)}
              {this.renderFlowButton("Exceptions", `/staff-fulfilment/${dynamicPart}/required-allocation/weekly`, this.props.page, allocationsCreated)}
              {this.renderFlowButton("Working Pattern Template", `/staff-fulfilment/${dynamicPart}/working-pattern/template`, this.props.page, allocationsCreated)}
              {this.renderFlowButton("Unassigned Shifts Template", `/staff-fulfilment/${dynamicPart}/working-pattern-unassigned/template`, this.props.page, shiftsCreated)}
              {this.renderFlowButton("Assigned Shifts by Role", `/staff-fulfilment/${dynamicPart}/working-pattern/weekly`, this.props.page, shiftsCreated)}
              {this.renderFlowButton("Assigned Shifts by Person", `/staff-fulfilment/${dynamicPart}/shift-assignment/weekly`, this.props.page, shiftsCreated)}
            </ul>
          </div>

          <div id="staff-filfilment-help-text">
            {this.renderHelpText(this.props.page)}
          </div>
        </>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/flow?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ flow: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  componentDidUpdate(prevProps) {
    // reload if Flow buttons aren't fully unlocked and data may have changed
    if (this.state.loaded && (this.state.flow.allocations === false || this.state.flow?.shifts === false) && this.props.date !== prevProps.date) {
      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/flow?location_id=${this.props.location_id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ flow: data, loaded: true })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
  }
}

export default StafFulfilmentFlow;
