import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class BigLogo extends Component {
  render() {
    return (
      <div>
        <Link to={'/'}>
          <img src={"../../digital-reception.png"} className="main-logo" alt="Digital Reception"/>
        </Link>
      </div>
    )
  }
}

export default BigLogo;