import React, { Component } from 'react';

class Blank extends Component {

  render() {

    return (
      <div/>
    );
  }
}

export default Blank;
