import React from "react";
import { withMsal } from "@azure/msal-react";

class MicrosoftLoginButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const msalInstance = this.props.msalContext.instance;

    msalInstance.loginPopup({
      redirectUri: `/blank`
    })
    .then((data) => {
      this.props.handleMicrosoftLogin(data.idToken)
    })
    .catch(error => {
      // if someone wilfully closes the login popup, we don't need to send a Honeybadger
      if (error.message === "user_cancelled: User cancelled the flow.") {
        this.props.handleMicrosoftLoginError("User cancelled the flow.")
      }
      // but if something unexpected does happen, we definitely want to know!
      else {
        throw error
      }
    })
  }

  render() {
    return <img src={"../../ms_signin_light.png"} className="visible-link" alt="Sign in with Microsoft" onClick={this.handleClick} />
  }
}

export default withMsal(MicrosoftLoginButton);
