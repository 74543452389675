import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import EventsPanel from '../../../components/shared/EventsPanel';

import { collectHexCodes } from '../../../utilities/Colors.js'
import { capitalize } from '../../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class AnalysisActiveHours extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const element = getElementAtEvent(this.barGraph.current, event)[0]
    this.openPanel(element.index, element.datasetIndex)
  }

  openPanel(index, datasetIndex) {
    let type;
    // eslint-disable-next-line
    switch(index) {
      case 0:
        type = "morning"
        break;
      case 1:
        type = "afternoon"
        break;
      case 2:
        type = "evening"
        break;
      case 3:
        type = "night"
        break;
    }

    this.setState({
      infoPanel: true,
      type: type,
      datasetIndex: datasetIndex
    })

    this.props.history.push(`/analysis/overview/active_hours/${type}/${datasetIndex}${this.props.location.search}`)
  }

  render() {
    const { json, loaded, error, unauthorized, invalid_params, infoPanel, datasetIndex, type } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {

      var colors = collectHexCodes(json.length)

      let datasets = []
      json.forEach((array) => (
        datasets.push({
          label: array.location,
          data: [
            array.morning.length,
            array.afternoon.length,
            array.evening.length,
            array.night.length
          ],
          hidden: this.props.location_name && this.props.location_name !== array.location,
          backgroundColor: colors.shift()
        })
      ))

      const data = {
        labels: ["Morning", "Afternoon", "Evening", "Night"],
        datasets: datasets
      }

      const options = {
        scales: {
          yAxes: {
            ticks: {
              beginAtZero: true,
            }
          }
        }
      }

      const event_ids = json[datasetIndex] && json[datasetIndex][type];
      const location_name = json[datasetIndex] && json[datasetIndex].location;

      return (
        <div className="widget">
          <h3>When Used</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />

          <SlidingPane isOpen={infoPanel} title={type && `${location_name} - ${capitalize(type)} Events`} width="70%"
            onRequestClose={() => {
              this.setState({
                infoPanel: false,
                type: null
              });
              this.props.history.push(`/analysis/overview${this.props.location.search}`)
            }}>
            <EventsPanel event_ids={event_ids}></EventsPanel>
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    json: [],
    infoPanel: false,
    type: null,
    datasetIndex: null,
    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  static getDerivedStateFromProps(props, state) {
    const validContext = (["morning", "afternoon", "evening", "night"].includes(props.match.params.context)) && parseInt(props.match.params.context2) > -1

    if (state.infoPanel === false && props.match.params.chart === "active_hours" && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context,
        datasetIndex: props.match.params.context2
      }
    }
    else if (state.infoPanel && (props.match.params.chart !== "active_hours" || validContext === false)) {
      return {
        infoPanel: false,
        type: null,
        datasetIndex: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.match.params.chart === "active_hours" && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context,
        datasetIndex: props.match.params.context2
      }
    }
    else if (props.history.action === "POP" && (props.match.params.chart !== "active_hours" || validContext === false)) {
      return {
        infoPanel: false,
        type: null,
        datasetIndex: null
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    if (this.props.date_from !== "" && this.props.date_to !== "") {
      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let params = `analysis[date_from]=${this.props.date_from}&analysis[date_to]=${this.props.date_to}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/analysis/active_hours?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ json: data, loaded: true })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(AnalysisActiveHours);
