import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Example:
//
// <BigTable
//   data={feedbackTypes}
//   headings={["Name", "Display", "Include in Analysis Graphs", "Hidden"]}
//   attributes={["name", "display", "analysis_overview", "hidden"]}
//   render_types={["text", "text", "boolean", "boolean"]}
//   onClick={this.handleEditPanel}
//   classes={"bottom-margin"}
// />

class BigTable extends Component {
  render() {

    const url = this.props.url;
    const data = this.props.data;
    const headings = this.props.headings;
    const attributes = this.props.attributes;
    const render_types = this.props.render_types;

    const classes = this.props.classes || "";

    const onClick = this.props.onClick;

    return (
      <div className={`table big-table ${classes}`}>
        <div className="tr heading">
          {headings.map((heading, index) => (
            <div className="th" key={index}>{heading}</div>
          ))}
        </div>
        {data.map((datum) => (
          <Link to={`${url}/${datum.id}`} onClick={onClick} id={datum.id} key={datum.id} className="tr">
            {attributes.map((attribute, index) => {
              const render_type = render_types[index]

              if (render_type === "text") {
                return (<div className="td" key={`${datum.id}-${index}`}>{datum[attribute]}</div>)
              }
              else if (render_type === "boolean") {
                return (<div className="td center" key={`${datum.id}-${index}`}>{datum[attribute] ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>)
              }
              else {
                return null
              }
            })}
          </Link>
        ))}
      </div>
    )
  }
}

export default BigTable;
