import React, { Component } from 'react';
import ListOfRoles from '../../components/roles/ListOfRoles';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class RolesIndex extends Component {
  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Roles"} />
        <PopupBox />

        <h2 className="page-title">Roles</h2>
        <OrganisationName />

        <div>
          <ListOfRoles id={params.id} />
        </div>
      </div>
    );
  }
}

export default RolesIndex;
