import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import CreateAction from '../../components/actions/CreateAction';
import UpdateAction from '../../components/actions/UpdateAction';

import { hideElementForNonAdminsAndNonManagers, capitalizeAllWords } from '../../utilities/Forms.js'
import { optionalParam, arrayParamBuilder } from '../../utilities/Generic.js'

import { CSVLink } from "react-csv";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class OutstandingActions extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleUpdatePanel = this.handleUpdatePanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.setState({
      newPanelToggle: false,
      editPanelToggle: false,
      actionId: null
    });

    let url = `/exports/outstanding-actions`
    const params = optionalParam("question", this.props.question) + optionalParam("location", this.props.location) + optionalParam("type", this.props.type_of_person) + optionalParam("action_statuses", this.props.action_statuses)

    if (params) {
      url = url + "?" + params
    }

    this.props.history.push(url);
    this.componentDidMount();
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  emptyActions(outstanding_actions) {
    if (outstanding_actions.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyActionsText() }
          </div>
        </div>
      )
    }
  }

  emptyActionsText() {
      let text = "No outstanding actions to show"

      let content = []
      this.props.question !== "" && content.push(" Question")
      this.props.selected_location !== "" && content.push(" Location")
      this.props.type_of_person !== "" && content.push(" Type")

      if (content.length > 0) {
        text +=  " for this"
        for (let index = content.length - 1; index > 0; index--) {
          content.splice(index, 0, " and")
        }

        for (const index of content) {
          text+= index
        }
      }

     if (this.props.action_statuses) {
      text+= " with this action status"
     }
     text+= "."

     return text
  }

  downloadCSV(outstanding_actions) {
    let filename = `outstanding_action-export-${this.formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "Person", key: "person" },
      { label: "Person Type", key: "type_of_person" },
      { label: "Date of Problem", key: "date_of_problem" },
      { label: "Problem Type", key: "problem_type" },
      { label: "Location", key: "location" },
      { label: "Action Status", key: "action_status" }
    ];

    if (outstanding_actions.length === 0) {
      outstanding_actions = [{[headers[0].key]: this.emptyActionsText()}]
    }


    return (
      <CSVLink data={outstanding_actions} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  handleNewPanel(event) {
    this.setState({
      newPanelToggle: true
    });
    this.props.history.push('/exports/outstanding-actions/new')
  }

  handleUpdatePanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      actionId: id,
      editPanelToggle: true
    });
    this.props.history.push(`/exports/outstanding-actions/${id}`)
  }

  colourActionsByStatus(action) {
    switch(action.action_status) {
      case "Open":
        return "open-action"
      case "Started":
        return "started-action"
      case "Awaiting sign-off":
        return "awaiting-sign-off-action"
      case "Blocked":
        return "blocked-action"
      case "Completed":
        return "completed-action"
      case "Abandoned":
        return "abandoned-action"
      default:
        return ""
    }
  }

  render() {
    const { outstanding_actions, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="relative">
          {this.downloadCSV(outstanding_actions)}

          <Link to={'/exports/outstanding-actions/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdminsAndNonManagers()}`}>New Outstanding Action</div>
          </Link>

          <div className="table big-table top-padding" data-testid="table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Person Type</div>
              <div className="th">Date of Problem</div>
              <div className="th">Problem Type</div>
              <div className="th">Location</div>
              <div className="th">Action Status</div>
            </div>

            {outstanding_actions.map((outstanding_action) => (
              <Link to={`/exports/outstanding-actions/${outstanding_action.id}`} onClick={this.handleUpdatePanel} className={`tr event ${this.colourActionsByStatus(outstanding_action)}`} key={outstanding_action.id} id={outstanding_action.id}>
                <div className="td">{outstanding_action.person}</div>
                <div className="td">{outstanding_action.type_of_person}</div>
                <div className="td">{outstanding_action.date_of_problem}</div>
                <div className="td">{capitalizeAllWords(outstanding_action.problem_type.replace("cool ", ""))}</div>
                <div className="td">{outstanding_action.location}</div>
                <div className="td center">{outstanding_action.action_status}</div>
              </Link>
            ))}
            </div>

          {this.emptyActions(outstanding_actions)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="Create Action" width="60%"
            onRequestClose={this.closePanel}>
            <CreateAction closePanel={this.closePanel} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Update Actions" width="60%"
            onRequestClose={this.closePanel}>
            <UpdateAction outstanding_action_id={this.state.actionId} closePanel={this.closePanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    outstanding_actions: [],
    loaded: false,
    error: null,
    unauthorized: null,

    actionId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        actionId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && props.id !== state.actionId) {
      return {
        actionId: props.id,
        key: Math.random()
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/outstanding-actions")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        actionId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        actionId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        actionId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    let params = "";

    if (this.props.question || this.props.selected_location || this.props.type_of_person || this.props.action_statuses.length > 0 || this.props.search) {
      params = `outstanding_action[question]=${this.props.question}&outstanding_action[location]=${this.props.selected_location}&outstanding_action[person]=${this.props.type_of_person}&outstanding_action[search]=${this.props.search}${arrayParamBuilder("outstanding_action", "action_status", this.props.action_statuses)}`
    }

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ outstanding_actions: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(OutstandingActions);
