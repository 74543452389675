export function collectHexCodes(numberOfCodes) {
  const defaultCodes = ["#eee622", "#21ed80", "#222aee", "#ee2290", "#8826bf", "#b80c09", "#ff8819", "#10e3ff"]
  let hexCodes = []

  if (numberOfCodes <= defaultCodes.length) {
    hexCodes = defaultCodes.slice(0, numberOfCodes)
  }
  else {
    hexCodes = defaultCodes
    const lighterCodes = defaultCodes.map(color => lighten(color, 0.5)).sort(() => Math.random() - 0.5);
    const darkerCodes = defaultCodes.map(color => darken(color, 0.3)).sort(() => Math.random() - 0.5);

    let index = 0
    while (hexCodes.length < numberOfCodes) {
      hexCodes.push(lighterCodes[index])
      index++

      if (index === 8) {
        break
      }
    }

    index = 0
    while (hexCodes.length < numberOfCodes) {
      hexCodes.push(darkerCodes[index])
      index++

      if (index === 8) {
        break
      }
    }
  }

  return hexCodes;
}

function lighten(hexCode, strength) {
  const { r, g, b } = hexToRgb(hexCode)

  return rgbToHex(adjustLightness(r, strength), adjustLightness(g, strength), adjustLightness(b, strength))
}

function darken(hexCode, strength) {
  const { r, g, b } = hexToRgb(hexCode)

  return rgbToHex(adjustDarkness(r, strength), adjustDarkness(g, strength), adjustDarkness(b, strength))
}

function hexToRgb(hexCode) {
  return { r: parseInt(hexCode.substring(1, 3), 16), g: parseInt(hexCode.substring(3, 5), 16), b:parseInt(hexCode.substring(5, 7), 16) }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

function adjustLightness(color, strength) {
  return Math.min(255, Math.round(color + (255 - color) * strength))
}

function adjustDarkness(color, strength) {
  return Math.max(0, Math.round(color - color * strength))
}
