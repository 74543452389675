import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import NoticeBox from '../../components/shared/NoticeBox';

export default function AssignVisitedPeopleModal(props) {
  const [people, setPeople] = useState([]);
  const [partial, setPartial] = useState("");
  const [selectedPerson, setSelectedPerson] = useState("");
  const [selectedPeople, setSelectedPeople] = useState([])

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [unauthorized, setUnauthorized] = useState(false);

  const useDebouncedEffectExceptOnMount = (effect, deps, delay) => {
    const mounted = React.useRef(false);
    React.useEffect(() => {
      if (mounted.current) {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
      } else {
        mounted.current = true;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);

    // Reset on unmount for the next mount.
    React.useEffect(() => {
      return () => mounted.current = false;
    }, []);
  }

  // Search on component mount
  useEffect(handleSearch, [])
  // Use a debounced search every time partial changes
  useDebouncedEffectExceptOnMount(handleSearch, [partial], 500)

  function handleSearch() {
    if (partial === "") {
      setLoaded(true)
      return
    }

    setLoaded(false)

    const visitable_types_of_people_params = props.visitable_types_of_people.map( type_of_person => `&person[type][]=${type_of_person}`).join("")
    const partial_param = partial ? `&person[partial]=${partial}` : ""

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?person[location]=${props.location_id}${visitable_types_of_people_params}${partial_param}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (data.length === 1) {
          setSelectedPeople((prevPeople) => ([...prevPeople, data[0]]))
          setSelectedPerson("")
          setPartial("")
        }
        else {
          setPeople(data)
          setLoaded(true)
        }
      })
      .catch(error => setError(error))
  }

  function handlePartialChange(event) {
    setPartial(event.target.value)
  }

  function handleSelectedPersonChange(event) {
    const id = event.target.value;
    if (id) {
      const person = people.find(person => person.id === parseInt(id));
      setSelectedPeople((prevPeople) => ([...prevPeople, person]))
      setSelectedPerson("")

      event.preventDefault();
    }
  }

  function handleRemovePerson(event) {
    setSelectedPeople((prevPeople) => (
      prevPeople.filter(person => person.id !== parseInt(event.target.parentNode.id))
    ))

    event.preventDefault();
  }

  function handleSubmit(event) {
    if (selectedPeople.length === 0) {
      return
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "event": {
        "event_visited_person_id": props.event_visited_person_id,
        "person_ids": selectedPeople.map(person => person.id)
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events/${props.event_id}/assign_visited_people`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response;
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        setUnauthorized(true)
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        setError(data)
      } else {
        sessionStorage.setItem("updateSuccess", `Visited ${selectedPeople.length > 1 ? "People" : "Person"} added successfully!`);
        props.handleClose()
      }
    })
    .catch(error => setError(error))

    event.preventDefault();
  }

  function renderSelectedPeople() {
    if (selectedPeople.length > 0) {
      return (
        <div className="small-bottom-margin">
          <div className="centered-text small-bottom-margin">The following people are currently assigned:</div>

          {selectedPeople.map((person) => (
            <div className="tall-line flash-highlight bigger-font" id={person.id} key={person.id}>
              {person.full_name}
              <div className="small button right" onClick={handleRemovePerson}>Remove</div>
            </div>
          ))}
        </div>
      )
    }
  }

  if (unauthorized) {
    return <Redirect to="/login"/>
  }

  if (error) {
    return (
      <div className="full-modal-container">
        <div className="full-modal-content">
          {error}
        </div>
      </div>
    )
  }

  const personCount = partial === "" ? "" : `(${people.length})`
  const selectText = loaded ? `Select ${personCount}` : "Loading...";
  const selectedIds = selectedPeople.map(person => parseInt(person.id))

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-title">Assign Visited People</div>
        <div className="centered-text small-bottom-margin">Please assign <span className="green-text">{props.typed_name}</span> to the appropriate person or people.</div>
        <div className="small-bottom-margin">
          <input name="partial" placeholder="Enter text to search" className="search-text" onChange={handlePartialChange} value={partial} />
          <select name="selectedPerson" className="search-select" onChange={handleSelectedPersonChange} value={selectedPerson.id || ""}>
            <option value="">{selectText}</option>
            {people.map((person) => (
              <option value={person.id} key={person.id} disabled={selectedIds.includes(person.id)}>{person.full_name}</option>
            ))}
          </select>
        </div>

        <div className="small-top-padding">
          {renderSelectedPeople()}
          <NoticeBox type="info" text={`Note: Anyone assigned as a visited person to this event will be entered into a Relationship with ${props.person_name}.`} />
          <div className="small button right" onClick={props.handleClose}>Cancel</div>
          <div disabled={selectedPeople.length === 0} className="small button right" onClick={handleSubmit}>Confirm Selection</div>
        </div>
      </div>
    </div>
  )
}
