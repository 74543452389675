import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateRole from '../../components/roles/UpdateRole';
import CreateRole from '../../components/roles/CreateRole';

import { hideElementForNonAdminsAndNonManagers } from '../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfRoles extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    roles: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/configuration/roles/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });
    this.props.history.push(`/configuration/roles/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });

    this.props.history.push('/configuration/roles');
    this.componentDidMount();
  }

  render() {
    const { roles, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of roles</h3>

          <Link to={'/configuration/roles/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdminsAndNonManagers()}`}>New Role</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Colour</div>
              <div className="th">Number of Staff</div>
            </div>
            {roles.map((role) => (
                <Link to={`/configuration/roles/${role.id}`} onClick={this.handleEditPanel} id={role.id} key={role.id} className="tr">
                  <div className="td">{role.name}</div>
                  <div className="td"><div className="color-preview" style={{backgroundColor: role.color}}/></div>
                  <div className="td">{role.person_ids_count}</div>
                </Link>
            ))}
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Role" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/configuration/roles');
              this.componentDidMount();
            }}>
            <CreateRole />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Role" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateRole id={this.state.editId} closeEditPanel={this.closeEditPanel} key={this.state.key} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/roles")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    // Handle internal linking from one UpdateRole component to another
    if (state.editPanelToggle && props.id !== state.editId) {
      return {
        editId: props.id,
        key: Math.random()
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ roles: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfRoles);
