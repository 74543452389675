import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class CreateDefaultShiftAssignment extends Component {

  constructor(props) {
    super(props);

    this.addBreakTime = this.addBreakTime.bind(this);
    this.removeBreakTime = this.removeBreakTime.bind(this);
    this.handleBreakTimeChange = this.handleBreakTimeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDayOfWeekChange = this.handleDayOfWeekChange.bind(this);
    this.handleWeekChange = this.handleWeekChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleDurationTypeChange = this.handleDurationTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.unavailableStaffSearch = this.unavailableStaffSearch.bind(this);
    this.debouncedUnavailableStaffSearch = debounce(this.unavailableStaffSearch.bind(this), 1000);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
  }

  state = {
    person_id: this.props.clickedPerson || "",
    day_of_week: this.props.day_of_week || "",
    end_day_of_week: this.props.day_of_week || "",
    start_time: this.props.clickedHour || this.generateMinStartTime(),
    end_time: this.props.clickedEndHour || this.generateClickedHourEndTime(),
    duration: this.generateInitialDuration(),
    durationType: this.generateInitalDurationType(),
    role_id: this.props.role_id || "",
    break_times: [],

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    roles: null,
    people: [],
    unavailable_person_ids: [],

    rolesLoaded: false,
    peopleLoaded: true,

    unauthorized: "",
    errors: ""
  };

  generateClickedHourEndTime() {
    const startTime = this.props.clickedHour || this.generateMinStartTime()

    let time = new Date("1970-01-01T" + startTime + "Z");
    time.setMinutes(time.getMinutes() + time.getTimezoneOffset());
    time.setHours(time.getHours() + 1);

    return time.toTimeString().slice(0, 5);
  }

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  generateInitialDuration() {
    let duration = this.calculateDurationInMinutes({start_time: this.props.clickedHour || this.generateMinStartTime(), end_time: this.props.clickedEndHour || this.generateClickedHourEndTime()})
    return this.shouldDisplayInHours(duration) === true ? duration / 60 : duration
  }

  generateInitalDurationType() {
    let duration = this.calculateDurationInMinutes({start_time: this.props.clickedHour || this.generateMinStartTime(), end_time: this.props.clickedEndHour || this.generateClickedHourEndTime()})
    return this.shouldDisplayInHours(duration) ? "hours" : "minutes"
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "role_id") {
      const default_period_days = (this.state.roles.find(role => (role.id === parseInt(value)))?.default_period_weeks || this.state.location_default_period_weeks || 1) * 7;

      this.setState(prevState => {
        return ({
          day_of_week: prevState.day_of_week % default_period_days
        })
      })

      this.handlePersonSearch(this.state.partial, value)
    }

    if (name === "role_id" || name === "day_of_week") {
      this.debouncedUnavailableStaffSearch({start_time: this.state.start_time, end_time: this.state.end_time})
    }
  }

  handleDayOfWeekChange(event) {
    let value = parseInt(event.target.value)

    this.setState(prevState => {
      let weekNumber = Math.floor(prevState.day_of_week / 7)
      return ({day_of_week: value + (weekNumber * 7)})
    });

  }

  handleWeekChange(event) {
    let value = event.target.value;

    this.setState(prevState =>
      ({ day_of_week: (prevState.day_of_week % 7) + (value * 7) })
    );
  }


  handleTimeChange(event) {
    let times = {
      start_time: this.state.start_time,
      end_time: this.state.end_time
    }

    let name = event.target.name;
    times[name] = event.target.value;

    let duration = this.calculateDurationInMinutes(times)
    let durationType = duration ? "minutes" : this.state.durationType;

    if (duration && this.shouldDisplayInHours(duration)) {
      duration = duration / 60
      durationType = "hours"
    }

    this.setState({
      start_time: times.start_time,
      end_time: times.end_time,
      duration: duration,
      durationType: durationType
    });

    this.debouncedUnavailableStaffSearch(times)
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      peopleLoaded: false
    });

    this.debouncedHandlePersonSearch(value, this.state.role_id)
  }

  handlePersonSearch(partial, role_id) {
    if (partial === "" || role_id === "") {
      this.setState({
        people: [],
        peopleLoaded: true
      })

      return
    }

    let params = `?person[location]=${this.props.location_id}&person[role]=${role_id}&person[partial]=${partial}`

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ people: data, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, peopleLoaded: true }))

    this.unavailableStaffSearch({start_time: this.state.start_time, end_time: this.state.end_time})
  }

  calculateDurationInMinutes(times) {
    if (times.start_time === "" || times.end_time === "") {
      return ""
    }

    let start_time = new Date(`01/01/2000 ${times.start_time}`)
    let end_time = new Date(`01/01/2000 ${times.end_time}`)

    if (end_time <= start_time) {
      end_time.setDate(end_time.getDate() + 1)
    }

    return Math.floor((end_time - start_time) / 60000)
  }

  shouldDisplayInHours(duration) {
    return duration % 60 === 0 || (duration > 90 && duration % 30 === 0)
  }

  handleDurationChange(event) {
    let duration = parseFloat(event.target.value);
    let end_time = this.calculateEndTime(duration);

    this.setState({
      duration: duration,
      end_time: end_time
    });

    this.debouncedUnavailableStaffSearch({start_time: this.state.start_time, end_time: end_time})
  }

  calculateEndTime(duration) {
    if (this.state.start_time === "") {
      return ""
    }

    let end_time = new Date(`01/01/2000 ${this.state.start_time}`)

    if (this.state.durationType === "hours") {
      end_time.setHours(end_time.getHours() + duration)
      end_time.setMinutes(end_time.getMinutes() + Math.round((duration % 1) * 60) )
    }
    else {
      end_time.setMinutes(end_time.getMinutes() + duration)
    }
    let hours = ("0" + end_time.getHours()).slice(-2)
    let minutes = ("0" + end_time.getMinutes()).slice(-2)
    return `${hours}:${minutes}`
  }

  handleDurationTypeChange(event) {
    let durationType = event.target.value;

    this.setState((prevState) => {
      let duration = prevState.duration.valueOf();

      if (prevState.durationType === "minutes" && durationType === "hours") {
        duration = Math.round(duration / 60 * 100) / 100
      }
      else if (prevState.durationType === "hours" && durationType === "minutes") {
        duration = Math.round(duration * 60)
      }
      return ({
        duration: duration,
        durationType: durationType
      })
    })
  }

  handleBreakTimeChange(event) {
    let index = event.target.parentNode.parentNode.id;
    let name = event.target.name;
    let value = event.target.value;

    this.setState((prevState) => {
      let break_times = [...prevState.break_times]
      break_times[index][name] = value
      return (
        {break_times: break_times}
      )
    });
  }

  addBreakTime(event) {
    this.setState((prevState) => ({
      break_times: [...prevState.break_times, {start_time: "", duration: ""}]
    }))
    event.preventDefault();
  }

  removeBreakTime(event) {
    let index = event.currentTarget.id;

    this.setState((prevState) => {
      let break_times = [...prevState.break_times]
      break_times.splice(index, 1)

      return ({
        break_times: break_times
      })
    })
    event.preventDefault();
  }

  unavailableStaffSearch(times) {
    if (times.start_time === "" || times.end_time === "" || times.end_time === "Inval" || this.state.day_of_week === "" || this.state.role_id === "") {
      return this.setState({
        unavailable_person_ids: []
      })
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let end_day_of_week = this.state.end_time > this.state.start_time ? this.state.day_of_week : (parseInt(this.state.day_of_week) + 1) % 7

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_shift_assignments/unavailable_staff?default_shift_assignment[start_time]=${times.start_time}&default_shift_assignment[end_time]=${times.end_time}&default_shift_assignment[day_of_week]=${this.state.day_of_week}&default_shift_assignment[end_day_of_week]=${end_day_of_week}&default_shift_assignment[role_id]=${this.state.role_id}&default_shift_assignment[location_id]=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let person_id = data.unavailable_person_ids.includes(parseInt(this.state.person_id)) ? "" : this.state.person_id
        this.setState({ unavailable_person_ids: data.unavailable_person_ids, person_id: person_id })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))
  }

  handleSubmit(event) {
    if (this.state.person_id === "") {
      this.setState({"errors": {"person": "can't be blank"} });
      event.preventDefault();
      return
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let end_day_of_week = this.state.end_time > this.state.start_time ? this.state.day_of_week : (parseInt(this.state.day_of_week) + 1) % 7

    var json = JSON.stringify({
      "default_shift_assignment": {
        "person_id": this.state.person_id,
        "day_of_week": this.state.day_of_week,
        "end_day_of_week": end_day_of_week,
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "break_times": this.state.break_times,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_shift_assignments`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Template Shift Assignment created successfully!");
        this.props.closeNewPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderPeople() {
    if (this.state.role_id === "") {
      return (
        <option value="">Select a Role</option>
      )
    }

    return (
      <>
        <option value="">Select</option>
        {this.state.people.map((person) => (
          <option value={person.id} key={person.id} disabled={this.state.unavailable_person_ids.includes(person.id)}>{person.full_name}</option>
        ))}
      </>
    )
  }

  renderWeekSelector(default_period_weeks) {
    if (default_period_weeks === 1) {
      return
    }

    let weeks = []

    for (let i = 0; i < default_period_weeks; i++) {
      weeks.push(
        <option key={i} value={i}>Week {i + 1}</option>
      )
    }

    return (
      <div className="row">
        <select name="week" onChange={this.handleWeekChange} value={Math.floor(this.state.day_of_week / 7)}>
          {weeks}
        </select>
      </div>
    )
  }

  renderErrors(field) {
    if (this.state.errors[field]) {
      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { person_id, day_of_week, start_time, end_time, duration, durationType, role_id, break_times, min_start_time, max_start_time, min_end_time, max_end_time, roles, location_default_period_weeks, rolesLoaded, locationLoaded, peopleLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false || locationLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded && locationLoaded) {
      const default_period_weeks = roles.find(role => (role.id === parseInt(role_id)))?.default_period_weeks || location_default_period_weeks || 1

      let maxDuration = durationType === "hours" ? 24 : 24 * 60

      return (
        <div>
          <SetTitle title={"New Default Required Allocation | Staff Fulfilment"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'default_shift_assignment'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'default_shift_assignment'} section={'person'} />

              <label className="column">Staff Member:</label>{ this.renderErrors('person') }
              <input name="partial" placeholder="Enter text to search" className="search-text full-width" onChange={this.handlePartialChange} value={this.state.partial} />
              {peopleLoaded === false ? (
                <select name="person_id" disabled={role_id === ""} onChange={this.handleChange} value={person_id}>
                  <option value="">Loading...</option>
                </select>
              ) : (
                <select name="person_id" disabled={role_id === ""} onChange={this.handleChange} value={person_id}>
                  {this.renderPeople()}
                </select>
              )}
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'day_of_week'} />

                <label className="column">Start Day of Week:</label>{ this.renderErrors('day_of_week') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <select name="day_of_week" onChange={this.handleDayOfWeekChange} value={day_of_week % 7}>
                  <option value="">Select</option>
                  <option value="0">Monday</option>
                  <option value="1">Tuesday</option>
                  <option value="2">Wednesday</option>
                  <option value="3">Thursday</option>
                  <option value="4">Friday</option>
                  <option value="5">Saturday</option>
                  <option value="6">Sunday</option>
                </select>
              </div>

              {this.renderWeekSelector(default_period_weeks)}
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'default_shift_assignment'} section={'start_time'} />
                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>
              <div className="row">
                <HelpText page={'default_shift_assignment'} section={'end_time'} />
                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleTimeChange} />
              </div>
              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleTimeChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'default_shift_assignment'} section={'duration'} />
              <label className="column">Duration:</label>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="number" name="duration" min="0" max={maxDuration} value={duration} onChange={this.handleDurationChange} />
              </div>
              <div className="row">
                <select name="durationType" onChange={this.handleDurationTypeChange} value={durationType}>
                  <option value="minutes">Minute{duration !== 1 && "s"}</option>
                  <option value="hours">Hour{duration !== 1 && "s"}</option>
                </select>
              </div>
            </div>

            <div className="row bottom-padding">
              <HelpText page={'default_shift_assignment'} section={'break_times'} />
              <label className="column">Break Times:</label>{ this.renderErrors('break_times') }

              <div className="table big-table break-time-bg">
                <div className="tr heading">
                  <div className="th">Start time</div>
                  <div className="th">Duration</div>
                  <div className="th">Remove</div>
                </div>
                {break_times.map((break_time, index) =>
                  <div className="tr" key={index} id={index}>
                    <div className="td full-row">
                      <input type="time" name="start_time" min={min_start_time} max={max_start_time} value={break_time.start_time} onChange={this.handleBreakTimeChange} />
                    </div>
                    <div className="td">
                      <select name="duration" onChange={this.handleBreakTimeChange} value={break_time.duration}>
                        <option value="">Select</option>
                        <option value="15">15 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="45">45 minutes</option>
                        <option value="60">1 hour</option>
                        <option value="120">2 hours</option>
                      </select>
                    </div>
                    <div className="td center">
                      <div className="button" id={index} onClick={this.removeBreakTime}>Remove</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="long-tr center" style={{backgroundColor: "#fff"}}>
                <div className="long-td">
                  <div className="button" onClick={this.addBreakTime}>Add {break_times.length > 0 ? "another" : "a"} break time +</div>
                </div>
              </div>
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/role_configurations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/location_configuration?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location_default_period_weeks: data.default_period_weeks, locationLoaded: true })
      })
      .catch(error => this.setState({ error, locationLoaded: true }))
  }
}

export default CreateDefaultShiftAssignment;
