import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CreateLocation from '../../components/locations/CreateLocation';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { faTabletAlt, faUserFriends, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ListOfLocations extends Component {

  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('locations/new')
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  backgroundImage(background_image) {
    if (background_image !== null) {
      return (
        `url(${process.env.REACT_APP_ROOT_DOMAIN}/${background_image})`
      );
    }
  }

  selectHiddenLocations(location) {
    if (location.hidden === true) {
      return (
        this.locationWidget(location)
      )
    }
  }

  selectNonHiddenLocations(location) {
    if (location.hidden === false) {
      return (
        this.locationWidget(location)
      )
    }
  }

  hiddenLocationsToggle(locations) {
    const hiddenStats = locations.map((location) => (location.hidden))

    // check that at least one location is hidden. Otherwise, don't show this toggle
    if (hiddenStats.includes(true)) {
      return (
        <div className="toggle-hidden">
          <input type="checkbox" name="showHiddenLocations" id="hide_locations" checked={this.state.showHiddenLocations} onChange={this.handleCheckbox} />
          <label htmlFor="hide_locations">{this.state.showHiddenLocations === true ? "Hide" : "Show"} hidden locations</label>
        </div>
      )
    }
  }

  hiddenLocations(locations) {
    if (this.state.showHiddenLocations === true) {

      return (
        <div className="hidden-locations">
          <h3>Hidden Locations </h3>
          {locations.map((location) => (
            this.selectHiddenLocations(location)
          ))}
        </div>
      )

    }
  }

  locationWidget(location) {
    return (
      <Link to={`/location/${location.id}`} key={location.id}>
        <div className="widget locations-page full-background" style={{ backgroundImage: this.backgroundImage(location.background_image) }}>
          <div className="fade">

            <h3 className="title">{location.name}</h3>

            <div className="icons">
              <div className="no-of-people">
                <FontAwesomeIcon icon={faUserFriends} />
                {location.number_of_people}
              </div>
              <div className="no-of-events">
                <FontAwesomeIcon icon={faPencilAlt} />
                {location.number_of_events}
              </div>
              <div className="no-of-devices">
                <FontAwesomeIcon icon={faTabletAlt} />
                {location.number_of_devices}
              </div>
            </div>

          </div>
        </div>
      </Link>
    )
  }

  render() {
    const { locations, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          {baseLicense && (
            <Link to={'/locations/new'} onClick={this.handleNewPanel}>
              <div className={`button new-button locations-page ${hideElementForNonAdmins()}`}>New Location</div>
            </Link>
          )}

          {locations.map((location) => (
            this.selectNonHiddenLocations(location)
          ))}

          {this.hiddenLocationsToggle(locations)}

          {this.hiddenLocations(locations)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Location" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/locations')
              this.componentDidMount();
            }}>
            <CreateLocation />
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    locations: [],
    loaded: false,
    error: null,
    unauthorized: null,

    showHiddenLocations: false,
    newPanelToggle: false
  };

  static getDerivedStateFromProps(props, state) {
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/locations")) {
      return {
        newPanelToggle: false
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfLocations);
