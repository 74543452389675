import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateCustomAttribute from '../../components/custom-attributes/UpdateCustomAttribute';
import CreateCustomAttribute from '../../components/custom-attributes/CreateCustomAttribute';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfCustomAttributes extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    customAttributes: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/configuration/custom-attributes/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/configuration/custom-attributes/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/configuration/custom-attributes');
    this.componentDidMount();
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // now, reassign profile.order numbers
    var newResult = []
    for (var i = 0; i < result.length; i++) {
      var profile = result[i]
      profile.order = i + 1

      newResult.push(profile)
    }

    return newResult;
  };

  indexBackground(index) {
    if (index % 2 === 0) {
      return "#eee"
    }
    else {
      return "#fff"
    }
  }

  render() {
    const { customAttributes, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="section">
          <h3 className="inline-block">List of Custom Attributes</h3>

          <Link to={'/configuration/custom-attributes/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New Custom Attribute</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Type</div>
              <div className="th">Required?</div>
              <div className="th">Included in Fire Log?</div>
              <div className="th">Used in Number of Profiles</div>
            </div>
            {customAttributes.map((customAttribute) => (
                <Link to={`/configuration/custom-attributes/${customAttribute.id}`} onClick={this.handleEditPanel} id={customAttribute.id} key={customAttribute.id} className="tr">
                  <div className="td">{customAttribute.name}</div>
                  <div className="td">{customAttribute.answer_type}</div>
                  <div className="td">{customAttribute.required ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                  <div className="td center">{customAttribute.include_in_fire_log ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                  <div className="td">{customAttribute.number_of_profiles}</div>
                </Link>
            ))}
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Custom Attribute" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/configuration/custom-attributes');
              this.componentDidMount();
            }}>
            <CreateCustomAttribute/>
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Custom Attribute" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateCustomAttribute id={this.state.editId} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/custom-attributes")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/custom_attributes`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ customAttributes: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfCustomAttributes);
