import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

Honeybadger.beforeNotify(function(notice) {
  if (notice.message.incldes("__gCrWeb")) { return false; }
});

const config = {
  apiKey: '93572dc0',
  environment: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
  revision: process.env.REACT_APP_COMMIT_HASH
}

const honeybadger = Honeybadger.configure(config)

// Set license value as a backup
if (localStorage.license !== "base" && localStorage.license !== "entry") {
  localStorage.setItem("license", "base")
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary honeybadger={honeybadger}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
