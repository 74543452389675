import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';

import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ShowFaq extends Component {
  constructor(props) {
    super(props);

    this.handleVote = this.handleVote.bind(this);
  }

  state = {
    question: "",
    answer: "",
    total_score: "",
    positive_score: "",
    user_vote: "",

    loaded: false,
    loading: false,
    unauthorized: "",
    error: ""
  };

  handleVote(event) {
    this.setState({ loading: true });

    let id = this.props.id;
    let vote = parseInt(event.currentTarget.id);

    // clicking the same vote again removes it
    if (vote === this.state.user_vote) {
      vote = 0
    }

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "faqs": {
        "vote": vote
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/faqs/${id}/vote`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      this.setState({
        question: data.question,
        answer: data.answer,
        total_score: data.total_score,
        positive_score: data.positive_score,
        user_vote: data.user_vote,
        loading: false
      })
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderVotingArea(user_vote, total_score, positive_score, loading) {
    let className = "vote-option "
    className += loading ? "loading" : "link"
    return (
      <div className="voting-box">
        <div className="small-bottom-margin"><em>Did you find this helpful?</em></div>
        <div className="thumbs">
          <div className={className + (user_vote === 1 ? " clicked" : "")} id="1" onClick={this.handleVote}><FontAwesomeIcon icon={faThumbsUp} /></div>
          <div className="score">{positive_score}</div>
          <div className={className + (user_vote === -1 ? " clicked" : "")} id="-1" onClick={this.handleVote}><FontAwesomeIcon icon={faThumbsDown} /></div>
        </div>
      </div>
    )
  }

  renderVotingError(errors) {
    if (errors) {
      return(
        <div className="red-text">{errors}</div>
      )
    }
  }

  render() {
    const { question, answer, total_score, positive_score, user_vote, loaded, loading, unauthorized, error, } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={`View FAQ | Support`} />

          <div>
            <h1>{question}</h1>
            <div className="faq-answer" dangerouslySetInnerHTML={{__html: answer}}></div>
            {this.renderVotingArea(user_vote, total_score, positive_score, loading)}
          </div>

        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/faqs/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          question: data.question,
          answer: data.answer,
          total_score: data.total_score,
          positive_score: data.positive_score,
          user_vote: data.user_vote,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default ShowFaq;
