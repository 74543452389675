import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'

class UpdateOrganisation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    organisationName: "",
    timezone: "",
    device_flow_order: "",

    timezones: [],

    loaded: false,
    timezonesLoaded: false,
    unauthorized: "",
    error: "",
    errors: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value,
      success: false
    });
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
     "organisation": {
       "name": this.state.organisationName,
       "timezone": this.state.timezone,
       "device_flow_order": this.state.device_flow_order,
     }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({error: JSON.stringify(response.body)})
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        localStorage.setItem('organisationName', data.name);
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Organisation updated successfully!");
        this.props.updatePopupCallback(Math.random())
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const { organisationName, timezone, device_flow_order, timezones, timezonesLoaded, loaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || timezonesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && timezonesLoaded ) {
      return (
        <div>
          <form className="settings big-settings top-padding" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'organisation'} section={'name'} />

              <p>Organisation Name:</p>{ renderErrors(errors, 'name') }
              <input type="text" name="organisationName" value={organisationName} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'organisation'} section={'timezone'} />

              <label className="column">Timezone:</label>{ renderErrors(errors, 'timezone') }
              <select name="timezone" onChange={this.handleChange} value={timezone}>
                <option value="">Select</option>
                {timezones.map((timezone) => (
                  <option value={timezone} key={timezone}>{timezone}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'organisation'} section={'device_flow_order'} />

              <label className="column">Device Flow Order:</label>{ renderErrors(errors, 'device_flow_order') }
              <select name="device_flow_order" onChange={this.handleChange} value={device_flow_order}>
                <option value="">Select</option>
                <option value="profiles">Profiles</option>
                <option value="arriving_or_leaving">Arriving or Leaving</option>
              </select>
            </div>

            <div className="row">
              { this.renderSaveButton() }
              { renderErrorWarning(errors) }
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        localStorage.setItem('organisationName', data.name);
        this.setState({
          organisationName: data.name,
          timezone: data.timezone,
          device_flow_order: data.device_flow_order,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/list_of_timezones`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ timezones: data.timezones, timezonesLoaded: true })
      })
      .catch(error => this.setState({ error, timezonesLoaded: true }))
  }
}


export default UpdateOrganisation;
