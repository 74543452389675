import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { futureEndDate } from '../../utilities/Generic.js'

class CreateDefaultRequiredAllocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    number_of_staff: "1",
    start_date: this.props.start_date || "",
    start_time: this.props.clickedHour || this.generateMinStartTime(),
    end_time: this.generateClickedHourEndTime(),
    role_id: this.props.role_id || "",

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    roles: null,
    rolesLoaded: false,
    unauthorized: "",
    errors: ""
  };

  generateClickedHourEndTime() {
    const startTime = this.props.clickedHour || this.generateMinStartTime()

    let time = new Date("1970-01-01T" + startTime + "Z");
    time.setMinutes(time.getMinutes() + time.getTimezoneOffset());
    time.setHours(time.getHours() + 1);

    return time.toTimeString().slice(0, 5);
  }

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "weekly_required_allocation": {
        "number_of_staff": this.state.number_of_staff,
        "start_date": this.state.start_date,
        "end_date": futureEndDate(this.state.start_date, this.state.start_time, this.state.end_time),
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_required_allocations`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Required Allocation added successfully!");
        this.props.closeNewPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { number_of_staff, start_date, start_time, end_time, role_id, min_start_time, max_start_time, min_end_time, max_end_time, roles, rolesLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded) {
      return (
        <div>
          <SetTitle title={"New Weekly Required Allocation | Staff Fulfilment"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'weekly_required_allocation'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'start_date'} />

                <label className="column">Start Date:</label>{ this.renderErrors('start_date') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input type="date" name="start_date" onChange={this.handleChange} value={start_date} />
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'start_time'} />
                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>

              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'end_time'} />
                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleChange} />
              </div>

              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'weekly_required_allocation'} section={'number_of_staff'} />

              <label className="column">Number of Staff:</label>{ this.renderErrors('number_of_staff') }
              <input className="column" type="number" min="1" step="1" name="number_of_staff" value={number_of_staff} onChange={this.handleChange} />
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))
  }
}

export default CreateDefaultRequiredAllocation;
