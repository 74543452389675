import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { renderErrorWarning, renderErrors } from '../../utilities/Forms.js'

class CreateTicket extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  state = {
    title: "",
    body: "",
    category: "",
    priority: "",
    upload_file: null,

    updated: "",
    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event, run) {
    this.setState({
      updated: "",
      updating: true
    })

    const formData = new FormData()
    formData.append('ticket[title]', this.state.title)
    formData.append('ticket[category]', this.state.category)
    formData.append('ticket[priority]', this.state.priority)
    formData.append('ticket[ticket_actions_attributes][][body]', this.state.body)
    if (this.state.upload_file !== null) {
      formData.append('ticket[ticket_actions_attributes][][image]', this.state.upload_file)
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tickets/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      }
      else {
        this.setState({
          id: data.id,
          updated: true,
          updating: false,
          errors: ""
        })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Ticket created!
        </div>
      );
    }
  }

  render() {
    const { title, body, category, priority, id, updated, updating, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/support/${id}`}/>
    }

    return (
      <div>
        <SetTitle title={`Create Ticket | Support`} />
        <form className="settings big-settings" onSubmit={this.handleSubmit}>
          <div className="row">
            <HelpText page={'support'} section={'title'} />

            <label className="column">Ticket Summary:</label>{ renderErrors(errors, 'title') }
            <input className="column" type="text" name="title" value={title} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'support'} section={'body'} />

            <label className="column">Full Ticket Description:</label>{ renderErrors(errors, 'ticket_actions.body', 'description') }
            <textarea aria-required="true" name="body" value={body} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'support'} section={'category'} />
            <label className="column">Category:</label>{ renderErrors(errors, 'category') }
            <select name="category" onChange={this.handleChange} value={category}>
              <option value="">Select</option>
              <option value="Problem">Problem</option>
              <option value="Question">Question</option>
              <option value="Ideas">Ideas</option>
            </select>
          </div>

          <div className="row">
            <HelpText page={'support'} section={'priority'} />
            <label className="column">Priority:</label>{ renderErrors(errors, 'priority') }
            <select name="priority" onChange={this.handleChange} value={priority}>
              <option value="">Select</option>
              <option value="Urgent">Urgent</option>
              <option value="Important">Important</option>
              <option value="No rush">No rush</option>
            </select>
          </div>
          <label className="column">Upload an image for context (optional):</label>
          <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} accept="image/*, video/*" />

          <input type="submit" value="Create Ticket" disabled={updating} />
          { this.renderUpdated(updated) }
          { renderErrorWarning(errors) }
        </form>
      </div>
    );
  }
}

export default CreateTicket;
