import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import BigLogo from '../../components/header/BigLogo';
import SetTitle from '../../components/shared/SetTitle';

class Logout extends Component {

  state = {
    loggedOut: false
  };

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/sessions`, requestOptions)
    .then(res => res.json())
      .then((data) => {
        if (data === true) {
          const cookies = new Cookies();
          cookies.remove('auth_token');
          cookies.remove('support');
          localStorage.removeItem('organisation');
          localStorage.removeItem('organisationName');
          localStorage.removeItem('access_levels');
          localStorage.removeItem('license');

          this.setState({loggedOut: true})
        }
        else if (data.errors) {
          this.setState({errors: JSON.stringify(data)})
        }
      })
      .catch(error => this.setState({error: error.message}))
  }

  renderResponse(state) {
    if (state.loggedOut) {
      return (
        <div>
        <p>You have been successfully logged out.</p>
        <p><Link to={'/login'}>Click here to log in again.</Link></p>
        </div>
      );
    }
    else {
      return (
        <div>
          <p>Sorry, there's been a problem.</p>
          <p>Try going back to the <Link to={'/'}>homepage</Link>.</p>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="login-page">
        <SetTitle title={"Logout"} />
        <LoggedOutHeader />

        <div className="center-container">
          <div className="login-box">
            <p className="welcome-to">Thank you for using...</p>

            <BigLogo />

            <div className="logout-message">
              {
                this.renderResponse(this.state)
              }
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default Logout;
