import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import FireLog from '../../components/locations/FireLog';
import PopupBox from '../../components/shared/PopupBox';
import ResellerLogo from '../../components/header/ResellerLogo';
import ResellerCSS from '../../components/header/ResellerCSS';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";


class FireLogIndex extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    location: "",

    locations: [],
    loaded: false,
    key: 0
  };

  handleChange(event) {
    let name = event.target.name;
    var value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random()
    });
  }

  displayFireLog(fire_log_id) {
    if (this.state.location !== "") {
      return <FireLog id={this.state.location} fire_log_id={fire_log_id} key={this.state.key + 1} />
    }
    else {
      return <></>
    }
  }

  displayNoLocations() {
    if (this.state.locations.length === 0) {
      return (
        <p>Sorry, you don't have access to any locations. Please contact one of your admins for help.</p>
      )
    }
  }

  render() {
    const { match: { params } } = this.props;
    const { location, locations, loaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to={{ pathname: '/login', fireLogLocation: location }}/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <PopupBox />
          <SetTitle title={"Fire Log"} />

          <header>
            <ResellerLogo />

            <div className="top-header">
              <nav className="left-nav">
                <ul>
                  <li><Link to={'/fire-log'}><FontAwesomeIcon icon={faHome} /> Firelog</Link></li>
                </ul>
              </nav>

              <nav className="right-nav">
                <ul>
                  <li><Link to={'/logout'}><FontAwesomeIcon icon={faSignOutAlt} /> Log Out</Link></li>
                </ul>
              </nav>
            </div>

            <div className="bottom-header">
              <div className="dr-header-text">
                Digital Reception
              </div>
            </div>

            <ResellerCSS />
          </header>

          <div className="main-page">
            <h2 className="page-title">Locations</h2>
            <OrganisationName />

            <div className="export-selection">
              <label className="column">Select a Location:</label>
              <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            { this.displayNoLocations() }

            <div className="widgets">
              {this.displayFireLog(params.fire_log_id)}
            </div>
          </div>

        </div>
      );
    }
  }

  getLocationId(data) {
    if (data.length > 0) {
      return data[0].id
    }
    else {
      return ""
    }
  }

  componentDidMount() {
    // use email param as an autofill candidate for the login screen
    const params = new URLSearchParams(this.props.location.search)
    const loginEmail = params.get('email')
    const location = params.get('location')

    if (loginEmail) {
      sessionStorage.setItem("loginEmail", loginEmail)
      this.setState({ emailSet: true })
      this.props.history.push(`/fire-log?location=${location}`)
    }

    // use location param to set the currently selected location
    if (location) {
      this.setState({ location: location })
    }


    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          sessionStorage.removeItem("loginEmail")
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location: this.getLocationId(data), locations: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default FireLogIndex;
