import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

import DevicesIndex from '../devices/Index';
import ProfilesIndex from '../profiles/Index';
import FeedbackIndex from '../feedback/Index';
import CustomAttributesIndex from '../custom-attributes/Index';
import RolesIndex from '../roles/Index';
import TagsIndex from '../tags/Index';

import BtnActions from '../../images/btn_actions_1075_725.png';
import BtnDevices from '../../images/btn_devices_1075_725.png';
import BtnFeedback from '../../images/btn_feedback_1075_725.png';
import BtnPeople from '../../images/btn_people_1075_725.png';
import BtnRoles from '../../images/roles.png';
import BtnTags from '../../images/tags.png';

class ConfigurationIndex extends Component {
  constructor(props) {
    super(props);

    this.updatePopupCallback = this.updatePopupCallback.bind(this);
  }

  state = {
    page: "index"
  };

  currentPage(page) {
    if (page === this.state.page) {
      return "current-page"
    }
  }

  updatePopupCallback(id) {
    this.setState({
      resetId: id
    })
  }

  renderConfigurationsIndex() {
    const baseLicense = localStorage.license === "base"

    if (this.state.page === "index") {
      return (
        <div>
          <SetTitle title={"Configuration"} />
          <h2 className="page-title">Configuration</h2>
          <OrganisationName />

          <div className="widgets">
            <Link to={"/configuration/devices"}>
              <div className="widget exports-page" style={{ backgroundImage: `url(${BtnDevices})` }}>
                <h3 className="title">Devices</h3>
              </div>
            </Link>

            <Link to={"/configuration/profiles"}>
              <div className="widget exports-page" style={{ backgroundImage: `url(${BtnPeople})` }}>
                <h3 className="title">Profiles</h3>
              </div>
            </Link>

            {baseLicense && (
              <Link to={"/configuration/feedback"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnFeedback})` }}>
                  <h3 className="title">Feedback</h3>
                </div>
              </Link>
            )}

            {baseLicense && (
              <Link to={"/configuration/custom-attributes"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnActions})` }}>
                  <h3 className="title">Custom Attributes</h3>
                </div>
              </Link>
            )}

            {baseLicense && (
              <Link to={"/configuration/roles"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnRoles})` }}>
                  <h3 className="title">Roles</h3>
                </div>
              </Link>
            )}

            {baseLicense && (
              <Link to={"/configuration/tags"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnTags})` }}>
                  <h3 className="title">People Tags</h3>
                </div>
              </Link>
            )}
          </div>
        </div>
      )
    }
  }

  renderPages() {
    switch (this.state.page) {
      case "devices":
        return <DevicesIndex {...this.props} />;
      case "profiles":
        return <ProfilesIndex {...this.props} />;
      case "feedback":
        return <FeedbackIndex {...this.props} />;
      case "custom-attributes":
        return <CustomAttributesIndex {...this.props} />;
      case "roles":
        return <RolesIndex {...this.props} />;
      case "tags":
        return <TagsIndex {...this.props} />;
      default:
        return this.renderConfigurationsIndex();
    }
  }

  render() {
    const baseLicense = localStorage.license === "base"

    return (
      <div>
        <Header />

        <div className="settings-page">
          <div className="sidebar">
            <Link to={"/configuration"} className={this.currentPage("index")}>List of Configurations</Link>
            <Link to={"/configuration/devices"} className={this.currentPage("devices")}>Devices</Link>
            <Link to={"/configuration/profiles"} className={this.currentPage("profiles")}>Profiles</Link>
            {baseLicense && (<Link to={"/configuration/feedback"} className={this.currentPage("feedback")}>Feedback</Link>)}
            {baseLicense && (<Link to={"/configuration/custom-attributes"} className={this.currentPage("custom-attributes")}>Custom Attributes</Link>)}
            {baseLicense && (<Link to={"/configuration/roles"} className={this.currentPage("roles")}>Roles</Link>)}
            {baseLicense && (<Link to={"/configuration/tags"} className={this.currentPage("tags")}>Tags</Link>)}
          </div>
          <div className="main-content">
            <div className="main-page">
              {this.renderPages()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    // Render sidebar page based on URL
    const page = props.match.params.page

    if (page !== undefined) {
      return {
        page: page
      }
    }
    else {
      return {
        page: "index"
      }
    }
  }
}

export default ConfigurationIndex;
