import React, { Component } from 'react';

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PopupBox extends Component {

  constructor(props) {
    super(props);

    this.boxHider = this.boxHider.bind(this)
  }

  state = {
    showPopupToggle: "",
    output: "",
    type: ""
  }

  boxHider() {
    this.setState({
      showPopupToggle: false
    })
  }

  componentDidUpdate() {
    // passing sessionStorage into state
    if (sessionStorage.getItem('updateSuccess')) {
      clearTimeout(this.boxStopper)
      this.setState({
        showPopupToggle: false,
        output: sessionStorage.getItem('updateSuccess'),
        type: ""
      });
      sessionStorage.removeItem('updateSuccess');
      setTimeout(() => { this.setState({showPopupToggle: true}); }, 1);
      this.boxStopper = setTimeout(() => { this.boxHider(); }, 5001)
    }

    // delete messages are more verbose and require more uptime
    else if (sessionStorage.getItem('deleteSuccess')) {
      clearTimeout(this.boxStopper)
      this.setState({
        showPopupToggle: false,
        output: sessionStorage.getItem('deleteSuccess'),
        type: "slow"
      });
      sessionStorage.removeItem('deleteSuccess');
      setTimeout(() => { this.setState({showPopupToggle: true}); }, 1);
      this.boxStopper = setTimeout(() => { this.boxHider(); }, 10001)
    }
  }

  render() {
    if (this.state.showPopupToggle) {
      return (
        <div className={`update-popup ${this.state.type}`} onClick={this.boxHider}>
          {this.state.output}
          <FontAwesomeIcon className="close-button" icon={faTimes} />
        </div>
      )
    }
    else {
      return (
        <>
        </>
      );
    }
  }
}

export default PopupBox;
