import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'
import NoticeBox from '../../components/shared/NoticeBox';
import LoadingSpinner from '../../components/shared/LoadingSpinner';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UpdateImport extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleHeadersChange = this.handleHeadersChange.bind(this);
    this.handleAddHeadersRow = this.handleAddHeadersRow.bind(this);
    this.handleRemoveHeadersRow = this.handleRemoveHeadersRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickSavePreviewSinceLast = this.handleClickSavePreviewSinceLast.bind(this);
    this.handleClickSavePreviewGetAll = this.handleClickSavePreviewGetAll.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handlePreviewFailureAcknowledgement = this.handlePreviewFailureAcknowledgement.bind(this);
    this.handleAcceptPreview = this.handleAcceptPreview.bind(this);
  }

  state = {
    name: "",
    provider: "",
    type_of_person: "",
    api_key: "",
    home_id: "",
    headers: ":",
    inactive: "",
    unique_id_mapping: "",
    first_name_mapping: "",
    last_name_mapping: "",
    alternate_name_mapping: "",
    email_mapping: "",
    phone_mapping: "",
    related_to_mapping: "",
    type_of_related_to_mapping: "",
    filter_mapping: "",
    filter_value: "",
    export_events_to_mcm: false,
    export_resident_events_to_mcm: false,
    location_id: "",
    import_has_run: false,
    import_has_failed_too_many_times: false,
    preview: false,
    get_all: false,

    import_preview: null,

    importResults: [],
    locations: [],

    loaded: false,
    importResultsLoaded: false,
    locationsLoaded: false,

    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleHeadersChange(event, skipIndex) {
    let headers = ""

    document.querySelectorAll('#header-box div').forEach((row) => {
      if (row.dataset.key === skipIndex) {
        return
      }

      if (headers.length > 0) {
        headers += "\n"
      }

      const key = row.querySelector('input[name="key"]').value.trim()
      const value = row.querySelector('input[name="value"]').value.trim()

      headers += `${key}: ${value}`
    })

    this.setState({headers: headers});
  }

  handleAddHeadersRow(event) {
    let headers = this.state.headers
    headers += "\n:"

    this.setState({headers: headers});

    event.preventDefault();
  }

  handleRemoveHeadersRow(event) {
    let skipIndex = event.currentTarget.parentElement.dataset.key;

    this.handleHeadersChange(event, skipIndex)

    event.preventDefault();
  }

  handleCloseModal(event) {
    this.setState({ import_preview: null })

    event.preventDefault();
  }

  handlePreviewFailureAcknowledgement(event) {
    this.setState({ import_preview: null })

    // re-request Import Results so latest failure is included
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

  fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/${this.props.id}/results`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401) {
        this.setState({
          error: JSON.stringify(response.body),
          unauthorized: true
        })
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      this.setState({ importResults: data, importResultsLoaded: true })
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handleSubmit(event, preview, get_all) {
    this.setState({
      updating: true,
      preview: preview,
      get_all: get_all
    })
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let api_key = this.state.api_key

    if (this.state.provider === "cool_care") {
      // Store api_key and home_id as serialised json
      api_key = JSON.stringify({ key: this.state.api_key, home_id: this.state.home_id })
    }

    var json = JSON.stringify({
      "import": {
        "name": this.state.name,
        "provider": this.state.provider,
        "type_of_person": this.state.type_of_person,
        "api_key": api_key,
        "headers": this.state.headers,
        "inactive": this.state.inactive,
        "unique_id_mapping": this.state.unique_id_mapping,
        "first_name_mapping": this.state.first_name_mapping,
        "last_name_mapping": this.state.last_name_mapping,
        "alternate_name_mapping": this.state.alternate_name_mapping,
        "email_mapping": this.state.email_mapping,
        "phone_mapping": this.state.phone_mapping,
        "related_to_mapping": this.state.related_to_mapping,
        "type_of_related_to_mapping": this.state.type_of_related_to_mapping,
        "filter_mapping": this.state.filter_mapping,
        "filter_value": this.state.filter_value,
        "export_events_to_mcm": this.state.export_events_to_mcm,
        "export_resident_events_to_mcm": this.state.export_resident_events_to_mcm,
        "location_id": this.state.location_id,
        "preview": preview,
        "get_all": get_all
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      }
      else {
        this.setState({
          updating: false,
          errors: ""
        })

        if (preview === true) {
          let api_key = data.api_key
          let home_id = ""

          if (data.provider === "cool_care") {
            // We store api_key and home_id together as stringified json
            const parsed_api_key = JSON.parse(data.api_key)
            api_key = parsed_api_key.key
            home_id = parsed_api_key.home_id
          }

          this.setState({
            name: data.name,
            provider: data.provider,
            type_of_person: data.type_of_person,
            api_key: api_key,
            home_id: home_id,
            headers: data.headers,
            inactive: data.inactive,
            unique_id_mapping: data.unique_id_mapping,
            first_name_mapping: data.first_name_mapping,
            last_name_mapping: data.last_name_mapping,
            alternate_name_mapping: data.alternate_name_mapping,
            email_mapping: data.email_mapping,
            phone_mapping: data.phone_mapping,
            related_to_mapping: data.related_to_mapping,
            type_of_related_to_mapping: data.type_of_related_to_mapping,
            filter_mapping: data.filter_mapping,
            filter_value: data.filter_value,
            export_events_to_mcm: data.export_events_to_mcm,
            export_resident_events_to_mcm: data.export_resident_events_to_mcm,
            location_id: data.location_id,
            import_has_run: data.import_has_run,
            import_preview: data.import_preview,
            loaded: true
          })
        }
        else {
          sessionStorage.setItem("updateSuccess", "Import updated successfully!");

          this.props.closeEditPanel()
        }
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handleClickSave(event){
    this.handleSubmit(event, false);;
  }

  handleClickSavePreviewGetAll(event) {
    this.handleSubmit(event, true, true);;
  }

  handleClickSavePreviewSinceLast(event) {
    this.handleSubmit(event, true, false);;
  }

  handleAcceptPreview(event) {
    this.setState({
      updating: true
      })
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/${id}/accept_preview`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422 || response.status === 404) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      }
      else {
        this.setState({
          updating: false,
          errors: ""
        })

        sessionStorage.setItem("updateSuccess", "Previewed data will be imported shortly.");

        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdating(preview, updating, get_all) {
    if (updating && preview) {
      return (
        <div>
          Saving and preparing preview of data...
        </div>
      );
    }
    else if (updating) {
      return (
        <div>
          Saving...
        </div>
      );
    }
  }

  emptyResults(importResults) {
    if (importResults.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            This Import has not yet run.
          </div>
        </div>
      )
    }
  }

  renderHeadersBoxes(headers) {
    let fields = []

    if (headers === null) {
      fields.push(
        <div className="key-value-pairs" key="0" data-key="0">
          <input type="text" name="key" value="" onChange={this.handleHeadersChange} />
          <input type="text" name="value" value="" onChange={this.handleHeadersChange} />
          <FontAwesomeIcon onClick={this.handleRemoveHeadersRow} icon={faTimes} />
        </div>
      )
    }
    else {
      const rows = headers.split("\n")

      rows.forEach((row, index) => {
        const key = row.split(":")[0].trim()
        const value = row.split(":")[1].trim()

        fields.push(
          <div className="key-value-pairs" key={index} data-key={index}>
            <input type="text" name="key" value={key} onChange={this.handleHeadersChange} />
            <input type="text" name="value" value={value} onChange={this.handleHeadersChange} />
            <FontAwesomeIcon onClick={this.handleRemoveHeadersRow} icon={faTimes} />
          </div>
        )
      })
    }

    return (
      <div id="header-box">
        {fields}
      </div>
    )
  }

  providerSpecificFields() {
    const {
      api_key, home_id, headers, unique_id_mapping, first_name_mapping, last_name_mapping, alternate_name_mapping,
      email_mapping, phone_mapping, related_to_mapping, type_of_related_to_mapping, filter_mapping, filter_value,
    } = this.state;

    if (this.state.provider === "generic") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'apiUrl'} />
            <label className="column">Full API URL:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'headers'} />
            <label className="column">Headers:</label>{ this.renderErrors('headers') }
          </div>
          <div className="row">
            <div className="key-value-pairs">
              <label>Key:</label>
              <label>Value:</label>
            </div>
            {this.renderHeadersBoxes(headers)}
            <button className="button right-button" onClick={this.handleAddHeadersRow}>Add row</button>
          </div>

          <div className="row">
            <HelpText page={'import'} section={'idMapping'} />
            <label className="column">Unique ID Mapping:</label>{ this.renderErrors('unique_id_mapping') }
            <input className="column" type="text" name="unique_id_mapping" value={unique_id_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'firstNameMapping'} />
            <label className="column">First Name Mapping:</label>{ this.renderErrors('first_name_mapping') }
            <input className="column" type="text" name="first_name_mapping" value={first_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'lastNameMapping'} />
            <label className="column">Last Name Mapping:</label>{ this.renderErrors('last_name_mapping') }
            <input className="column" type="text" name="last_name_mapping" value={last_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'alternateNameMapping'} />
            <label className="column">Alternate Name Mapping:</label>{ this.renderErrors('alternate_name_mapping') }
            <input className="column" type="text" name="alternate_name_mapping" value={alternate_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'emailMapping'} />
            <label className="column">Email Mapping:</label>{ this.renderErrors('email_mapping') }
            <input className="column" type="text" name="email_mapping" value={email_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'phoneMapping'} />
            <label className="column">Phone Mapping:</label>{ this.renderErrors('phone_mapping') }
            <input className="column" type="text" name="phone_mapping" value={phone_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'relatesMapping'} />
            <label className="column">Related To Mapping:</label>{ this.renderErrors('related_to_mapping') }
            <input className="column" type="text" name="related_to_mapping" value={related_to_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'relatesTypeMapping'} />
            <label className="column">Type of Related To Mapping:</label>{ this.renderErrors('type_of_related_to_mapping') }
            <input className="column" type="text" name="type_of_related_to_mapping" value={type_of_related_to_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'filterMapping'} />
            <label className="column">Filter Mapping:</label>{ this.renderErrors('filter_mapping') }
            <input className="column" type="text" name="filter_mapping" value={filter_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'filterValueMapping'} />
            <label className="column">Filter Value:</label>{ this.renderErrors('filter_value') }
            <input className="column" type="text" name="filter_value" value={filter_value} onChange={this.handleChange} />
          </div>
        </div>
      )
    }
    else if (this.state.provider === "mcm") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'mcmKey'} />
            <label className="column">mCare API Key:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          { this.exportEventsCheckbox() }
        </div>
      )
    }
    else if (this.state.provider === "cool_care") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'coolCareKey'} />
            <label className="column">Cool Care API Key:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'coolCareHomeId'} />
            <label className="column">Cool Care Home ID:</label>{ this.renderErrors('home_id') }
            <input className="column" type="text" name="home_id" value={home_id} onChange={this.handleChange} />
          </div>
        </div>
      )
    }
  }

  exportEventsCheckbox() {
    const { export_events_to_mcm, export_resident_events_to_mcm } = this.state;

    if (this.state.type_of_person === "resident") {
      return (
        <>
          <div className="row">
            <HelpText page={'import'} section={'exportEventsToMcm'} />

            <input className="column" type="checkbox" name="export_events_to_mcm" id="export_events_to_mcm" checked={export_events_to_mcm} onChange={this.handleCheckbox} />
            <label className="column checkbox-label" htmlFor="export_events_to_mcm">Export Visitor Events to mCare?</label>{ this.renderErrors('export_events_to_mcm') }
          </div>

          <div className="row">
            <HelpText page={'import'} section={'exportResidentEventsToMcm'} />

            <input className="column" type="checkbox" name="export_resident_events_to_mcm" id="export_resident_events_to_mcm" checked={export_resident_events_to_mcm} onChange={this.handleCheckbox} />
            <label className="column checkbox-label" htmlFor="export_resident_events_to_mcm">Export Resident Events to mCare?</label>{ this.renderErrors('export_resident_events_to_mcm') }
          </div>
        </>
      )
    }
  }

  renderSaveButtons() {
    if (adminUser()) {
      return (
        <div>
          {this.renderPreviewButtons()}
          {this.renderSaveAsDraftButton()}
        </div>
      )
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }



  renderPreviewButtons() {
    if (this.state.provider === "mcm" && this.state.type_of_person === "resident" && this.state.import_has_run) {
      const newData = "Preview a grab of only changes made on mCare since the last time the import ran.";
      const allData = "Preview a grab of all import data, old and new.";
      return (
        <>
          <button className="button" title={newData} disabled={this.state.updating} onClick={this.handleClickSavePreviewSinceLast}>
            Save & preview new data
            <LoadingSpinner condition={this.state.updating && this.state.preview && this.state.get_all === false} />
          </button>
          <button className="button" title={allData} disabled={this.state.updating} onClick={this.handleClickSavePreviewGetAll}>
            Save & preview all data
            <LoadingSpinner condition={this.state.updating && this.state.preview && this.state.get_all} />
          </button>
        </>
      )
    }
    else {
      return (
        <button className="button" disabled={this.state.updating} onClick={this.handleClickSavePreviewGetAll}>
          Save & preview data
          <LoadingSpinner condition={this.state.updating && this.state.preview} />
        </button>
      )
    }
  }

  renderSaveAsDraftButton() {
    if (!this.state.import_has_run){
      return (
        <button className="button" value="Save as draft" disabled={this.state.updating} onClick={this.handleClickSave}>
          Save as draft
          <LoadingSpinner condition={this.state.updating && this.state.preview === false} />
        </button>
      )
    }
  }

  renderImportPreviewModal() {
    if (this.state.import_preview) {
      const preview = this.state.import_preview;
      if (preview.errors) {
        return (
          <div className="full-modal-container">
            <div className="full-modal-content">
              <div className="modal-title">Import Preview</div>
              <label className="column red-text">{preview.errors}</label>
              <div>An attempt at generating a preview for this Import failed. Double check all details are correct and try again later.</div>

              <button className="modal-button" value="ok" onClick={this.handlePreviewFailureAcknowledgement}>OK</button>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="full-modal-container">
            <div className="full-modal-content">
              <div className="modal-title">Import Preview</div>
              <label className="column">{preview.log}</label>
              {this.renderPreviewTable(preview)}

              <div className="center-two-buttons">
                <button className="modal-button" value="yes" onClick={this.handleAcceptPreview}>Accept Preview</button>
                <button className="modal-button" value="no" onClick={this.handleCloseModal}>Close Preview</button>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderPreviewTable(preview) {
    if (preview.people.length > 0) {
      return (
        <>
          <div className="table big-table" data-testid="table">
            <div className="tr heading">
              <div className="th">First Name</div>
              <div className="th">Last Name</div>
              <div className="th">Email</div>
              <div className="th">Phone</div>
              <div className="th">Type</div>
              <div className="th">Notes</div>
            </div>
            {preview.people.filter(person => person.id === null).map((person, index) => (
                <div id={"previewPerson" + index} key={"previewPerson" + index} className="tr">
                  <div className="td green-text">{person.first_name}</div>
                  <div className="td green-text">{person.last_name}</div>
                  <div className="td green-text">{person.email}</div>
                  <div className="td green-text">{person.phone}</div>
                  <div className="td green-text">{person.type_of_person}</div>
                  <div className="td">{this.renderPersonNotes(person)}</div>
                </div>
            ))}
            {preview.people.map((person, person_index) => (
              person.related_people.filter(related_person => related_person.id === null).map((related_person, related_person_index) => (
                  <div id={"previewPerson" + person_index + "relatedPerson" + related_person_index} key={"previewPerson" + person_index + "relatedPerson" + related_person_index} className="tr">
                    <div className="td green-text">{related_person.first_name}</div>
                    <div className="td green-text">{related_person.last_name}</div>
                    <div className="td green-text">{related_person.email}</div>
                    <div className="td green-text">{related_person.phone}</div>
                    <div className="td green-text">Visitor</div>
                    <div className="td">{this.renderRelatedPersonNotes(related_person, person)}</div>
                  </div>
            ))))}
            {preview.people.filter(person => person.id !== null).map((person, index) => (
                <Link to={`/people/${person.id}`} target="_blank" id={"previewPerson" + index} key={"previewPerson" + index} className="tr">
                  <div className="td">{this.renderChanges(person.current_first_name, person.first_name)}</div>
                  <div className="td">{this.renderChanges(person.current_last_name, person.last_name)}</div>
                  <div className="td">{this.renderChanges(person.current_email, person.email)}</div>
                  <div className="td">{this.renderChanges(person.current_phone, person.phone)}</div>
                  <div className="td">{person.type_of_person}</div>
                  <div className="td">{this.renderPersonNotes(person)}</div>
                </Link>
            ))}
            {preview.people.map((person, person_index) => (
              person.related_people.filter(related_person => related_person.id !== null).map((related_person, related_person_index) => (
                  <Link to={`/people/${related_person.id}`} target="_blank" id={"previewPerson" + person_index + "relatedPerson" + related_person_index} key={"previewPerson" + person_index + "relatedPerson" + related_person_index} className="tr">
                    <div className="td">{this.renderChanges(related_person.current_first_name, related_person.first_name)}</div>
                    <div className="td">{this.renderChanges(related_person.current_last_name, related_person.last_name)}</div>
                    <div className="td">{this.renderChanges(related_person.current_email, related_person.email)}</div>
                    <div className="td">{this.renderChanges(related_person.current_phone, related_person.phone)}</div>
                    <div className="td">{related_person.type_of_person}</div>
                    <div className="td">{this.renderRelatedPersonNotes(related_person, person)}</div>
                  </Link>
            ))))}
          </div>
          <div>New attributes will appear in <span className="green-text">green</span>. New people will have all their attributes appear in  <span className="green-text">green</span>.</div><div> Overwritten attributes of existing people will appear in <span className="red-text">red</span>.</div><div> Unchanged attributes of existing people will appear in black.</div><div></div>
        </>
      )
    }
  }

  renderChanges(old_value, new_value) {
    const parsed_old_value = (old_value || "").trim().toLowerCase();
    const parsed_new_value = (new_value || "").trim().toLowerCase();

    if (parsed_old_value !== parsed_new_value) {
      return (
        <span><span className="red-text">{old_value}</span> -> <span className="green-text">{new_value}</span></span>
      )
    }
    else {
      return new_value
    }
  }

  renderPersonNotes(person) {
    if (person.type_of_person === "Resident") {
      return `This Resident has ${person.related_people.length} related ${person.related_people.length === 1 ? "person" : "people"}.`
    }
  }

  renderRelatedPersonNotes(related_person, person) {
    return `This person is ${person.first_name} ${person.last_name}'s ${related_person.relationship}.`
  }

  renderAutomatedRunStatus() {
    let text = "";
    let type = "info";

    if (this.state.inactive === false && this.state.import_has_run && this.state.import_has_failed_too_many_times === false) {
      text = "This import will run automatically at night."
    }

    else {
      let reasons = [];
      if (this.state.inactive) {
        reasons.push("This import is set to inactive. Only active imports run automatically.")
      }

      if (this.state.import_has_run === false) {
        reasons.push("Imports will only run automatically once a previewed import has been accepted.")
      }

      if (this.state.import_has_failed_too_many_times) {
        reasons.push("This import has failed to run too many times in a row to run automatically, please try previewing manually to see if the problem can be solved.")
        type = "warning"
      }

      if (reasons.length > 1) {
        text = "This import will <b>NOT</b> run automatically for the following reasons: <br/>" + reasons.join(" <br/>")
      }
      else {
        text = reasons[0]
      }
    }

    return (
      <NoticeBox type={type} text={text} />
    )
  }

  render() {
    const { name, provider, type_of_person, inactive, location_id, locations, importResults, loaded, importResultsLoaded, locationsLoaded, unauthorized, errors, error, preview, updating } = this.state;
    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || importResultsLoaded === false || locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && importResultsLoaded && locationsLoaded) {
      const loading = updating ? "loading" : ""

      return (
        <div className={loading}>
          <SetTitle title={`Edit Import | ${name} | Imports`} />

          <h3>Import Results</h3>
          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Ran At</div>
              <div className="th">Success</div>
              <div className="th">Log</div>
            </div>
            {importResults.map((importResult, index) => (
                <div key={index} className="tr">
                  <div className="td">{importResult.ran_at}</div>
                  <div className="td center">{importResult.success ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                  <div className="td">{importResult.log}</div>
                </div>
            ))}
          </div>

          {this.emptyResults(importResults)}

          <h3 className="import-title">Import Details</h3>
          <form className="settings big-settings">
            {this.renderAutomatedRunStatus()}
            <div className="row">
              <HelpText page={'import'} section={'name'} />

              <label className="column">Import Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'import'} section={'location'} />

              <label className="column">Location:</label>{ this.renderErrors('location') }
              <select name="location_id" onChange={this.handleChange} value={location_id}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'import'} section={'provider'} />

              <label className="column">Provider:</label>{ this.renderErrors('provider') }
              <select name="provider" onChange={this.handleChange} value={provider}>
                <option value="">Select</option>
                <option value="generic">Generic</option>
                <option value="mcm">mCare</option>
                <option value="cool_care">Cool Care</option>
              </select>
            </div>

            <div className="row">
              <HelpText page={'import'} section={'personType'} />

              <label className="column">Type of Person:</label>{ this.renderErrors('type_of_person') }
              <select name="type_of_person" onChange={this.handleChange} value={type_of_person}>
                <option value="">Select</option>
                <option value="resident">Resident</option>
                <option value="staff">Staff</option>
                <option disabled={provider === "mcm"} value="visitor">Visitor</option>
                <option disabled={provider === "mcm"} value="contractor">Contractor</option>
                <option disabled={provider === "mcm"} value="industry professional">Industry Professional</option>
                <option disabled={provider === "mcm"} value="other">Other</option>
              </select>
            </div>

            {this.providerSpecificFields()}

            <div className="row">
              <HelpText page={'import'} section={'inactive'} />

              <input className="column" type="checkbox" name="inactive" id="inactive" checked={inactive} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="inactive">Inactive?</label>{ this.renderErrors('inactive') }
            </div>

            { this.renderSaveButtons() }
            { this.renderUpdating(preview, updating) }
            { this.renderImportPreviewModal() }
            { renderErrorWarning(errors) }
          </form>

        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let api_key = data.api_key
        let home_id = ""

        if (data.provider === "cool_care") {
          // We store api_key and home_id together as stringified json
          const parsed_api_key = JSON.parse(data.api_key)
          api_key = parsed_api_key.key
          home_id = parsed_api_key.home_id
        }

        this.setState({
          name: data.name,
          provider: data.provider,
          type_of_person: data.type_of_person,
          api_key: api_key,
          home_id: home_id,
          headers: data.headers,
          inactive: data.inactive,
          unique_id_mapping: data.unique_id_mapping,
          first_name_mapping: data.first_name_mapping,
          last_name_mapping: data.last_name_mapping,
          alternate_name_mapping: data.alternate_name_mapping,
          email_mapping: data.email_mapping,
          phone_mapping: data.phone_mapping,
          related_to_mapping: data.related_to_mapping,
          type_of_related_to_mapping: data.type_of_related_to_mapping,
          filter_mapping: data.filter_mapping,
          filter_value: data.filter_value,
          export_events_to_mcm: data.export_events_to_mcm,
          export_resident_events_to_mcm: data.export_resident_events_to_mcm,
          location_id: data.location_id,
          import_has_run: data.import_has_run,
          import_has_failed_too_many_times: data.import_has_failed_too_many_times,
          import_preview: data.import_preview,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/${id}/results`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ importResults: data, importResultsLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

  }
}

export default UpdateImport;
