import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning, renderErrors, capitalize } from '../../utilities/Forms.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class UpdatePlannedEventSettings extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    "visitor_reminders": ["same day"],
    "resident_reminders": ["same day"],
    "staff_reminders": ["same day"],
    "contractor_reminders": ["same day"],
    "industry professional_reminders": ["same day"],
    "other_reminders": ["same day"],

    "visitor_link_text": "",
    "resident_link_text": "",
    "staff_link_text": "",
    "contractor_link_text": "",
    "industry professional_link_text": "",
    "other_link_text": "",

    "visitor_link_url": "",
    "resident_link_url": "",
    "staff_link_url": "",
    "contractor_link_url": "",
    "industry professional_link_url": "",
    "other_link_url": "",

    tabIndex: 0,

    loaded: false,
    unauthorized: "",
    error: "",
    errors: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
    this.validator.hideMessages();
  }

  handleMultiCheckbox(event) {
    let name = event.target.name;
    let setName = event.target.dataset.name;
    let value = event.target.checked;

    var set = this.state[setName]

    if (value === true) {
      if (name === "no reminder") {
        set = []
      }
      else if (!set.includes(name)) {
        set.push(name)
      }
    }
    else if (value === false) {
      if (set.includes(name)) {
        let index = set.indexOf(name)
        set.splice(index, 1)
      }
    }

    this.setState({[setName]: set});
  }

  handleTabChange(index) {
    this.setState({ tabIndex: index });
  }

  handleSubmit(event) {
    if (this.validator.allValid()) {
      this.setState({
        success: false,
        updating: true
      })
      var headers = new Headers();
      headers.append("Content-Type", "application/json");

      var json = JSON.stringify({
        "organisation": {
         "planned_event_settings": {
          "visitor": this.constructParams("visitor"),
          "resident": this.constructParams("resident"),
          "staff": this.constructParams("staff"),
          "contractor": this.constructParams("contractor"),
          "industry professional": this.constructParams("industry professional"),
          "other": this.constructParams("other"),
         }
        }
      })

      var requestOptions = {
        method: 'PUT',
        headers: headers,
        body: json,
        credentials: 'include',
        redirect: 'follow'
      };

      var errorsInResponse = false

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 422) {
          errorsInResponse = true
          return response.json()
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({ errors: data })
        } else {
          this.setState({
            "visitor_reminders": data.planned_event_settings["visitor"].reminders,
            "resident_reminders": data.planned_event_settings["resident"].reminders,
            "staff_reminders": data.planned_event_settings["staff"].reminders,
            "contractor_reminders": data.planned_event_settings["contractor"].reminders,
            "industry professional_reminders": data.planned_event_settings["industry professional"].reminders,
            "other_reminders": data.planned_event_settings["other"].reminders,

            "visitor_link_text": data.planned_event_settings["visitor"].link_text,
            "resident_link_text": data.planned_event_settings["resident"].link_text,
            "staff_link_text": data.planned_event_settings["staff"].link_text,
            "contractor_link_text": data.planned_event_settings["contractor"].link_text,
            "industry professional_link_text": data.planned_event_settings["industry professional"].link_text,
            "other_link_text": data.planned_event_settings["other"].link_text,

            "visitor_link_url": data.planned_event_settings["visitor"].link_url,
            "resident_link_url": data.planned_event_settings["resident"].link_url,
            "staff_link_url": data.planned_event_settings["staff"].link_url,
            "contractor_link_url": data.planned_event_settings["contractor"].link_url,
            "industry professional_link_url": data.planned_event_settings["industry professional"].link_url,
            "other_link_url": data.planned_event_settings["other"].link_url,

            errors: "",
            updating: false
          })
          sessionStorage.setItem("updateSuccess", "Planned Event Settings updated successfully!");
          this.props.updatePopupCallback(Math.random())
        }
      })
      .catch(error => this.setState({ error, loaded: true }))
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }

    event.preventDefault();
  }

  constructParams(type_of_person) {
    return {
      reminders: this.state[`${type_of_person}_reminders`].length === 0 ? ["no reminder"] : this.state[`${type_of_person}_reminders`],
      link_text: this.state[`${type_of_person}_link_text`],
      link_url: this.state[`${type_of_person}_link_url`]
    }
  }

  renderSaveButton() {
    if (adminUser()) {

      return (
        <input type="submit" disabled={this.state.updating} value="Save" />
      )
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderTabErrorIndicator(type_of_person) {
    if (this.validator.messagesShown === true && this.validator.errorMessages[`${type_of_person}_link_url`] !== null) {
      return (
        <span className="notification-indicator red" />
      )
    }
  }

  render() {

    const { tabIndex, loaded, unauthorized, errors, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const type_of_people = ["visitor", "resident", "staff", "contractor", "industry professional", "other"]

      return (
        <div>
          <form className="settings big-settings segment-settings top-padding" onSubmit={this.handleSubmit}>
            <div className="segment">
              <label className="column"><strong>Email reminders:</strong></label>
              <HelpText page={'planned_event_settings'} section={'reminders'} />
              <div className="reminder-table">
                <div className="reminder-option header">
                </div>
                <div className="reminder-option header">
                  <label>One day before</label>
                </div>

                <div className="reminder-option header">
                  <label>Day of</label>
                </div>

                <div className="reminder-option header">
                  <label>No reminder</label>
                </div>
                {type_of_people.map((type_of_person) => (
                  <React.Fragment key={type_of_person}>
                    <div className="reminder-option text">
                      {capitalize(type_of_person)}
                    </div>

                    <div className="reminder-option">
                      <input className="column" type="checkbox" data-name={`${type_of_person}_reminders`} name="one day" id={`${type_of_person} one day`} checked={this.state[`${type_of_person}_reminders`].includes("one day")} onChange={this.handleMultiCheckbox} />
                    </div>

                    <div className="reminder-option">
                      <input className="column" type="checkbox" data-name={`${type_of_person}_reminders`} name="same day" id={`${type_of_person} same day`} checked={this.state[`${type_of_person}_reminders`].includes("same day")} onChange={this.handleMultiCheckbox} />
                    </div>

                    <div className="reminder-option">
                      <input className="column" type="checkbox" data-name={`${type_of_person}_reminders`} name="no reminder" id={`${type_of_person} no reminder`} checked={this.state[`${type_of_person}_reminders`].length === 0} onChange={this.handleMultiCheckbox} />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="segment">
              <HelpText page={'planned_event_settings'} section={'link'} />
              <label className="small-bottom-padding"><strong>Reminder email footer links:</strong></label>
              <Tabs selectedIndex={tabIndex} onSelect={index => this.handleTabChange(index)}>
                <TabList>
                  {type_of_people.map((type_of_person) => (
                    <Tab key={type_of_person}>{capitalize(type_of_person)} {this.renderTabErrorIndicator(type_of_person)}</Tab>
                  ))}
                </TabList>

                {type_of_people.map((type_of_person) => (
                  <TabPanel key={type_of_person}>
                    { renderErrors(errors, type_of_person) }
                    <div className="row">
                      <HelpText page={'planned_event_settings'} section={'link_text'} />

                      <label className="column">Email Link Text:</label>
                      <input className="column" type="input" name={`${type_of_person}_link_text`} value={this.state[`${type_of_person}_link_text`]} onChange={this.handleChange} />
                    </div>

                    <div className="row">
                      <HelpText page={'planned_event_settings'} section={'link_url'} />

                      <label className="column">Email Link URL:</label>
                      {this.validator.message(`${type_of_person}_link_url`, this.state[`${type_of_person}_link_url`], "url", { className: 'error' })}
                      <input className="column" type="input" name={`${type_of_person}_link_url`} value={this.state[`${type_of_person}_link_url`]} onChange={this.handleChange} />
                    </div>

                    <div className="row">
                      <HelpText page={'planned_event_settings'} section={'link_preview'} />

                      <label className="column">Email Link Preview:</label>
                      <a className="link-preview" target="_blank" rel="noopener noreferrer" href={this.state[`${type_of_person}_link_url`]}>{this.state[`${type_of_person}_link_text`]}</a>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>

            <div className="segment">
              {type_of_people.map((type_of_person) => (
                <React.Fragment key={type_of_person}>
                  {this.validator.message(`${type_of_person}_link_url`, this.state[`${type_of_person}_link_url`], "url", { className: 'error' })}
                </React.Fragment>
              ))}
              { renderErrors(errors, "planned_event_settings")}
              { this.renderSaveButton() }
              { renderErrorWarning(errors) }
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          "visitor_reminders": data.planned_event_settings["visitor"].reminders || ["same day"],
          "resident_reminders": data.planned_event_settings["resident"].reminders || ["same day"],
          "staff_reminders": data.planned_event_settings["staff"].reminders || ["same day"],
          "contractor_reminders": data.planned_event_settings["contractor"].reminders || ["same day"],
          "industry professional_reminders": data.planned_event_settings["industry professional"].reminders || ["same day"],
          "other_reminders": data.planned_event_settings["other"].reminders || ["same day"],

          "visitor_link_text": data.planned_event_settings["visitor"].link_text || "",
          "resident_link_text": data.planned_event_settings["resident"].link_text || "",
          "staff_link_text": data.planned_event_settings["staff"].link_text || "",
          "contractor_link_text": data.planned_event_settings["contractor"].link_text || "",
          "industry professional_link_text": data.planned_event_settings["industry professional"].link_text || "",
          "other_link_text": data.planned_event_settings["other"].link_text || "",

          "visitor_link_url": data.planned_event_settings["visitor"].link_url || "",
          "resident_link_url": data.planned_event_settings["resident"].link_url || "",
          "staff_link_url": data.planned_event_settings["staff"].link_url || "",
          "contractor_link_url": data.planned_event_settings["contractor"].link_url || "",
          "industry professional_link_url": data.planned_event_settings["industry professional"].link_url || "",
          "other_link_url": data.planned_event_settings["other"].link_url || "",

          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}


export default UpdatePlannedEventSettings;
