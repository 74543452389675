import React, { Component } from 'react';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

class UpgradeIndex extends Component {
  render() {
    return (
      <div>
        <SetTitle title={"Upgrade License"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Interested in Upgrading your license type?</h2>
          <OrganisationName />

          <p>
            You are currently on the Entry license. <br/>
            You can upgrade to gain access to the following features:
          </p>

          <ul>
            <li>Custom Attributes</li>
            <li>Outstanding Actions</li>
            <li>Profiles for Staff, Contractors, Residents, etc</li>
            <li>Email Alerts for critical Visitors</li>
            <li>Photos on Arrival</li>
            <li>Custom Feedback</li>
            <li>Fire Log</li>
            <li>Roles</li>
            <li>Tags</li>
            <li>Imports</li>
            <li>Customer API</li>
            <li>Planned Events</li>
            <li>Staff Fulfilment (Additional cost)</li>
          </ul>

          <p>
            Upgrading will give you access to the full suite of Digital Reception tools, allowing you to replace your existing paper systems with a one-stop shop.<br/>
            We operate an "it's your data" approach, so provide integrations for importing or exporting your data to any provider.
          </p>

          <p><strong>To upgrade:</strong> Contact <a href={`mailto:sales@dreception.com?subject=Upgrade+${localStorage.getItem("organisationName")}+to+Base+license`}>sales@dreception.com</a>.</p>

        </div>

      </div>
    );
  }
}

export default UpgradeIndex;
