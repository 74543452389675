import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import { getContrastYIQ } from '../../utilities/Generic.js'
import CreateDefaultUnassignedShift from '../../components/staff-fulfilment/CreateDefaultUnassignedShift';
import UpdateDefaultUnassignedShift from '../../components/staff-fulfilment/UpdateDefaultUnassignedShift';
import Flow from '../../components/staff-fulfilment/Flow';
import StaffFulfilmentConfigurationIndex from '../../components/staff-fulfilment/StaffFulfilmentConfigurationIndex';
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'

import { hideElementForNonAdminsAndNonManagers, adminUser, managerUser } from '../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class DefaultWorkingPattern extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeNewPanel = this.closeNewPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.handleGraphClick = this.handleGraphClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkForModalChanges = this.checkForModalChanges.bind(this);
  }

  state = {
    workStartsAt: 0,
    durationInHours: 24,

    editId: null,
    clickedRole: null,
    clickedDayOfWeek: null,
    clickedHour: null,
    clickedType: null,
    clickedStartMinute: null,
    clickedEndMinute: null,
    modalShiftIds: null,
    modalShiftsNeeded: null,
    showModal: false,
    newPanelToggle: false,
    editPanelToggle: false,

    rotaLoaded: false,
    rolesLoaded: false,
    shiftsLoaded: false,
    fulfilmentLoaded: false,

    roles: null,
    shifts: null,
    fulfilment: null
  }

  handleNewPanel(event) {
    this.closeModal()

    if (adminUser === false && managerUser === false) {
      return
    }

    const clickedRole = event.currentTarget.dataset.role_id;
    const clickedDayOfWeek = event.currentTarget.dataset.day_of_week
    const clickedEndHour = event.currentTarget.dataset.end_hour
    let clickedHour = event.currentTarget.dataset.start_hour

    this.setState({
      newPanelToggle: true,
      clickedRole: clickedRole,
      clickedDayOfWeek: clickedDayOfWeek,
      clickedHour: clickedHour,
      clickedEndHour: clickedEndHour || null
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template/new`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template/new`)
    }

    event.stopPropagation();
  }

  handleEditPanel(event) {
    var id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template/${id}`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template/${id}`)
    }

    event.stopPropagation();
  }

  closeNewPanel() {
    this.setState({
      newPanelToggle: false,
      clickedHour: null,
      clickedEndHour: null,
      flowDate: new Date().valueOf()
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template`);
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template`);
    }

    this.componentDidMount();
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null,
      flowDate: new Date().valueOf()
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template`);
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template`);
    }

    this.componentDidMount();
  }

  checkForModalChanges(data) {
    if (this.state.showModal === false || data[this.state.clickedDayOfWeek] === undefined) {
      return
    }

    // Try to find the new range after update
    let range = data[this.state.clickedDayOfWeek][this.state.clickedRole].ranges.find(range => range.range === `${this.state.clickedStartMinute}..${this.state.clickedEndMinute}`);

    if (range === undefined) {
      // range no longer exists after update, close modal
      return this.closeModal()
    }

    let modalShiftsNeeded = null;
    let modalShiftIds = null;

    if (this.state.clickedType === "underassigned") {
      modalShiftsNeeded = this.populateModal(this.state.clickedType, range)
    }
    else {
      modalShiftIds = this.populateModal(this.state.clickedType, range)
    }

    if (modalShiftsNeeded === 0 || modalShiftIds.length === 0) {
      // no unment requirements/shifts left, close modal
      return this.closeModal()
    }

    this.setState({
      modalShiftsNeeded: modalShiftsNeeded,
      modalShiftIds: modalShiftIds
    })
  }

  handleGraphClick(event) {
    const clickedRole = event.currentTarget.dataset.role_id;
    const clickedDayOfWeek = event.currentTarget.dataset.day_of_week;
    const [type, index, range] = event.currentTarget.id.split("-")
    const [clickedStartMinute, clickedEndMinute] = range.split("..")

    let modalShiftIds = null
    let modalShiftsNeeded = null
    let showModal = true
    let editId = null
    let editPanelToggle = false

    let data = this.state.fulfilment.rota[clickedDayOfWeek][clickedRole].ranges[index]

    if (type === "underassigned") {
      modalShiftsNeeded = this.populateModal(type, data)
    }
    else {
      modalShiftIds = this.populateModal(type, data);
    }

    let skiptoEditPanel = modalShiftIds?.length === 1
    if (skiptoEditPanel) {
      showModal = false
      editId = modalShiftIds[0]
      editPanelToggle = true

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template/${editId}`)
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template/${editId}`)
      }
    }

    this.setState({
      clickedRole: clickedRole,
      clickedDayOfWeek: clickedDayOfWeek,
      clickedType: type,
      clickedStartMinute: parseInt(clickedStartMinute),
      clickedEndMinute: parseInt(clickedEndMinute),
      modalShiftIds: modalShiftIds,
      modalShiftsNeeded: modalShiftsNeeded,
      showModal: showModal,
      editId: editId,
      editPanelToggle: editPanelToggle
    })

    event.preventDefault();
    event.stopPropagation();
  }

  populateModal(type, data) {
    // eslint-disable-next-line
    switch (type) {
      // collect the underassigned number to prompt users to create that many new shifts
      case "underassigned":
        return data.allocations.height - (data.assigned_shifts?.height || 0) - (data.unassigned_shifts?.height || 0)

      // collect constituant shift assignment IDs
      case "overassigned":
        let ids = []
        ids = ids.concat(data.unassigned_shifts?.ids || [])
        ids = ids.concat(data.assigned_shifts?.ids || [])
        return ids
      case "unassigned":
        return data.unassigned_shifts?.ids || []
      case "assigned":
        return data.assigned_shifts?.ids || []
    }
  }

  closeModal(event) {
    this.setState({
      clickedRole: null,
      clickedDayOfWeek: null,
      clickedType: null,
      clickedStartMinute: null,
      clickedEndMinute: null,
      modalShiftIds: null,
      modalShiftsNeeded: null,
      showModal: false
    });
  }

  doNotCloseModal(event) {
    event.stopPropagation();
  }

  renderModal() {
    if (this.state.showModal) {
      if (this.state.clickedType === "underassigned") {
        return (
          <div className="modal-container" onClick={this.closeModal}>
            <div className="modal-content" onClick={this.doNotCloseModal}>
              <div className="column padding-bottom">The period from {this.minuteToTime(this.state.clickedStartMinute)} and {this.minuteToTime(this.state.clickedEndMinute)} needs <span className="red-text">{this.state.modalShiftsNeeded}</span> more shift{this.state.modalShiftsNeeded === 1 ? "" : "s"}.</div>

              <div className="center">
                <div className={`column padding-bottom ${hideElementForNonAdminsAndNonManagers}`}>Would you like to create {this.state.modalShiftsNeeded === 1 ? "it" : "them"} now?</div>
                <button className={`button margin-right-20 ${hideElementForNonAdminsAndNonManagers}`} onClick={this.handleNewPanel} data-day_of_week={this.state.clickedDayOfWeek} data-role_id={this.state.clickedRole} data-start_hour={this.minuteToTime(this.state.clickedStartMinute)} data-end_hour={this.minuteToTime(this.state.clickedEndMinute)}>Yes</button>
                <button className="button margin-right" onClick={this.closeModal}>No</button>
              </div>
            </div>
          </div>
        )
      }

      let shifts = this.state.shifts[this.state.clickedRole].filter(shift => this.state.modalShiftIds.includes(shift.id))

      return (
        <div className="modal-container" onClick={this.closeModal}>
          <div className="modal-content" onClick={this.doNotCloseModal}>
            <div className="padding-bottom">Here are the Shift Assignments that make up this part of the graph:</div>

            <div className="table big-table">
              <div className="tr heading">
                <div className="th">Person</div>
                <div className="th">Start Time</div>
                <div className="th">End Time</div>
                <div className="th">Breaks</div>
              </div>
              {shifts.map((shift) => (
                  <Link to={this.shiftURL(shift)} onClick={this.handleEditPanel} id={shift.id} key={shift.id} className="tr">
                    <div className="td">{shift.person_name}</div>
                    <div className="td">{shift.start_time}</div>
                    <div className="td">{shift.end_time}</div>
                    <div className="td" title={this.renderBreakTimes(shift.break_times)}>{shift.break_times.length}{shift.break_times.length > 0 ? "*" : ""}</div>
                  </Link>
              ))}
            </div>
            <button className="modal-button float-right" onClick={this.closeModal}>Close</button>
          </div>
        </div>
      )
    }
  }

  shiftURL(shift) {
    if (this.props.match.params.rota_id !== undefined) {
      return `/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/working-pattern-unassigned/template/${shift.id}`
    }
    else {
      return `/staff-fulfilment/${this.props.match.params.location_id}/working-pattern-unassigned/template/${shift.id}`
    }
  }

  renderBreakTimes(break_times) {
    if (break_times.length === 0) {
      return ""
    }
    let text = "This shift has the following break times:"

    for (let i in break_times) {
      text += `\n${break_times[i].start_time} for ${break_times[i].duration} minutes`
    }
    return text
  }

  renderRotaName() {
    if (this.state.rota === undefined) {
      return
    }
    else {
      return (
        <>
         - {this.state.rota.name} Rota
        </>
      )
    }
  }

  render() {
    const { match: { params } } = this.props;
    const { workStartsAt, durationInHours, clickedDayOfWeek, clickedRole, clickedHour, clickedEndHour, editId, rolesLoaded, shiftsLoaded, fulfilmentLoaded, rotaLoaded, flowDate, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false || shiftsLoaded === false || fulfilmentLoaded === false || rotaLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded && shiftsLoaded && fulfilmentLoaded && rotaLoaded) {
      return (
        <div className="fulfilment">
          <SetTitle title={"Staff Fulfilment"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Staff Fulfilment {this.renderRotaName()}</h2>
            <OrganisationName />

            <Flow location_id={params.location_id} rota_id={params.rota_id} page={"Unassigned Shifts Template"} date={flowDate} />

            <div>
              {this.renderDailyRotas()}
              {this.renderModal()}
            </div>
          </div>

          <ModalContainerTrigger triggerText="Template configuration" className="small button right config">
            <StaffFulfilmentConfigurationIndex location_id={params.location_id} />
          </ModalContainerTrigger>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Unassigned Template Shift" width="60%"
            onRequestClose={
              this.closeNewPanel
            }>
            <CreateDefaultUnassignedShift origin="working-pattern-unassigned" closeNewPanel={this.closeNewPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} clickedHour={clickedHour} clickedEndHour={clickedEndHour} day_of_week={clickedDayOfWeek} role_id={clickedRole} location_id={params.location_id} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Unassigned Template Shift" width="60%"
            onRequestClose={
              this.closeEditPanel
            }>
            <UpdateDefaultUnassignedShift id={editId} closeEditPanel={this.closeEditPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} location_id={params.location_id} />
          </SlidingPane>

        </div>
      );
    }
  }

  renderDailyRotas() {
    let dates = []
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const maxDefaultPeriodWeeks = Math.max(...Object.values(this.state.fulfilment.role_data).map(role => role.default_period_weeks || 1));

    for (let i = 0; i < (7 * maxDefaultPeriodWeeks); i++) {
      const date = new Date(tomorrow);
      date.setDate(tomorrow.getDate() + i);

      dates.push(date)
    }

    return (
      <div className="rotas">
        {dates.map((date) => (
          this.renderIndividualDay(date)
        ))}
      </div>
    )
  }

  renderIndividualDay(date) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = daysOfWeek[date.getDay()]

    const { workStartsAt, durationInHours } = this.state;
    const workingHours = Array.from({length: durationInHours}, (_, i) => workStartsAt + i);

    const roles = this.state.roles;

    return (
      <div key={date} className="container">
        <div className="graph">

          {/* Header */}
          <div className="roles">
            <div className="left">
              <div className="role-label">Roles</div>
              <div className="time-label">Time</div>
            </div>
            <div className="right">
              <div className="title">{day}</div>
              <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
                {workingHours.map((hour, index) => (
                  <div key={hour} className="hour-title">{hour}</div>
                ))}
              </div>
            </div>
          </div>

          {roles.map((role) => (
            this.renderIndividualRole(role, workingHours, date)
          ))}
        </div>
      </div>
    )
  }

  renderIndividualRole(role, workingHours, date) {
    const today = new Date(date)
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)

    const start_of_current_period = new Date(this.state.fulfilment.role_data[role.id].start_of_current_period)

    const default_period = this.state.fulfilment.role_data[role.id].default_period_weeks * 7

    // Convert millisecond difference into days (86400000 === 1000 * 60 * 60 * 24))
    const currentDay = (Math.round((today - start_of_current_period) / 86400000)) % default_period

    const padding = 0

    if (this.state.fulfilment.rota === undefined || this.state.fulfilment.rota[currentDay] === undefined || this.state.fulfilment.rota[currentDay][role.id] === undefined) {
      return (
        <div className="roles" key={`role${role.id}`}>
          <div className="left">
            <div className="row-labels" style={{gridTemplateRows: `repeat(${1}, 1fr)`}}>
              {Array.from({ length: 1 + padding}).map((_, index) => (
                <div key={index}>{1 - index + padding}</div>
              ))}
            </div>
            <div className="role-title">
              <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
              <div className="hour-count">Week {Math.floor(currentDay / 7) + 1} of {this.state.fulfilment.role_data[role.id].default_period_weeks}</div>
              <div className="hour-count">0 hours required</div>
              <div className="hour-count">0 hours assigned</div>
            </div>
          </div>
          <div className="right">
            <div className="assigned-hours">
            </div>
            <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
              {workingHours.map(hour => (
                <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={currentDay}><wbr/></div>
              ))}
            </div>
          </div>
          <div className="new-shift">
            <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={currentDay} data-role_id={role.id}>+</div>
          </div>
        </div>
      )
    }

    const data = this.state.fulfilment.rota[currentDay][role.id]
    const height = this.gridRowHeight(data)
    const hoursRequired = this.calculateTotalHoursRequired(data)
    const hoursAssigned = this.calculateTotalHoursAssigned(data)

    return (
      <div className="roles" key={`role${role.id}`}>
        <div className="left">
          <div className="row-labels" style={{gridTemplateRows: `repeat(${height}, 1fr)`}}>
            {Array.from({ length: height + padding}).map((_, index) => (
              <div key={index}>{height - index + padding}</div>
            ))}
          </div>
          <div className="role-title">
            <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
            <div className="hour-count">Week {Math.floor(currentDay / 7) + 1} of {this.state.fulfilment.role_data[role.id].default_period_weeks}</div>
            <div className="hour-count">{hoursRequired} hours required</div>
            <div className="hour-count">{hoursAssigned} hours assigned</div>
          </div>
        </div>
        <div className="right">
          <div className="assigned-hours">
            {this.renderRotaHours(data, role.id, currentDay)}
          </div>
          <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
            {workingHours.map(hour => (
              <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={currentDay}><wbr/></div>
            ))}
          </div>
        </div>
        <div className="new-shift">
          <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={currentDay} data-role_id={role.id}>+</div>
        </div>
      </div>
    )
  }

  gridRowHeight(data) {
    const defaultHeight = 1;

    return data?.max_height || defaultHeight
  }

  calculateTotalHoursRequired(data) {
    if (data.ranges === undefined) {
      return 0
    }

    let totalHours = 0

    data.ranges.forEach((range, index) => {
      let [startMinute, endMinute] = range.range.split("..")
      let allocationHeight = range.allocations?.height || 0
      const numberOfHours = (endMinute - startMinute) / 60

      totalHours += numberOfHours * allocationHeight
    })

    return Math.ceil(totalHours)
  }

  calculateTotalHoursAssigned(data) {
    if (data.ranges === undefined) {
      return 0
    }

    let totalHours = 0

    data.ranges.forEach((range, index) => {
      let [startMinute, endMinute] = range.range.split("..")
      let shiftsHeight = range.assigned_shifts?.height || 0
      const numberOfHours = (endMinute - startMinute) / 60

      totalHours += numberOfHours * shiftsHeight
    })

    return Math.ceil(totalHours)
  }

  renderRotaHours(data, role_id, day_of_week) {
    const highestPoint = data.max_height

    return (
      data.ranges?.map((range, index) => {
        let [startMinute, endMinute] = range.range.split("..")

        let allocationHeight = range.allocations?.height || 0
        let assignedShifts = range.assigned_shifts?.height || 0
        let unassignedShifts = range.unassigned_shifts?.height || 0

        let assignedShiftHeight = Math.min(allocationHeight, assignedShifts)
        let shifts = assignedShifts + unassignedShifts
        let unassignedShiftHeight = Math.min(shifts, allocationHeight)

        let overassignedHeight = shifts > allocationHeight ? shifts : 0

        let startTime = this.minuteToTime(startMinute)
        let endTime = this.minuteToTime(endMinute)

        return (
          <React.Fragment key={index}>
            <div id={`underassigned-${index}-${range.range}`} className="red" title={this.generateHoverText("underassigned", allocationHeight, startTime, endTime)} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, allocationHeight, highestPoint)})`}} onClick={this.handleGraphClick} data-role_id={role_id} data-day_of_week={day_of_week}><wbr/></div>
            <div id={`overassigned-${index}-${range.range}`} className="orange" title={this.generateHoverText("overassigned", overassignedHeight - allocationHeight, startTime, endTime)} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, overassignedHeight, highestPoint)})`}} onClick={this.handleGraphClick} data-role_id={role_id} data-day_of_week={day_of_week}><wbr/></div>
            <div id={`unassigned-${index}-${range.range}`} className="yellow" title={this.generateHoverText("unassigned", unassignedShiftHeight - assignedShiftHeight, startTime, endTime)} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, unassignedShiftHeight, highestPoint)})`}} onClick={this.handleGraphClick} data-role_id={role_id} data-day_of_week={day_of_week}><wbr/></div>
            <div id={`assigned-${index}-${range.range}`} className="green" title={this.generateHoverText("assigned", assignedShiftHeight, startTime, endTime)} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, assignedShiftHeight, highestPoint)})`}} onClick={this.handleGraphClick} data-role_id={role_id} data-day_of_week={day_of_week}><wbr/></div>
          </React.Fragment>
        )
      })
    )
  }

  minuteToTime(minute) {
    return `${("0" + Math.floor(minute/60)).slice(-2)}:${("0" + minute % 60).slice(-2)}`
  }

  generateHoverText(type, height, startTime = 0, endTime = 0) {
    let tooltip = `This part of the graph shows you have ${height} ${type} shift${height === 1 ? "" : "s"}, between ${startTime} and ${endTime}.`
    return tooltip
  }

  calculateCoordinates(startMinute, endMinute, height, maxHeight) {
    if (!(height > 0)) {
      return "0% 0%"
    }

    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const startPercentage = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const endPercentage = ((endMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;

    const blockHeight = 100 - (height / maxHeight) * 100
    const bottomOfGraph = 100

    let points = []

    points.push(`${startPercentage}% ${bottomOfGraph}%`)
    points.push(`${startPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${bottomOfGraph}%`)

    // Important note for debugging:
    //
    // The CSS attribute clip-path that we use to drive this feature has a wonky axis.
    //
    // If the calculations seem surprising, this is why:
    //
    //      0%                   100%
    //   0% ┌───────────────────────┐ 0%
    //      │                       │
    //      │                       │
    //    Y │                       │
    //    - │                       │
    //    a │                       │
    //    x │                       │
    //    i │                       │
    //    s │                       │
    //      │                       │
    //      │                       │
    // 100% └───────────────────────┘ 100%
    //      0%        X-axis      100%
    //

    return points
  }

  static getDerivedStateFromProps(props, state) {
    const id = props.match.params.id
    if (state.newPanelToggle && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else if (state.editPanelToggle && id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && id !== state.editId) {
      return {
        editId: id
      }
    }
    else if (state.editPanelToggle === false && id !== undefined) {
      return {
        editPanelToggle: true,
        editId: id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/working-pattern-unassigned/template")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let queryString = ""
    const location_id = this.props.match.params.location_id
    const rota_id = this.props.match.params.rota_id

    if (rota_id !== undefined) {
      queryString = `?rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    queryString = `location_id=${location_id}`

    if (rota_id !== undefined) {
      queryString += `&rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_shift_assignments?${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ shifts: data, shiftsLoaded: true })
      })
      .catch(error => this.setState({ error, shiftsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/default?${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ fulfilment: data, fulfilmentLoaded: true })
        this.checkForModalChanges(data);
      })
      .catch(error => this.setState({ error, fulfilmentLoaded: true }))

    if (rota_id !== undefined) {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types/${rota_id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ rota: data, rotaLoaded: true })
        })
        .catch(error => this.setState({ error, rotaLoaded: true }))
    }
    else {
      this.setState({ rotaLoaded: true })
    }
  }
}

export default DefaultWorkingPattern;
