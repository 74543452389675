import React, { Component } from 'react';
import ListOfDevices from '../../components/devices/ListOfDevices';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class DevicesIndex extends Component {
  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Devices"} />
        <PopupBox />

        <h2 className="page-title">Devices</h2>
        <OrganisationName />

        <div>
          <ListOfDevices id={params.id} />
        </div>
      </div>
    );
  }
}

export default DevicesIndex;
