import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Countdown from '../../components/shared/Countdown';
import { debounce } from '../../utilities/Generic.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MobileEnrolment extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);
    this.generateCode = this.generateCode.bind(this);

    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);

    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handleStaffSearch.bind(this), 500);
  }

  state = {
    staff: [],

    tabIndex: 0,
    page: 1,

    loaded: false,
    unauthorized: "",
    error: "",
    errors: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
    this.validator.hideMessages();
  }

  handleTabChange(index) {
    this.setState({ tabIndex: index });

    let enrolled
    if (index === 0) {
      enrolled = true
    }
    else if (index === 1) {
      enrolled = false
    }

    this.fetchData(enrolled, 1)
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandlePersonSearch(value)
  }

  handleStaffSearch(partial) {
    let enrolled
    if (this.state.tabIndex === 0) {
      enrolled = true
    }
    else if (this.state.tabIndex === 1) {
      enrolled = false
    }

    this.fetchData(enrolled, 1, partial)
  }

  handleNextPage() {
    const page = parseInt(this.state.page) + 1

    this.setState({
      page: page,
      loaded: false
    })

    let enrolled
    if (this.state.tabIndex === 0) {
      enrolled = true
    }
    else if (this.state.tabIndex === 1) {
      enrolled = false
    }

    this.fetchData(enrolled, page, this.state.partial)
  }

  handlePreviousPage() {
    const page = parseInt(this.state.page) - 1

    this.setState({
      page: page,
      loaded: false
    })

    let enrolled
    if (this.state.tabIndex === 0) {
      enrolled = true
    }
    else if (this.state.tabIndex === 1) {
      enrolled = false
    }

    this.fetchData(enrolled, page, this.state.partial)
  }

  fetchData(enrolled, page, partial = "") {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `page=${page}&staff[enrolled]=${enrolled}`

    if (partial !== "") {
      params += `&staff[partial]=${partial}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/manual_enrolment/staff?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          staff: data,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  generateCode(event) {
    const staffId = event.target.parentNode.parentNode.id

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/manual_enrolment/generate_code?id=${staffId}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({error: JSON.stringify(response.body)})
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        sessionStorage.setItem("updateSuccess", "Code successfully generated");
        this.props.updatePopupCallback(Math.random())

        let staffArray = this.state.staff
        let staffMember = staffArray.find(staff => staff.id === data.person_id);
        if (staffMember) {
          staffMember.code = data.code
          staffMember.countdown = Date.now()
        }

        this.setState({
          staff: staffArray,
          errors: "",
          updating: false
        })
      }
    })
    .catch(error => {
      this.setState({ error, loaded: true })
    })

    event.preventDefault();
  }

  revokeAccess(event) {
    const staffId = event.target.parentNode.parentNode.id

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/manual_enrolment/revoke?id=${staffId}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({error: JSON.stringify(response.body)})
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          errors: "",
          updating: false
        })
        sessionStorage.setItem("updateSuccess", "Access successfully removed");
        this.props.updatePopupCallback(Math.random())

        let enrolled
        if (this.state.tabIndex === 0) {
          enrolled = true
        }
        else if (this.state.tabIndex === 1) {
          enrolled = false
        }
        this.fetchData(enrolled, this.state.page)
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderGenerateCode(staff) {
    if (staff.code === undefined) {
      return <div className="small button" onClick={this.generateCode}>Generate Code</div>
    }
    else {
      return (
        <>
          <div>Code: <strong>{staff.code}</strong></div>
          <div>Countdown: <strong><Countdown duration={15} /></strong></div>
        </>
      )
    }
  }

  renderError() {
    if (this.state.error.length > 0) {
      return (
        <div className="errors">{this.state.error}</div>
      )
    }
  }

  renderPageButtons(page, records) {
    if (parseInt(page) === 1 && records.length < 250) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.handleNextPage} disabled={(records.length < 250)}>Next Page</button>
          <button className="button new-button" onClick={this.handlePreviousPage} disabled={(page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  render() {
    const { staff, tabIndex, partial, page, loaded, unauthorized, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <div>
            <div className="top-text">
              <p>This is the Mobile Enrolment page. Here you can see which of your staff are enrolled.</p>
              <p>You can manually enroll staff that are having issues, but ideally they will use the <strong>Organisation PIN</strong> and a <em>unique phone number</em> to enroll by themselves on the Mobile App.</p>
            </div>
            <div className="organisation-pin">Your organisation PIN is: <strong>{localStorage.enrolmentCode}</strong></div>
          </div>

          <div className="top-padding">
            <input name="partial" placeholder="Enter text to search" className="search-text small-bottom-margin" onChange={this.handlePartialChange} value={partial} />
          </div>

          <Tabs selectedIndex={tabIndex} onSelect={value => this.handleTabChange(value)}>
            <TabList>
              <Tab key={0}>Enrolled</Tab>
              <Tab key={1}>Not yet enrolled</Tab>
            </TabList>

            <TabPanel key={0}>
              <div className="table big-table">
                <div className="tr heading">
                  <div className="th">Name</div>
                  <div className="th">Enrolled?</div>
                  <div className="th">Revoke Access</div>
                  <div className="th">Edit</div>
                </div>
                {staff.map((staff) => (
                  <div key={staff.id} id={staff.id} className="tr">
                    <div className="td">{staff.full_name}</div>
                    <div className="td center">{staff.enrolled ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                    <div className="td center"><div className="small button" onClick={this.revokeAccess}>Revoke</div></div>
                    <div className="td center"><Link to={`/people/${staff.id}`}><div className="small button">Edit</div></Link></div>
                  </div>
                ))}
              </div>
              {staff.length === 0 && (
                <div className="long-tr">
                  <div className="long-td">
                    No enrolled staff found.
                  </div>
                </div>
              )}
            </TabPanel>

            <TabPanel key={1}>
              <div className="table big-table">
                <div className="tr heading">
                  <div className="th">Name</div>
                  <div className="th">Enrolled?</div>
                  <div className="th">Generate Code</div>
                  <div className="th">Warnings</div>
                  <div className="th">Edit</div>
                </div>
                {staff.map((staff) => (
                  <div key={staff.id} id={staff.id} className="tr">
                    <div className="td">{staff.full_name}</div>
                    <div className="td center">{staff.enrolled ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                    <div className="td center">{this.renderGenerateCode(staff)}</div>
                    <div className="td center">{staff.warnings?.map((warning) => (<p>{warning}</p>))}</div>
                    <div className="td center"><Link to={`/people/${staff.id}`}><div className="small button">Edit</div></Link></div>
                  </div>
                ))}
              </div>
              {staff.length === 0 && (
                <div className="long-tr">
                  <div className="long-td">
                    No un-enrolled staff found.
                  </div>
                </div>
              )}
            </TabPanel>
          </Tabs>

          {this.renderPageButtons(page, staff)}

          {this.renderError()}
        </div>
      )
    }
  }

  componentDidMount() {
    this.fetchData(true, 1)
  }
}

export default MobileEnrolment;
