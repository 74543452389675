import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import { convertTime, formatDate, getContrastYIQ } from '../../utilities/Generic.js'
import CreateWeeklyRequiredAllocation from '../../components/staff-fulfilment/CreateWeeklyRequiredAllocation';
import UpdateWeeklyRequiredAllocation from '../../components/staff-fulfilment/UpdateWeeklyRequiredAllocation';
import Flow from '../../components/staff-fulfilment/Flow';
import StaffFulfilmentConfigurationIndex from '../../components/staff-fulfilment/StaffFulfilmentConfigurationIndex';
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'

import { adminUser, managerUser } from '../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class StaffFulfilmentWeeklyRequiredAllocation extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeNewPanel = this.closeNewPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);

    this.handleJumpToDate = this.handleJumpToDate.bind(this);
    this.handleJumpToDateTooltip = this.handleJumpToDateTooltip.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePreviousWeek = this.handlePreviousWeek.bind(this);
    this.handleNextWeek = this.handleNextWeek.bind(this);
    this.handlePreviousDay = this.handlePreviousDay.bind(this);
    this.handleNextDay = this.handleNextDay.bind(this);

    this.grabData = this.grabData.bind(this);
  }

  state = {
    // location settings or org settings?
    workStartsAt: 0,
    durationInHours: 24,

    date: new Date(),
    previouslyGrabbedDateFrom: new Date(),
    jumpToDate: false,
    jumpToDateTooltip: false,

    editId: null,
    clickedRole: null,
    clickedStartDate: null,
    clickedHour: null,
    newPanelToggle: false,
    editPanelToggle: false,

    rotaLoaded: false,
    rolesLoaded: false,
    allocationsLoaded: false,

    roles: null,
    allocations: null
  }

  handleDateChange(event) {
    const value = event.target.value;
    if (value.length > 0) {
      const date = new Date(value)

      this.setState({
        date: date,
        jumpToDate: false,
        jumpToDateTooltip: false,
        key: Math.random()
      })

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly?date=${this.formatDate(date)}`);
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly?date=${this.formatDate(date)}`);
      }

      this.grabData(date)
    }
  }

  handlePreviousWeek(event) {
    this.setState(prevState => {
      const newDate = new Date(prevState.date)
      newDate.setDate(newDate.getDate() - 7)

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }

      return ({
        date: newDate
      })
    })
  }

  handleNextWeek(event) {
    this.setState(prevState => {
      const newDate = new Date(prevState.date)
      newDate.setDate(newDate.getDate() + 7)

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }

      return ({
        date: newDate
      })
    })
  }

  handlePreviousDay(event) {
    this.setState(prevState => {
      const newDate = new Date(prevState.date)
      newDate.setDate(newDate.getDate() - 1)

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }

      return ({
        date: newDate
      })
    })
  }

  handleNextDay(event) {
    this.setState(prevState => {
      const newDate = new Date(prevState.date)
      newDate.setDate(newDate.getDate() + 1)

      if (this.props.match.params.rota_id !== undefined) {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }
      else {
        this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly?date=${this.formatDate(newDate)}`);
      }

      return ({
        date: newDate
      })
    })
  }

  formatDate(date) {
    const day = ("0" + (date.getDate())).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  handleJumpToDate(event) {
    this.setState({jumpToDate: true})

    event.preventDefault();
  }

  handleJumpToDateTooltip(event) {
    if (event.target.value.length > 0) {
      this.setState({jumpToDateTooltip: true})
    }
    else {
      this.setState({jumpToDateTooltip: false})
    }
    event.preventDefault();
  }

  handleNewPanel(event) {
    if (adminUser === false && managerUser === false) {
      return
    }

    const clickedRole = event.currentTarget.dataset.role_id;
    const clickedStartDate = event.currentTarget.dataset.start_date
    let clickedHour = event.currentTarget.dataset.start_hour

    this.setState({
      newPanelToggle: true,
      clickedRole: clickedRole,
      clickedStartDate: clickedStartDate,
      clickedHour: clickedHour
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly/new`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly/new`)
    }
  }

  handleEditPanel(event) {
    var id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly/${id}`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly/${id}`)
    }

    event.stopPropagation();
  }

  closeNewPanel() {
    this.setState({
      newPanelToggle: false,
      clickedRole: null,
      clickedStartDate: null,
      clickedHour: null
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly`)
    }

    this.componentDidMount();
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/weekly`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/weekly`)
    }

    this.componentDidMount();
  }

  renderDateSelect() {
    if (this.state.location !== "") {
      const hidden = this.state.jumpToDate ? "" : "hidden "
      return (
        <>
          <div className="small button" onClick={this.handleJumpToDate}>Jump to Date:</div>
          <div className="jump-to-date-tooltip-container">
            <input className={hidden + "contextual-date"} type="date" name="date" onChange={this.handleJumpToDateTooltip} onBlur={this.handleDateChange} />
            {this.renderJumpToDateTooltip()}
          </div>
        </>
      )
    }
  }

  renderJumpToDateTooltip() {
    if (this.state.jumpToDateTooltip) {
      return (
        <div className="jump-to-date-tooltip">Click away from the Date selector to confirm your pick when ready.</div>
      )
    }
  }

  renderRotaName() {
    if (this.state.rota === undefined) {
      return
    }
    else {
      return (
        <>
         - {this.state.rota.name} Rota
        </>
      )
    }
  }

  render() {
    const { match: { params } } = this.props;
    const { workStartsAt, durationInHours, clickedStartDate, clickedRole, clickedHour, editId, rolesLoaded, rotaLoaded, allocationsLoaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded && allocationsLoaded && rotaLoaded) {
      return (
        <div className="fulfilment">
          <SetTitle title={"Staff Fulfilment"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Staff Fulfilment {this.renderRotaName()}</h2>
            <OrganisationName />

            <Flow location_id={params.location_id} rota_id={params.rota_id} page={"Exceptions"} />

            <div>
              {this.renderDateSelect()}
              <div className="small button" onClick={this.handlePreviousWeek}>Previous Week</div>
              <div className="small button" onClick={this.handleNextWeek}>Next Week</div>
              <div className="small button" onClick={this.handlePreviousDay}>Previous Day</div>
              <div className="small button" onClick={this.handleNextDay}>Next Day</div>

              {this.renderDailyRotas()}
            </div>
          </div>

          <ModalContainerTrigger triggerText="Template configuration" className="small button right config">
            <StaffFulfilmentConfigurationIndex location_id={params.location_id} />
          </ModalContainerTrigger>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Weekly Required Allocation" width="60%" onRequestClose={this.closeNewPanel}>
            <CreateWeeklyRequiredAllocation closeNewPanel={this.closeNewPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} clickedHour={clickedHour} start_date={clickedStartDate} role_id={clickedRole} location_id={params.location_id} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Weekly Required Allocation" width="60%" onRequestClose={this.closeEditPanel}>
            <UpdateWeeklyRequiredAllocation id={editId} closeEditPanel={this.closeEditPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} location_id={params.location_id} />
          </SlidingPane>

        </div>
      );
    }

    else {
      return <p>Loading ...</p>;
    }
  }

  renderDailyRotas() {
    let dates = []
    const firstDay = this.state.date;

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDay);
      date.setDate(firstDay.getDate() + i);

      dates.push(date)
    }

    return (
      <div className="rotas">
        {dates.map((date) => (
          this.renderIndividualDay(date)
        ))}
      </div>
    )
  }

  calculatePercentage() {
    const currentDate = new Date().toLocaleTimeString()

    const hour = parseInt(currentDate.split(":")[0])
    const minute = parseInt(currentDate.split(":")[1])

    const totalMinutes = 23 * 60 + 59;
    const currentMinutes = hour * 60 + minute;
    const percentage = 16.86 + ((currentMinutes / totalMinutes) * (97.75 - 16.86));

    return parseFloat(percentage.toFixed(2));
  }

  calculatePPF(date) {
    var currentDate = new Date();
    var inputDate = new Date(date);

    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate < currentDate) return "past"
    if (inputDate > currentDate) return "future"

    return "present"
  }

  renderIndividualDay(date) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = daysOfWeek[date.getDay()]

    const { workStartsAt, durationInHours } = this.state;
    const workingHours = Array.from({length: durationInHours}, (_, i) => workStartsAt + i);

    const roles = this.state.roles;

    const jsDayOfWeek = date.getDay()
    const day_of_week = jsDayOfWeek === 0 ? 6 : jsDayOfWeek - 1;

    let backgroundStyle = {}
    const backgroundClass = this.calculatePPF(date) // "past", "present", or "future"

    if (backgroundClass === "present") {
      const percentage = this.calculatePercentage();

      backgroundStyle = { background: `linear-gradient(to right, #cfcfcf ${percentage}%, #f00 ${percentage}%, #f00 ${percentage + 0.25}%, #eee 0%)` }
    }

    return (
      <div key={date} className={`container ${backgroundClass}`} style={backgroundStyle}>
        <div className="graph">

          {/* Header */}
          <div className="roles">
            <div className="left">
              <div className="role-label">Roles</div>
              <div className="time-label">Time</div>
            </div>
            <div className="right">
              <div className="title">{day} {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</div>
              <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
                {workingHours.map((hour, index) => (
                  <div key={hour} className="hour-title">{hour}</div>
                ))}
              </div>
            </div>
          </div>

          {roles.map(role => (
            this.renderIndividualRole(role, workingHours, date, day_of_week)
          ))}
        </div>
      </div>
    )
  }

  renderIndividualRole(role, workingHours, date, day_of_week) {
    let weekNumberMessage;

    if (this.state.rotaConfigurationsLoaded) {
      const today = new Date(date)
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)

      const start_of_current_period = new Date(this.state.rota_configurations[role.id].start_of_current_period)

      const default_period = this.state.rota_configurations[role.id].default_period_weeks * 7

      // Convert millisecond difference into days (86400000 === 1000 * 60 * 60 * 24))
      const currentDay = (Math.round((today - start_of_current_period) / 86400000)) % default_period

      weekNumberMessage = `Week ${Math.floor(currentDay / 7) + 1} of ${this.state.rota_configurations[role.id].default_period_weeks}`
    }

    const padding = 0

    if (this.state.allocations[date.getDate()] === undefined || this.state.allocations[date.getDate()][role.id] === undefined) {
      return (
        <div className="roles" key={`role${role.id}`}>
          <div className="left">
            <div className="row-labels" style={{gridTemplateRows: `repeat(${1}, 1fr)`}}>
              {Array.from({ length: 1 + padding}).map((_, index) => (
                <div key={index}>{1 - index + padding}</div>
              ))}
            </div>
            <div className="role-title">
              <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
              {this.state.rotaConfigurationsLoaded &&
                <div className="hour-count">{weekNumberMessage}</div>
              }
              <div className="hour-count">0 hours required</div>
            </div>
          </div>
          <div className="right">
            <div className="assigned-hours">
            </div>
            <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
              {workingHours.map(hour => (
                <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-start_date={formatDate(date)}><wbr/></div>
              ))}
            </div>
          </div>
          <div className="new-shift">
            <div className="plus-button" onClick={this.handleNewPanel} data-start_date={formatDate(date)} data-role_id={role.id}>+</div>
          </div>
        </div>
      )
    }

    const data = this.state.allocations[date.getDate()][role.id]
    const height = this.gridRowHeight(data)
    const hoursRequired = this.calculateTotalHoursRequired(data)

    return (
      <div className="roles" key={`role${role.id}`}>
        <div className="left">
          <div className="row-labels" style={{gridTemplateRows: `repeat(${height}, 1fr)`}}>
            {Array.from({ length: height }).map((_, subindex) => (
              <div key={subindex}>{height - subindex}</div>
            ))}
          </div>
          <div className="role-title">
            <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
            {this.state.rotaConfigurationsLoaded &&
              <div className="hour-count">{weekNumberMessage}</div>
            }
            <div className="hour-count">{hoursRequired} hours required</div>
          </div>
        </div>
        <div className="right">
          <div className="assigned-hours">
            {this.renderRotaHours(data, role.id, date.getDay())}
          </div>
          <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
            {workingHours.map(hour => (
              <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-start_date={formatDate(date)}><wbr/></div>
            ))}
          </div>
        </div>
        <div className="new-shift">
          <div className="plus-button" onClick={this.handleNewPanel} data-start_date={formatDate(date)} data-role_id={role.id}>+</div>
        </div>
      </div>
    )
  }

  gridRowHeight(data) {
    const defaultHeight = 1;

    return data?.max_height || defaultHeight
  }

  calculateTotalHoursRequired(data) {
    let countedIds = []
    let totalHours = 0

    if (data.ranges === undefined) {
      return 0
    }

    data.ranges.forEach(range => {
      let [startMinute, endMinute] = range.range.split("..").map(Number)

      if (endMinute > 1440) {
        endMinute = 1440
      }

      const numberOfHours = (endMinute - startMinute) / 60

      if (range.default_allocations) {
        range.default_allocations.forEach(default_allocation => {
          if (countedIds.includes(default_allocation.id)) {
            return
          }

          totalHours += numberOfHours * default_allocation.height
        })
      }

      if (range.exceptions) {
        range.exceptions.forEach(exception => {
          if (countedIds.includes(exception.id)) {
            return
          }

          totalHours += numberOfHours * exception.height
        })
      }
    })

    return Math.ceil(totalHours)
  }

  renderRotaHours(data, role_id, date) {
    const highestPoint = data.max_height

    return (
      data.ranges?.map((range, index) => {
        let [startMinute, endMinute] = range.range.split("..")

        if (endMinute > 1440) {
          endMinute = 1440
        }

        let cumulativeHeight = 0
        let default_allocations = range.default_allocations || []
        let exceptions = range.exceptions || []

        let default_allocations_elements = default_allocations.map((default_allocation, index) => {
          cumulativeHeight += default_allocation.height
          return (
            <div key={index} className="allocated-shift" id={default_allocation.id} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, cumulativeHeight, highestPoint)})`}} onClick={this.handleEditPanel}>
              <div className="number-of-people" style={this.calculateOffset(startMinute, cumulativeHeight, highestPoint)}>{default_allocation.height}</div>
              <div className="time" style={this.calculateOffset(startMinute, cumulativeHeight, highestPoint, true)}>{this.shiftTime(startMinute, endMinute)}</div>
            </div>
          )
        })


        let exceptions_elements = exceptions.map((exception, index) => {
          cumulativeHeight += exception.height
          return (
            <div key={index} className="allocated-shift exception" id={exception.id} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, cumulativeHeight, highestPoint)})`}} onClick={this.handleEditPanel}>
              <div className="number-of-people" style={this.calculateOffset(startMinute, cumulativeHeight, highestPoint)}>{exception.height}</div>
              <div className="time" style={this.calculateOffset(startMinute, cumulativeHeight, highestPoint, true)}>{this.shiftTime(startMinute, endMinute)}</div>
            </div>
          )
        })

        return (
          <React.Fragment key={index}>
            {exceptions_elements.reverse()}

            {default_allocations_elements.reverse()}
          </React.Fragment>
        )
      })
    )
  }

  shiftTime(startMinute, endMinute) {
    return `${convertTime(parseInt(startMinute/60))} - ${convertTime(parseInt(endMinute/60))}`
  }

  calculateCoordinates(startMinute, endMinute, height, maxHeight) {
    if (!(height > 0)) {
      return "0% 0%"
    }

    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const startPercentage = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const endPercentage = ((endMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;

    const blockHeight = 100 - (height / maxHeight) * 100
    const bottomOfGraph = 100

    let points = []

    points.push(`${startPercentage}% ${bottomOfGraph}%`)
    points.push(`${startPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${bottomOfGraph}%`)

    // Important note for debugging:
    //
    // The CSS attribute clip-path that we use to drive this feature has a wonky axis.
    //
    // If the calculations seem surprising, this is why:
    //
    //      0%                   100%
    //   0% ┌───────────────────────┐ 0%
    //      │                       │
    //      │                       │
    //    Y │                       │
    //    - │                       │
    //    a │                       │
    //    x │                       │
    //    i │                       │
    //    s │                       │
    //      │                       │
    //      │                       │
    // 100% └───────────────────────┘ 100%
    //      0%        X-axis      100%
    //

    return points
  }

  calculateOffset(startMinute, height, maxHeight, timeLabel = false) {
    if (!(startMinute > 0)) {
      return { left: "0%", right: "0%" }
    }

    const leftPadding = 1
    let topPadding = 1

    if (timeLabel) {
      topPadding += 33
    }

    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const leftOffset = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const blockHeight = 100 - (height / maxHeight) * 100

    return {
      left: `${leftOffset + leftPadding}%`,
      top: `${blockHeight + topPadding}%`
    }
  }

  static getDerivedStateFromProps(props, state) {
    const id = props.match.params.id
    if (state.newPanelToggle && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else if (state.editPanelToggle && id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && id !== state.editId) {
      return {
        editId: id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/required-allocation/weekly")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else {
      return null
    }
  }

  grabData(date) {
    this.setState({
      previouslyGrabbedDateFrom: date
    })

    const dateFrom = date
    const dateTo = new Date(dateFrom)
    dateTo.setDate(dateFrom.getDate() + 7);

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const location_id = this.props.match.params.location_id
    const rota_id = this.props.match.params.rota_id

    let queryString = `location_id=${location_id}`

    if (rota_id !== undefined) {
      queryString += `&rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_required_allocations?${queryString}&date_from=${this.formatDate(dateFrom)}&date_to=${this.formatDate(dateTo)}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ allocations: data, allocationsLoaded: true })
      })
      .catch(error => this.setState({ error, allocationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/weekly_rota_configurations?${queryString}&date_from=${this.formatDate(dateFrom)}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ rota_configurations: data, rotaConfigurationsLoaded: true })
      })
      .catch(error => this.setState({ error, rotaConfigurationsLoaded: true }))
  }

  componentDidMount() {
    let date = new Date()

    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)
      if (params.get('date')) {
        date = new Date(params.get('date'))
      }

      this.setState({
        date: date,
        previouslyGrabbedDateFrom: date,
        key: Math.random()
      });
    }
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let queryString = ""
    const rota_id = this.props.match.params.rota_id

    if (rota_id !== undefined) {
      queryString = `?rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    if (rota_id !== undefined) {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types/${rota_id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ rota: data, rotaLoaded: true })
        })
        .catch(error => this.setState({ error, rotaLoaded: true }))
    }
    else {
      this.setState({ rotaLoaded: true })
    }

    this.grabData(date)
  }

  componentDidUpdate() {
    if (this.state.date !== this.state.previouslyGrabbedDateFrom) {
      this.grabData(this.state.date)
    }
  }
}

export default StaffFulfilmentWeeklyRequiredAllocation;
