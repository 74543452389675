import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import ResellerLogo from '../header/ResellerLogo';
import ResellerCSS from '../header/ResellerCSS';

class LoggedOutHeader extends Component {
  render() {
    return (
      <header>
        <ResellerLogo />

        <div className="top-header signed-out-top-header">
          <nav className="left-nav">
            <ul>
            <li><a href="https://www.dreception.com/"><FontAwesomeIcon icon={faQuestion} /> About</a></li>
            <li><Link to={'/login'}><FontAwesomeIcon icon={faSignInAlt} /> Login</Link></li>
          </ul>
          </nav>
        </div>

        <div className="bottom-header">
          <div className="dr-header-text">
            Digital Reception
          </div>
        </div>

        <ResellerCSS />
      </header>
    )
  }
}

export default LoggedOutHeader;
