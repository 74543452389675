import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'

class CreateDevice extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    location_id: "",
    qr_code_size: "default",
    profile_ids: [],

    locations: [],
    profiles: [],

    locationsLoaded: false,
    profilesLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var profile_ids = this.state.profile_ids

    if (value === true) {
      if (!profile_ids.includes(name)) {
        profile_ids.push(name)
      }
    }
    else if (value === false) {
      if (profile_ids.includes(name)) {
        let index = profile_ids.indexOf(name)
        profile_ids.splice(index, 1)
      }
    }

    this.setState({profile_ids: profile_ids});
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "device": {
        "name": this.state.name,
        "qr_code_size": this.state.qr_code_size,
        "location_id": this.state.location_id,
        "profile_ids": this.state.profile_ids,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const { name, location_id, qr_code_size, profile_ids, locations, profiles, locationsLoaded, profilesLoaded, id, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/configuration/devices/${id}`}/>
    }

    if (locationsLoaded === false || profilesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded && profilesLoaded) {
      return (
        <div>
          <SetTitle title={"New Device | Devices"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'device'} section={'name'} />

              <label className="column">Device Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'device'} section={'location'} />

              <label className="column">Location:</label>{ this.renderErrors('location') }
              <select name="location_id" onChange={this.handleChange} value={location_id}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'device'} section={'qr_code_size'} />

              <label className="column">QR Code Size:</label>{ this.renderErrors('qr_code_size') }
              <div className="qr-code-options">
                <div className="qr-code-option">
                  <input className="column" type="radio" name="qr_code_size" id="default" value="default" checked={qr_code_size === "default"} onChange={this.handleChange} />
                  <label htmlFor="default">Default</label>
                  <div/>
                  <label className="sample-qr" htmlFor="default">Default QR size</label>
                </div>

                <div className="qr-code-option">
                  <input className="column" type="radio" name="qr_code_size" id="small" value="small" checked={qr_code_size === "small"} onChange={this.handleChange} />
                  <label htmlFor="small">Small</label>
                  <div/>
                  <label className="sample-qr-small" htmlFor="small">Reduced size</label>
                </div>
              </div>
            </div>

            <div className="row">
              <HelpText page={'device'} section={'profiles'} />

              <label className="column">Profiles:</label>{ this.renderErrors('profile') }
              <div className="scrollable-table">
                <table className="checkbox-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profiles.map((profile) => (
                      <tr key={profile.id}>
                        <td>
                          <Link to={`/configuration/profiles/${profile.id}`}>{profile.name}</Link>
                        </td>
                        <td>
                          {profile.display_name}
                        </td>
                        <td className="center-cell">
                          <input className="column" type="checkbox" data-name={profile.name} name={profile.id} checked={profile_ids.includes(profile.id)} onChange={this.handleMultiCheckbox} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ profiles: data, profilesLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default CreateDevice;
