import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import BigLogo from '../../components/header/BigLogo';
import SetTitle from '../../components/shared/SetTitle';

class PasswordReset extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    password: "",
    token: "",
    updated: false,
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "password_reset": {
        "password": this.state.password,
        "reset_token": this.props.match.params.token,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/reset_password`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ updated: true, errors: "" })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderErrors() {
    if (this.state.errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {this.state.errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }
  }

  renderUpdated() {
    if (this.state.updated) {
      return (
        <div className="login-form forgotten-password">
          <p className="success">Thank you, your password has been reset. You may now <Link to={'/login'}>log in</Link> again.</p>
        </div>
      )
    }
  }

  renderForm() {
    if (this.state.updated === false) {
      return (
        <div className="login-form forgotten-password">
          <p>If you wish to reset your password, you may enter a new one below.</p>
          <p>Make sure you type it correctly, as we will only ask you to type it once!</p>
          <form onSubmit={this.handleSubmit}>
            <label>
              <input placeholder=" new password" aria-required="true" type="password" name="password" value={this.state.password} autoComplete="new-password" onChange={this.handleChange} />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </form>

          { this.renderErrors() }
        </div>
      )
    }
  }

  render() {

    return (
      <div className="login-page">
        <SetTitle title={"Password Reset"} />
        <LoggedOutHeader />

        <div className="center-container">
          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            {this.renderForm()}
            {this.renderUpdated()}
          </div>

        </div>

      </div>
    );
  }
}

export default PasswordReset;
