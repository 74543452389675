import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';

import { adminUser, managerUser } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class UpdateRelationship extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePersonSearch = this.handlePersonSearch.bind(this);
    this.debouncedHandleSearch = debounce(this.handlePersonSearch, 500);
  }

  state = {
    search: "",
    type_of_person: "",
    person_id: this.props.person_id,
    relationship_type: this.relationshipTypeLookup(),

    people: [],
    originalPerson: {full_name: this.props.other_person_name, id: this.props.other_person_id},
    selectedPerson: {full_name: this.props.person_name, id: this.props.person_id},

    originalPersonLoaded: false,
    peopleLoaded: true,

    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  relationshipTypeLookup() {
    switch(this.props.relationship_type) {
      case "Parent":
        return "Parent/Child"
      case "Child":
        return "Child/Parent"
      case "Spouse":
        return "Spuse/Spuse"
      case "Grandparent":
        return "Grandparent/Grandchild"
      case "Grandchild":
        return "Grandchild/Grandparent"
      case "Friend":
        return "Friend/Friend"
      case "Other Relative":
        return "Other Relative/Other Relative"
      case "Professional":
        return "Professional/Client"
      case "Client":
        return "Client/Professional"
      case "Other":
        return "Other/Other"
      default:
        return ""
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSearchChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "search") {
      this.setState({peopleLoaded: false});
      this.debouncedHandleSearch(event)
    }
    else {
      this.handlePersonSearch(event)
    }
  }

  handlePersonSearch(event) {
    if (this.state.search === "") {
      this.setState({
        peopleLoaded: true
      })

      return
    }

    let dataObject = {
      search: this.state.search,
      type_of_person: this.state.type_of_person
    }

    let name = event.target.name;
    let value = event.target.value;

    dataObject[name] = value

    let params = `person[partial]=${dataObject.search}`

    if (dataObject.type_of_person) {
      params += `&person[type]=${dataObject.type_of_person}`
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let people = data.filter(person => person.id.toString() !== this.props.other_person_id)

        this.setState({ people: people, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, peopleLoaded: true }))
  }

  handleSubmit(event) {
    this.setState({
      updated: "",
      updating: true
    })

    const other_person_id = this.props.other_person_id;

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "relationship": {
        "person_relationships_attributes": [{
          "id": this.props.first_person_relationship_id,
          "relationship_type": this.state.relationship_type.split('/')[0],
          "person_id": this.state.person_id,
        }, {
          "id": this.props.second_person_relationship_id,
          "relationship_type": this.state.relationship_type.split('/')[1],
          "person_id": other_person_id,
        }]
      }
    })

    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let bigError = false
    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/relationships/${this.props.id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        bigError = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (bigError) {
        this.setState({ error: data })
      }
      else if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      }
      else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Relationship updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, updating: false }))

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      const capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderAlternateName(person) {
    if (person.alternate_name !== null) {
      return `(${person.alternate_name})`
    }
  }

  renderRelationshipDescription(person_id) {
    if (person_id && this.state.relationship_type) {

      const originalPerson = this.state.originalPerson
      let selectedPerson
      let message

      if (this.state.selectedPerson.id === person_id) {
        selectedPerson = this.state.selectedPerson
        message = "is"
      }
      else {
        selectedPerson = this.state.people.filter((person) => person.id.toString() === person_id)[0]
        message = "will be"
      }

      return (
        <div>
          <div>{selectedPerson.full_name} {message} registered as {originalPerson.full_name}'s {this.state.relationship_type.split('/')[0]}.</div>
          <br/>
        </div>
      )
    }
  }

  render() {
    const { type_of_person, search, person_id, relationship_type, people, peopleLoaded, updated, unauthorized, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return (
        <div>
          {error["errors"].map((errors, index) => (
            <div className="warning-box" key={index}>{errors}</div>
          ))}
        </div>
      )
    }

    const visitors = people.filter((person) => person.type_of_person === "visitor")
    const residents = people.filter((person) => person.type_of_person === "resident")
    const staff = people.filter((person) => person.type_of_person === "staff")
    const contractors = people.filter((person) => person.type_of_person === "contractor")
    const industry_professionals = people.filter((person) => person.type_of_person === "industry professional")
    const other = people.filter((person) => person.type_of_person === "other")

    return (
      <div>
        <SetTitle title={"New Relationship | People"} />

        <form className="settings big-settings" onSubmit={this.handleSubmit}>
          <label className="column">Type:</label>
          <select name="type_of_person" value={type_of_person} onChange={this.handleSearchChange} data-testid="select-type-of-person">
            <option value="">All</option>
            <option value="visitor">Visitors</option>
            <option value="resident">Residents</option>
            <option value="staff">Staff</option>
            <option value="contractor">Contractors</option>
            <option value="industry professional">Industry Professionals</option>
            <option value="other">Other</option>
          </select>

          <div className="row">
            <label className="column">Person:</label>{ this.renderErrors('person') }
            <input placeholder="Enter text to search" className="no-margin" type="text" name="search" value={search} onChange={this.handleSearchChange} />
            {peopleLoaded === false ? (
              <select name="person_id" onChange={this.handleChange} value={person_id}>
                <option value="">Loading...</option>
              </select>
            ) : (
              <select name="person_id" onChange={this.handleChange} value={person_id}>
                <option value="">Select</option>
                { (type_of_person === "visitor" || !type_of_person) &&
                  <optgroup label="Visitors">
                    {visitors.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
                { (type_of_person === "resident" || !type_of_person) &&
                  <optgroup label="Residents">
                    {residents.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
                { (type_of_person === "staff" || !type_of_person) &&
                  <optgroup label="Staff">
                    {staff.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
                { (type_of_person === "contractor" || !type_of_person) &&
                  <optgroup label="Contractors">
                    {contractors.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
                { (type_of_person === "industry professional" || !type_of_person) &&
                  <optgroup label="Industry Professionals">
                    {industry_professionals.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
                { (type_of_person === "other" || !type_of_person) &&
                  <optgroup label="Other">
                    {other.map((person) => (
                      <option value={person.id} key={person.id}>{person.full_name}</option>
                    ))}
                  </optgroup>
                }
              </select>
            )}
          </div>

          <div className="row">
            <label className="column">Relationship Type:</label>{ this.renderErrors('relationship_type') }
            <select name="relationship_type" onChange={this.handleChange} value={relationship_type}>
              <option value="">Select</option>
              <option value="Parent/Child">Parent</option>
              <option value="Child/Parent">Child</option>
              <option value="Spouse/Spouse">Spouse</option>
              <option value="Grandparent/Grandchild">Grandparent</option>
              <option value="Grandchild/Grandparent">Grandchild</option>
              <option value="Friend/Friend">Friend</option>
              <option value="Other Relative/Other Relative">Other Relative</option>
              <option value="Professional/Client">Professional</option>
              <option value="Client/Professional">Client</option>
              <option value="Other/Other">Other</option>
            </select>
          </div>

          { this.renderRelationshipDescription(person_id)}
          { this.renderSaveButton() }
          { this.renderUpdated(updated) }
        </form>
      </div>
    );
  }
}

export default UpdateRelationship;
