import React, { Component } from 'react';

import parse from 'html-react-parser';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

class NoticeBox extends Component {
  render() {
    var type = this.props.type;
    var text = this.props.text;

    if (type === "info") {
      return (
        <div className="information-box">
          <FontAwesomeIcon icon={faInfo} className="icon" />
          {text && (<p>{parse(text)}</p>)}
          {this.props.children}
        </div>
      );
    }

    if (type === "warning") {
      return (
        <div className="warning-box">
          <label className="column">
            <strong>WARNING:</strong>
            {text && (<p>{parse(text)}</p>)}
            {this.props.children}
          </label>
        </div>
      );
    }
  }
}

export default NoticeBox;
