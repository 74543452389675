import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class MergePeople extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.swapPeople = this.swapPeople.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
  }

  state = {
    target_person_id: "",
    to_be_deleted_person_id: "",
    target_person: "",
    to_be_deleted_person: "",
    updated_person: "",

    people: [],
    partial: "",
    searchedPeople: null,
    custom_attributes: [],
    customAttributesLoaded: false,
    errors: [],
    error: "",
    updated: ""
  };

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });

    // Fetch data on selected person
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const data_name = name.replace("_id", "")

    // return to default state of that person if person_id returns to empty
    if (value === "") {
      this.setState({
        [data_name]: "",
        updated_person: ""
      })
      return
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${value}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          [data_name]: data
        })

        // Calculate updated_person details (if both people have been selected)
        // Need to do it here so we have access to `data`.
        if (data_name === "target_person" && this.state.to_be_deleted_person !== "") {
          this.calculateUpdatedPerson(data, this.state.to_be_deleted_person)
        }

        if (data_name === "to_be_deleted_person" && this.state.target_person !== "") {
          this.calculateUpdatedPerson(this.state.target_person, data)
        }
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  swapPeople() {
    this.calculateUpdatedPerson(this.state.to_be_deleted_person, this.state.target_person)

    this.setState(prevState => ({
    target_person_id: prevState.to_be_deleted_person_id,
    to_be_deleted_person_id: prevState.target_person_id,
    target_person: prevState.to_be_deleted_person,
    to_be_deleted_person: prevState.target_person,}))
  }

  calculateUpdatedPerson(target_person, to_be_deleted_person) {
    if (!target_person || !to_be_deleted_person) {
      return
    }

    let updated_person = {}

    updated_person.first_name = target_person.first_name || to_be_deleted_person.first_name
    updated_person.last_name = target_person.last_name || to_be_deleted_person.last_name
    updated_person.alternate_name = target_person.alternate_name || to_be_deleted_person.alternate_name
    updated_person.display_name = target_person.display_name || to_be_deleted_person.display_name
    updated_person.phone = target_person.phone || to_be_deleted_person.phone
    updated_person.email = target_person.email || to_be_deleted_person.email
    updated_person.type_of_person = target_person.type_of_person || to_be_deleted_person.type_of_person
    updated_person.person_import = target_person.import_ids.length > 0 || to_be_deleted_person.import_ids.length > 0
    updated_person.import_warning = target_person.import_ids.length + to_be_deleted_person.import_ids.length > 1

    const custom_attributes = this.state.custom_attributes
    let custom_attributes_array = []

    custom_attributes.forEach((custom_attribute) => {
      let target_value = this.customAttributeFinder(custom_attribute, target_person)
      let back_up_value = this.customAttributeFinder(custom_attribute, to_be_deleted_person)

      if (target_value || back_up_value) {
        custom_attributes_array.push(target_value || back_up_value)
      }
    })

    updated_person.custom_attributes = custom_attributes_array

    this.setState({
      "updated_person": updated_person
    })
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      peopleLoaded: false
    });


    this.debouncedHandlePersonSearch(value)
  }

  handlePersonSearch(partial) {
    if (partial === "") {
      let people = []

      // Include currently selected people
      if (this.state.target_person) {
        people.push(this.state.target_person)
      }

      if (this.state.to_be_deleted_person) {
        people.push(this.state.to_be_deleted_person)
      }

      this.setState({
        searchedPeople: people,
        peopleLoaded: true
      })

      return
    }

    const params = `person[partial]=${partial}`

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let people = data
        const ids = people.map((person) => person.id)
        // include currently selected people if they would be omitted by the search terms
        if (this.state.target_person && !ids.includes(this.state.target_person.id)) {
          people.push(this.state.target_person)
        }
        if (this.state.to_be_deleted_person && !ids.includes(this.state.to_be_deleted_person.id)) {
          people.push(this.state.to_be_deleted_person)
        }

        this.setState({ searchedPeople: people, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "people": {
        "target_person_id": this.state.target_person_id,
        "to_be_deleted_person_id": this.state.to_be_deleted_person_id,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/merge_people`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data.errors })
      } else {
        // Re-fetch the list of people, now we have one less
        this.componentDidMount()
        this.handlePersonSearch(this.state.partial)

        this.setState({
          target_person_id: "",
          to_be_deleted_person_id: "",
          target_person: "",
          to_be_deleted_person: "",
          updated: true,
          updating: false,
          errors: ""
        })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderErrors() {
    if (this.state.errors.length > 0) {

      return (
        <div className="errors">
          {this.state.errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      )
    }

    if (this.state.error.length > 0) {
      return (
        <div>{this.state.error}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Target Person has been updated, and To Be Deleted Person has been deleted.
        </div>
      );
    }
  }

  renderUpdating(updating) {
    if (updating) {
      return (
        <div>
          Merging...
        </div>
      );
    }
  }

  renderSaveButton() {
    let disabled = false

    // Disable Merge button until two people are selected
    if (this.state.target_person === "" || this.state.to_be_deleted_person === "") {
      disabled = true
    }

    if (this.state.updating === true) {
      disabled = true
    }

    if (adminUser() || managerUser()) {
      return <input type="submit" value="Merge" disabled={disabled} onClick={this.handleSubmit} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderSelectBox(name, value, loaded) {
    if (loaded === false) {
      return (
        <select name={name} onChange={this.handleChange} value={value}>
          <option value="">Loading...</option>
        </select>
      )
    }

    const people = this.state.searchedPeople || this.state.people
    const visitors = people.filter((person) => person.type_of_person === "visitor")
    const residents = people.filter((person) => person.type_of_person === "resident")
    const staff = people.filter((person) => person.type_of_person === "staff")
    const contractors = people.filter((person) => person.type_of_person === "contractor")
    const industry_professionals = people.filter((person) => person.type_of_person === "industry professional")
    const other = people.filter((person) => person.type_of_person === "other")

    const target_person_id = parseInt(this.state.target_person_id)
    const to_be_deleted_person_id = parseInt(this.state.to_be_deleted_person_id)

    return (
      <select name={name} onChange={this.handleChange} value={value}>
        <option value="">Select</option>
        <optgroup label="Visitors">
          {visitors.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
        <optgroup label="Residents">
          {residents.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
        <optgroup label="Staff">
          {staff.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
        <optgroup label="Contractor">
          {contractors.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
        <optgroup label="Industry Professional">
          {industry_professionals.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
        <optgroup label="Other">
          {other.map((person) => {
            const disabled = target_person_id === person.id || to_be_deleted_person_id === person.id

            return (
              <option value={person.id} key={person.id} disabled={disabled}>{person.full_name}</option>
            )
          })}
        </optgroup>
      </select>
    )
  }

  renderCustomAttributeRows(custom_attributes, target_person, updated_person, to_be_deleted_person) {
    if (target_person === "") {
      return (
        <>
          {custom_attributes.map((custom_attribute) => {
            return (
              <div key={`custom_attribute${custom_attribute.id}`} className="row">
                <div className="cell">{custom_attribute.name}</div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
              </div>
            )
          })}
        </>
      )
    }

    if (custom_attributes.length === 0) {
      return
    }

    return (
      <>
        {custom_attributes.map((custom_attribute) => {
          const targetPersonAttribute = this.customAttributeFinder(custom_attribute, target_person)
          const updatedPersonAttribute = this.customAttributeFinder(custom_attribute, updated_person)
          const deletedPersonAttribute = this.customAttributeFinder(custom_attribute, to_be_deleted_person)

          return (
            <div key ={`custom_attribute_${custom_attribute.id}_parent_div`} className="row">
              <div className="cell">{custom_attribute.name}</div>
              <div className="cell">{this.renderCustomAttributeValue(target_person, targetPersonAttribute)}</div>
              <div className="cell bold">{this.renderCustomAttributeValue(updated_person, updatedPersonAttribute)}</div>
              <div className="cell">{this.renderCustomAttributeValue(to_be_deleted_person, deletedPersonAttribute)}</div>
            </div>
          )
        })}
      </>
    )
  }

  renderCustomAttributeValue(person, attribute) {
    if (person === "") {
      return
    }

    if (attribute) {
      return attribute.value
    }
    else {
      return "n/a"
    }
  }

  customAttributeFinder(custom_attribute, person) {
    if (person === "") {
      return
    }

    const index = person.custom_attributes.findIndex(element => element.custom_attribute_id === custom_attribute.id)

    return person.custom_attributes[index]
  }

  render() {
    const { target_person_id, to_be_deleted_person_id, target_person, updated_person, to_be_deleted_person, peopleLoaded, custom_attributes, customAttributesLoaded, updated, updating, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (customAttributesLoaded === false) {
      return (
        <div>
          <SetTitle title={"People"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Merge People</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    if (customAttributesLoaded) {
      return (
        <div>
          <SetTitle title={"People"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Merge People</h2>
            <OrganisationName /> 

            <div className="settings big-settings">
              <div className="two-columns top-padding">
                <div>
                  <HelpText page={'person'} section={'target_person'} />
                  <label className="column">Target Person:</label>
                  <input name="partial" placeholder="Enter text to search" className="search-text full-width" onChange={this.handlePartialChange} value={this.state.partial} />
                  {this.renderSelectBox("target_person_id", target_person_id, peopleLoaded)}
                </div>

                <button className="person-swap" title="Swap People" onClick={this.swapPeople}><FontAwesomeIcon icon={faExchangeAlt} /></button>

                <div>
                  <HelpText page={'person'} section={'to_be_deleted_person'} />
                  <label className="column">To Be Deleted Person:</label>
                  <input name="partial" placeholder="Enter text to search" className="search-text full-width" onChange={this.handlePartialChange} value={this.state.partial} />
                  {this.renderSelectBox("to_be_deleted_person_id", to_be_deleted_person_id, peopleLoaded)}
                </div>
              </div>

              <h3 className="merge-title">Results</h3>

              <div className="merge-results">
                <div className="row">
                  <div className="cell"></div>
                  <div className="cell bold">Target Person</div>
                  <div className="cell bold">Merged Person</div>
                  <div className="cell bold">To Be Deleted Person</div>
                </div>

                <div className="row">
                  <div className="cell">First Name</div>
                  <div className="cell">{target_person.first_name}</div>
                  <div className="cell bold">{updated_person.first_name}</div>
                  <div className="cell">{to_be_deleted_person.first_name}</div>
                </div>

                <div className="row">
                  <div className="cell">Last Name</div>
                  <div className="cell">{target_person.last_name}</div>
                  <div className="cell bold">{updated_person.last_name}</div>
                  <div className="cell">{to_be_deleted_person.last_name}</div>
                </div>

                <div className="row">
                  <div className="cell">Alternate Name</div>
                  <div className="cell">{target_person.alternate_name}</div>
                  <div className="cell bold">{updated_person.alternate_name}</div>
                  <div className="cell">{to_be_deleted_person.alternate_name}</div>
                </div>

                <div className="row">
                  <div className="cell">Display Name</div>
                  <div className="cell">{target_person.display_name}</div>
                  <div className="cell bold">{updated_person.display_name}</div>
                  <div className="cell">{to_be_deleted_person.display_name}</div>
                </div>

                <div className="row">
                  <div className="cell">Phone</div>
                  <div className="cell">{target_person.phone}</div>
                  <div className="cell bold">{updated_person.phone}</div>
                  <div className="cell">{to_be_deleted_person.phone}</div>
                </div>

                <div className="row">
                  <div className="cell">Email</div>
                  <div className="cell">{target_person.email}</div>
                  <div className="cell bold">{updated_person.email}</div>
                  <div className="cell">{to_be_deleted_person.email}</div>
                </div>

                <div className="row">
                  <div className="cell">Type Of Person</div>
                  <div className="cell">{target_person.type_of_person}</div>
                  <div className="cell bold">{updated_person.type_of_person}</div>
                  <div className="cell">{to_be_deleted_person.type_of_person}</div>
                </div>

                {this.renderCustomAttributeRows(custom_attributes, target_person, updated_person, to_be_deleted_person)}

                <div className="row">
                  <div className="cell">Is Imported?</div>
                  <div className="cell">{target_person && (target_person.import_ids.length > 0).toString()}</div>
                  <div className="cell bold">{updated_person && updated_person.person_import.toString()}</div>
                  <div className="cell">{to_be_deleted_person && (to_be_deleted_person.import_ids.length > 0).toString()}</div>
                </div>


                <div className="row">
                  <div className="cell">Multiple Imports?</div>
                  <div className="cell">{target_person && (target_person.import_ids.length > 1).toString()}</div>
                  <div className="cell bold">{updated_person && updated_person.import_warning.toString()}</div>
                  <div className="cell">{to_be_deleted_person && (to_be_deleted_person.import_ids.length > 1).toString()}</div>
                </div>
              </div>

              { this.renderSaveButton() }
              { this.renderUpdating(updating) }
              { this.renderUpdated(updated) }
              { this.renderErrors() }
            </div>
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/custom_attributes/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ custom_attributes: data, customAttributesLoaded: true })
      })
      .catch(error => this.setState({ error, customAttributesLoaded: true }))
  }
}

export default MergePeople;
