import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import SetTitle from '../components/shared/SetTitle';

class Error404 extends Component {
  render() {
    return (
      <div>
        <SetTitle title={"Page Not Found"} />
        <Header />

        <div className="main-page">
          <h2>Page not found</h2>

          <div>Sorry, that page doesn't seem to exist. Try going back to the <Link to={'/'}>homepage</Link>.</div>
        </div>
      </div>
    );
  }
}

export default Error404;