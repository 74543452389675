import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { futureEndDayOfWeek } from '../../utilities/Generic.js'

class CreateDefaultRequiredAllocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.selectAllDays = this.selectAllDays.bind(this);
    this.unselectAllDays = this.unselectAllDays.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    number_of_staff: "1",
    days: this.props.day_of_week ? [this.props.day_of_week] : [],
    start_time: this.props.clickedHour || this.generateMinStartTime(),
    end_time: this.generateClickedHourEndTime(),
    role_id: this.props.role_id || "",

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    roles: null,
    rolesLoaded: false,
    unauthorized: "",
    errors: ""
  };

  generateClickedHourEndTime() {
    const startTime = this.props.clickedHour || this.generateMinStartTime()

    let time = new Date("1970-01-01T" + startTime + "Z");
    time.setMinutes(time.getMinutes() + time.getTimezoneOffset());
    time.setHours(time.getHours() + 1);

    return time.toTimeString().slice(0, 5);
  }

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  selectAllDays(event) {
    const default_period_days = (this.state.roles.find(role => (role.id === parseInt(this.state.role_id)))?.default_period_weeks || this.state.location_default_period_weeks || 1) * 7;

    let days = [];
    for (let i = 0; i < default_period_days; i++) {
      days.push(i.toString())
    }

    this.setState({
      days: days
    });

    event.preventDefault();
  }

  unselectAllDays(event) {
    this.setState({
      days: []
    });

    event.preventDefault();
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "role_id") {
      const default_period_days = (this.state.roles.find(role => (role.id === parseInt(value)))?.default_period_weeks || this.state.location_default_period_weeks || 1) * 7;

      return this.setState(prevState => {
        let days = prevState.days.filter(day => parseInt(day) < default_period_days)
        return ({
          [name]: value,
          days: days
        })
      })
    }

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    const value = event.target.value;

    let days = this.state.days

    if (days.includes(value)) {
      days = days.filter(day => day !== value);
    } else {
      days.push(value)
    }

    this.setState({days: days});
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const default_period_days = (this.state.roles.find(role => (role.id === parseInt(this.state.role_id)))?.default_period_weeks || this.state.location_default_period_weeks || 1) * 7

    let days = []

    this.state.days.map(day => (
      days.push({
        "number_of_staff": this.state.number_of_staff,
        "day_of_week": day,
        "end_day_of_week": futureEndDayOfWeek(day, this.state.start_time, this.state.end_time) % default_period_days,
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      })
    ));

    var json = JSON.stringify({
      "default_required_allocation": days
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations/multi_create`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Required Allocation added successfully!");
        this.props.closeNewPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderDaysOfWeek(default_period_weeks) {
    const days = this.state.days;

    if (default_period_weeks < 2) {
      return (
        <>
          <label>Days:</label>
          <div className="fulfilment-day-options">
            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#monday" value="0" checked={days.includes("0")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#monday">Monday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#tuesday" value="1" checked={days.includes("1")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#tuesday">Tuesday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#wednesday" value="2" checked={days.includes("2")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#wednesday">Wednesday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#thursday" value="3" checked={days.includes("3")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#thursday">Thursday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#friday" value="4" checked={days.includes("4")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#friday">Friday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#saturday" value="5" checked={days.includes("5")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#saturday">Saturday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#sunday" value="6" checked={days.includes("6")} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#sunday">Sunday</label>
            </div>
          </div>
        </>
      )
    }

    let days_of_week = []
    for (let i = 0; i < default_period_weeks; i++) {
      let week_modifier = 7 * i
      days_of_week.push(
        <React.Fragment key={i}>
          <div>Week {i + 1}:</div>
          <div className="fulfilment-day-options">
            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#monday" value={(0 + week_modifier).toString()} checked={days.includes((0 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#monday">Monday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#tuesday" value={(1 + week_modifier).toString()} checked={days.includes((1 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#tuesday">Tuesday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#wednesday" value={(2 + week_modifier).toString()} checked={days.includes((2 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#wednesday">Wednesday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#thursday" value={(3 + week_modifier).toString()} checked={days.includes((3 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#thursday">Thursday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#friday" value={(4 + week_modifier).toString()} checked={days.includes((4 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#friday">Friday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#saturday" value={(5 + week_modifier).toString()} checked={days.includes((5 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#saturday">Saturday</label>
            </div>

            <div className="fulfilment-day-option">
              <input className="column" type="checkbox" name="days" id="#sunday" value={(6 + week_modifier).toString()} checked={days.includes((6 + week_modifier).toString())} onChange={this.handleMultiCheckbox} />
              <label htmlFor="#sunday">Sunday</label>
            </div>
          </div>
        </React.Fragment>
      )
    }

    return days_of_week
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { number_of_staff, days, start_time, end_time, role_id, min_start_time, max_start_time, min_end_time, max_end_time, roles, rolesLoaded, location_default_period_weeks, locationLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false || locationLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded && locationLoaded) {
      const default_period_weeks = roles.find(role => (role.id === parseInt(role_id)))?.default_period_weeks || location_default_period_weeks || 1

      return (
        <div>
          <SetTitle title={"New Default Required Allocation | Staff Fulfilment"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'default_required_allocation'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              {this.renderDaysOfWeek(default_period_weeks)}

              <div className="fulfilment-day-button">
                {days.length === 7 * default_period_weeks && (<button className="button" onClick={this.unselectAllDays}>Unselect all days</button>)}
                {days.length !== 7 * default_period_weeks && (<button className="button" onClick={this.selectAllDays}>Select all days</button>)}
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'start_time'} />

                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'end_time'} />

                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleChange} />
              </div>

              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'default_required_allocation'} section={'number_of_staff'} />

              <label className="column">Number of Staff:</label>{ this.renderErrors('number_of_staff') }
              <input className="column" type="number" min="1" step="1" name="number_of_staff" value={number_of_staff} onChange={this.handleChange} />
            </div>


            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/role_configurations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/location_configuration?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location_default_period_weeks: data.default_period_weeks, locationLoaded: true })
      })
      .catch(error => this.setState({ error, locationLoaded: true }))
  }
}

export default CreateDefaultRequiredAllocation;
