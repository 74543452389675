import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

class PeopleTotals extends Component {
  render() {
    const { people, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="widget">
          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Type of person</div>
              <div className="th">Number</div>
              <div className="th">Last used</div>
            </div>
            {people.map((person) => (
               <Link to={`/people?location=${this.props.id}&type=${person.name.toLowerCase()}`} key={person.name} className="tr">
                <div className="td bold">{person.name}</div>
                <div className="td">{person.count}</div>
                <div className="td">{person.last_used}</div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
  }

  state = {
    people: [],
    loaded: false,
    error: null
  };

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/people_totals`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ people: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default PeopleTotals;
