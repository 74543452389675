import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PeopleOnSite extends Component {
  render() {
    const { json, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const data = {
        labels: ['Visitors', 'Residents', 'Staff', 'Contractors', 'Industry Professionals', 'Other'],
        datasets: [{
          data: [
            json.visitors,
            json.residents,
            json.staff,
            json.contractors,
            json.industry_professionals,
            json.other
          ],
          backgroundColor: ['#eee622', '#22ee80', '#222aee', '#ee2290', '#b80c09', '#8826bf']
        }],
      }

      const options = {}

      const baseLicense = localStorage.license === "base"

      return (
        <div className="widget">
          <h3>People on Site chart</h3>
          { baseLicense && <h3 className="fire-log-title"><FontAwesomeIcon icon={faBell} /> Fire Log</h3> }
          <Pie data={data} width={500} height={300} options={options} />
        </div>
      );
    }
  }

  state = {
    json: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/people_on_site`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ json: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default PeopleOnSite;
