import React, { Component } from 'react';
import { Redirect, Link  } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import NoticeBox from '../../components/shared/NoticeBox';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning, spaceAndCapitalize } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class UpdateLocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBorderColorChange = this.handleBorderColorChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.applyToAllLocations = this.applyToAllLocations.bind(this);
    this.attachImageToAllLocations = this.attachImageToAllLocations.bind(this);
    this.showAutumnaKey = this.showAutumnaKey.bind(this);
    this.openCancelAutumnaIntegrationModal = this.openCancelAutumnaIntegrationModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.generateAutumnaKey = this.generateAutumnaKey.bind(this);
    this.validateAutumnaKey = this.validateAutumnaKey.bind(this);
    this.cancelAutumnaIntegration = this.cancelAutumnaIntegration.bind(this);
    this.clearBackgroundImageUpload = this.clearBackgroundImageUpload.bind(this);
    this.clearLogoUpload = this.clearLogoUpload.bind(this);
  }

  state = {
    name: "",
    timezone: "",
    timezones: [],
    regulator_reference: "",
    postcode: "",
    address: "",
    phone: "",
    hidden: false,
    background_image: null,
    logo: null,
    border_color: "#20c7d4",
    border_preview: "#20c7d4",
    welcome_message: "",
    device_help_message: "",
    text_on_select: "",
    arrival_thank_you_message: "",
    departure_thank_you_message: "",
    fire_logs_email: "",
    fire_log_access_pin: "",
    fire_log_access_pin_updated_at: "",
    email_fire_log: false,
    autumna_shared_secret: "",
    validated_secret_against_autumna: false,
    autumna_feedback_assigned_to_profiles_at_location: false,
    showAutumnaKey: false,
    cancelAutumnaIntegrationModal: false,
    validationLoading: false,
    failedValidation: false,
    copiedToClipboard: false,
    upload_background_image_file: null,
    upload_logo_file: null,
    user_ids: [],
    fire_log_user_ids: [],

    users: [],
    partial: "",
    searchedUsers: null,

    applied_to_all_locations: [],

    loaded: false,
    usersLoaded: false,
    timezonesLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      applied_to_all_locations: []
    });
  }

  handleBorderColorChange(event) {
    let value = event.target.value;

    this.setState({border_color: value});
    if (this.validateBorderColor(value)) {
      this.setState({border_preview: value})
    }
    else {
      this.setState({border_preview: "#20c7d4"})
    }
  }

  validateBorderColor(string) {
    if (string && [4, 5, 7].includes(string.length) && string[0] === "#" && this.hexValidator(string)) {
      return true
    }
  }

  hexValidator(string) {
    const validCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"]
    for (let index in string) {
      if (index === "0") {
        continue
      }

      if (!validCharacters.includes(string[index].toUpperCase())) {
        return false
      }
    }
    return true
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;
    const key = event.target.dataset.key;

    var state = this.state[key]

    if (value === true) {
      if (!state.includes(name)) {
        state.push(name)
      }
    }
    else if (value === false) {
      if (state.includes(name)) {
        let index = state.indexOf(name)
        state.splice(index, 1)
      }
    }

    this.setState({key: state});
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({
      [name]: value,
      applied_to_all_locations: []
    });
  }

  clearBackgroundImageUpload(event) {
    this.setState({
      background_image: null,
      applied_to_all_locations: []
    });

    event.preventDefault();
  }

  clearLogoUpload(event) {
    this.setState({
      logo: null,
      applied_to_all_locations: []
    });

    event.preventDefault();
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandlePersonSearch(value)
  }

  handlePersonSearch(partial) {
    if (partial === "") {
      return this.setState({ searchedUsers: null })
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/names_and_ids?user[search]=${partial}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ searchedUsers: data, usersLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  handleSubmit(event) {
    const id = this.props.id;

    const formData = new FormData()
    formData.append('location[name]', this.state.name)
    formData.append('location[timezone]', this.state.timezone)
    formData.append('location[regulator_reference]', this.state.regulator_reference)
    formData.append('location[postcode]', this.state.postcode)
    formData.append('location[address]', this.state.address)
    formData.append('location[phone]', this.state.phone)
    formData.append('location[hidden]', this.state.hidden)
    formData.append('location[border_color]', this.state.border_color)
    formData.append('location[welcome_message]', this.state.welcome_message)
    formData.append('location[device_help_message]', this.state.device_help_message)
    formData.append('location[text_on_select]', this.state.text_on_select)
    formData.append('location[arrival_thank_you_message]', this.state.arrival_thank_you_message)
    formData.append('location[departure_thank_you_message]', this.state.departure_thank_you_message)
    formData.append('location[fire_logs_email]', this.state.fire_logs_email)
    formData.append('location[fire_log_access_pin]', this.state.fire_log_access_pin)
    formData.append('location[email_fire_log]', this.state.email_fire_log)

    this.state.user_ids.map((user_id, index) => (
      formData.append(`location[user_ids][]`, user_id)
    ))

    this.state.fire_log_user_ids.map((fire_log_user_id, index) => (
      formData.append(`location[fire_log_user_ids][]`, fire_log_user_id)
    ))

    if (this.state.upload_background_image_file !== null) {
      formData.append('location[background_image]', this.state.upload_background_image_file)
    }

    if (this.state.upload_logo_file !== null) {
      formData.append('location[logo]', this.state.upload_logo_file)
    }

    var requestOptions = {
      method: 'PUT',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Location updated successfully!");
        this.props.updateName(data.name)
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  applyToAllLocations(event) {
    const name = event.target.getAttribute("name");
    const value = this.state[name];

    const formData = new FormData()

    if (name === "background_image" && this.state.upload_background_image_file !== null) {
      formData.append('location[background_image]', this.state.upload_background_image_file)
    }

    else if (name === "logo" && this.state.upload_logo_file !== null) {
      formData.append('location[logo]', this.state.upload_logo_file)
    }
    else {
      formData.append(`location[${name}]`, value)
    }


    var requestOptions = {
      method: 'PUT',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/apply_to_all_locations`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          errors: "",
          applied_to_all_locations: data.applied_to_all_locations
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  attachImageToAllLocations(event) {
    const id = this.props.id;
    const name = event.target.getAttribute("name");

    const formData = new FormData()

    formData.append(`location[${name}]`, true)

    var requestOptions = {
      method: 'PUT',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/attach_image_to_all_locations`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          errors: "",
          applied_to_all_locations: data.applied_to_all_locations
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  generateAutumnaKey(event) {
    const id = this.props.id;

    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/generate_autumna_key`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        // escape from javascript string literal new line: "\n" at the end of encrypted strings
        let autumna_shared_secret = data.shared_secret.replace(/\n/g, '\\n')
        navigator.clipboard.writeText(autumna_shared_secret)
        this.setState({
          autumna_shared_secret: autumna_shared_secret,
          copiedToClipboard: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
    event.preventDefault();
  }

  validateAutumnaKey(event) {
    this.setState({
      validationLoading: true,
      failedValidation: false
    });

    const id = this.props.id;

    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/validate_autumna_integration`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else if (response.status === 422) {
          errorsInResponse = true
          return response.json()
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({ errors: data })
        }
        this.setState({
          validated_secret_against_autumna: data.validated,
          failedValidation: !errorsInResponse && !data.validated,
          validationLoading: false
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
    event.preventDefault();
  }

  cancelAutumnaIntegration(event) {
    this.setState({cancelAutumnaIntegrationModal: false});

    const id = this.props.id;

    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/cancel_autumna_integration`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else if (response.status === 422) {
          errorsInResponse = true
          return response.json()
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({ errors: data })
        }
        this.setState({
          name: data.name,
          timezone: data.timezone,
          regulator_reference: data.regulator_reference,
          postcode: data.postcode,
          address: data.address,
          phone: data.phone,
          hidden: data.hidden,
          background_image: data.background_image,
          logo: data.logo,
          border_color: data.border_color || this.state.border_color,
          // Fallback to default values if `data.xxx` === null
          welcome_message: data.welcome_message || `Welcome to ${data.name}`,
          device_help_message: data.device_help_message || "Please select the type of person you are, or scan the QR code to continue. For help to know how to use a QR scanner please visit www.dreception.com/help.",
          arrival_thank_you_message: data.arrival_thank_you_message || "Thank you. Enjoy your stay.",
          departure_thank_you_message: data.departure_thank_you_message || "Thank you. Please visit again soon.",
          fire_logs_email: data.fire_logs_email,
          fire_log_access_pin: data.fire_log_access_pin,
          fire_log_access_pin_updated_at: data.fire_log_access_pin_updated_at,
          email_fire_log: data.email_fire_log,
          // escape from javascript string literal new line: "\n" at the end of encrypted strings
          autumna_shared_secret: data.autumna_shared_secret === null ? "" : data.autumna_shared_secret && data.autumna_shared_secret.replace(/\n/g, '\\n'),
          validated_secret_against_autumna: data.validated_secret_against_autumna,
          autumna_feedback_assigned_to_profiles_at_location: data.autumna_feedback_assigned_to_profiles_at_location,
          user_ids: data.user_ids
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
    event.preventDefault();
  }

  showAutumnaKey(event) {
    this.setState({showAutumnaKey: true})
    event.preventDefault();
  }

  openCancelAutumnaIntegrationModal(event) {
    this.setState({cancelAutumnaIntegrationModal: true});
    event.preventDefault();
  }

  closeModal(event) {
    this.setState({cancelAutumnaIntegrationModal: false});
    event.preventDefault();
  }

  renderAutumnaFields() {
    if (adminUser()) {
      if (this.state.showAutumnaKey) {
        return (
          <div className="row">
            <div>
              {this.renderAutumnaIntroduction()}
              {this.renderGenerateOrCancelButton()}
            </div>
            <HelpText page={'location'} section={'autumna_shared_secret'} />

            <label className="column">Shared Autumna Integration Key:</label>
            {this.renderCopied()}
            <input disabled className="column" type="text" name="autumna_shared_secret" value={this.state.autumna_shared_secret} />
            {this.renderValidateAutumnaKeyButton()}
          </div>
        )
      }
      else if (this.state.autumna_shared_secret) {
        return (
          <button className="button" onClick={this.showAutumnaKey}>Show Shared Autumna Integration Key</button>
        )
      }
      else {
        return (
          <>
            <HelpText page={'location'} section={'setup_autumna_integration'} />
            <button className="button" onClick={this.showAutumnaKey}>Set up Autumna Integration</button>
          </>
        )
      }
    }
  }

  renderAutumnaIntroduction() {
    if (!this.state.autumna_shared_secret) {
      return (
        <>
          <NoticeBox type="info">
            <div>
              <div>To set up an integration with Autumna for this Location, the following must happen (in order):</div>
              <ol>
                <li>Ensure this location's regulatory reference is saved as correct</li>
                <li>Generate the Autumna Integration Key – doing this will create a Shared Autumna Integration Key</li>
                <li>Enter the shared secret key into your profile on Autumna, or ask Autumna to do this for you</li>
                <li>Once the shared secret is entered into Autumna, validate the key by clicking the button.</li>
                <li>Once validated, you can add the Autumna Feedback Type to your visitor profiles.</li>
              </ol>
              <div>After these steps have been followed, visitor feedback will be automatically sent to Autumna.</div>
            </div>
          </NoticeBox>
        </>
      )
    }
  }

  renderValidateAutumnaKeyButton() {
    if (adminUser()) {
      const text = this.state.validated_secret_against_autumna ? "Re-Validate Autumna Integration" : "Validate Autumna Integration"
      return (
        <>
          <HelpText page={'location'} section={'autumna_validation'} />
          { this.renderErrors('autumna_validation') }

          <button className="button" disabled={this.state.validationLoading || !this.state.autumna_shared_secret} onClick={this.validateAutumnaKey}>
            {text}<LoadingSpinner condition={this.state.validationLoading} />
          </button>
          {this.renderFailedValidation()}
        </>
      )
    }
  }

  renderFailedValidation() {
    if (this.state.failedValidation) {
      return (
        <NoticeBox type="warning">
          <strong>  Validation failed!</strong>
          <div>This is most likely because the Autumna Shared Secret Key hasn't been shared with Autumna. If you're sure this has been done, make sure that you are using the correct Regulatory Reference number in Digital Reception and that it has been saved as correct, then try validating again.</div>
        </NoticeBox>
      )
    }
  }

  renderGenerateOrCancelButton() {
    if (adminUser()) {
      if (this.state.autumna_shared_secret) {
        return (
          <>
            {this.renderCancelAutumnaIntegrationModal()}
            <button className="button" onClick={this.openCancelAutumnaIntegrationModal}>Cancel Autumna Integration</button>
          </>
        )
      }
      else {
        return (
          <button className="button" onClick={this.generateAutumnaKey}>Generate Autumna Integration Key</button>
        )
      }
    }
  }

  renderCopied() {
    if (this.state.copiedToClipboard) {
      return (
        <div className="float-right">Copied to clipboard!</div>
        )
    }
  }

  renderCancelAutumnaIntegrationModal() {
    if (this.state.cancelAutumnaIntegrationModal) {
      const noticeType = this.state.validated_secret_against_autumna ? "warning" : "info"
      const text = this.state.validated_secret_against_autumna ?
        "This will disrupt an already validated & active Autumna integration. Autumna will not receive any more feedback from this Location until this connection is re-established!" :
        "You can re-establish an Autumna Feedback integration at any future time of your choosing."
      return (
        <div className="modal-container">
          <div className="modal-content">
            <label className="column">Are you sure you want to cancel this Location's Autumna integration?</label>
            <NoticeBox type={noticeType} text={text}/>

            <button className="modal-button" value="yes" onClick={this.cancelAutumnaIntegration}>Yes</button>
            <button className="modal-button" value="no" onClick={this.closeModal}>No</button>
          </div>
        </div>
      )
    }
  }

  renderAutumnaReadiness() {
    if (adminUser() && this.state.autumna_shared_secret) {
      if (this.state.validated_secret_against_autumna && this.state.autumna_feedback_assigned_to_profiles_at_location) {
        return (
          <NoticeBox type={"info"}>
            <div>
              <div>Autumna Integration is fully active <span role="img" aria-label="checkmark">✅</span></div>
              <div>Autumna Feedback given to Profiles assigned to Devices at this Location will be saved to the Live Ratings section of Autumna's review site for this Location.</div>
            </div>
          </NoticeBox>
        )
      }
      else {
        let warnings = [];
        !this.state.validated_secret_against_autumna && warnings.push("The Autumna Shared Secret Key's connection with Autumna still needs to be validated. You may still need to share it with Autumna if validation fails.")
        !this.state.autumna_feedback_assigned_to_profiles_at_location && warnings.push("The Autumna Feedback Type still needs to be assigned to a Profile used on Devices at this Location.")

        return (
          <NoticeBox type="info">
          <div>
            <div>Autumna Integration is not fully active <span role="img" aria-label="X">❌</span></div>
            <div>In order to send Feedback to the Live Ratings section of Autumna's review site for this Location you need to do the following:</div>
            <ul>
              {warnings.map((warning, index) => (
                <li key={index}>{warning}</li>
              ))}
            </ul>
            </div>
          </NoticeBox>
        )
      }
    }
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderAppliedToAllLocations(field) {
    if (this.state.applied_to_all_locations.includes(field)) {

      let capitalCaseField = spaceAndCapitalize(field)

      return (
        <div className="green-text inline-block margin-left">{`The current ${capitalCaseField} has been applied to all Locations`}</div>
      )
    }
  }

  loadBackgroundImageBox(background_image) {
    if (background_image === null) {
      return (
        <div>
          <input className="column" type="file" name="upload_background_image_file" onChange={this.handleFileUpload} />
          { this.renderUploadToAllLocationsButton("background_image") }
        </div>
      )
    }
    else {
      return (
        <div className="inline-image">
          <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${background_image}`} alt={`${this.state.name} Background`}></img>

          <div>
            <div className="small button" onClick={this.clearBackgroundImageUpload}>Clear image</div>
            <div className="small button" onClick={this.attachImageToAllLocations} name="background_image">Apply Background Image to all Locations</div>{ this.renderAppliedToAllLocations('background_image') }
          </div>
        </div>
      )
    }
  }

  loadLogoBox(logo) {
    if (logo === null) {
      return (
        <div>
          <input className="column" type="file" name="upload_logo_file" onChange={this.handleFileUpload} />
          { this.renderUploadToAllLocationsButton("logo") }
        </div>
      )
    }
    else {
      return (
        <div className="inline-image">
          <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${logo}`} alt={`${this.state.name} Logo`}></img>

          <div>
            <div className="small button" onClick={this.clearLogoUpload}>Clear image</div>
            <div className="small button" onClick={this.attachImageToAllLocations} name="logo">Apply Logo to all Locations</div>{ this.renderAppliedToAllLocations('logo') }
          </div>
        </div>
      )
    }
  }

  renderUploadToAllLocationsButton(field) {
    if (this.state[`upload_${field}_file`]){
      const writtenField = spaceAndCapitalize(field)
      return (
        <>
          <div className="small button bottom-margin" onClick={this.applyToAllLocations} name={field}>Apply {writtenField} to all Locations</div>
          { this.renderAppliedToAllLocations(field) }
        </>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderFireLogUsersSelection(assigned_users, fire_log_user_ids) {
    if (assigned_users.length > 0) {
      return (
        <div className="scrollable-table">
          <table className="checkbox-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Send Emergency Fire Log Emails?</th>
              </tr>
            </thead>
            <tbody>
              {assigned_users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Link to={`/settings/users/${user.id}`}>{user.first_name} {user.last_name}</Link>
                  </td>
                  <td>
                    <Link to={`/settings/users/${user.id}`}>{user.email}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={user.email} name={user.id} data-key="fire_log_user_ids" checked={fire_log_user_ids.includes(user.id)} onChange={this.handleMultiCheckbox} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return (
        <NoticeBox type={"info"} text={"Only assigned Users can be set to be informed of emergency fire log use!"} />
      )
    }
  }

  render() {

    const { name, timezone, regulator_reference, postcode, address, phone, hidden, timezones, timezonesLoaded, background_image, logo, border_color, border_preview, welcome_message, device_help_message, text_on_select, arrival_thank_you_message, departure_thank_you_message, fire_logs_email, fire_log_access_pin, fire_log_access_pin_updated_at, email_fire_log, user_ids, fire_log_user_ids, users, partial, searchedUsers, validationLoading, loaded, usersLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || timezonesLoaded === false || usersLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && timezonesLoaded && usersLoaded) {
      const className = validationLoading ? "loading" : ""
      const baseLicense = localStorage.license === "base"

      return (
        <div className={className}>
          <SetTitle title={`Edit Location | ${name} | Locations`} />

          <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'name'} />

                <label className="column">Location Name:</label>{ this.renderErrors('name') }
                <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'timezone'} />
                <label className="column">Timezone:</label>{ this.renderErrors('timezone') }
                <select name="timezone" className="small-bottom-margin" onChange={this.handleChange} value={timezone}>
                  <option value="">Select</option>
                  {timezones.map((timezone) => (
                    <option value={timezone} key={timezone}>{timezone}</option>
                  ))}
                </select>
                <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="timezone">Apply Timezone to all Locations</div>{ this.renderAppliedToAllLocations('timezone') }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'regulatory'} />

                <label className="column">Regulatory Reference:</label>{ this.renderErrors('regulator_reference') }
                <input className="column" type="text" name="regulator_reference" value={regulator_reference} onChange={this.handleChange} />
              </div>

              {this.renderAutumnaFields()}
              {this.renderAutumnaReadiness()}

              <div className="row">
                <HelpText page={'location'} section={'postcode'} />

                <label className="column">Postcode:</label>{ this.renderErrors('postcode') }
                <input className="column" type="text" name="postcode" value={postcode} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'address'} />

                <label className="column">Address:</label>{ this.renderErrors('address') }
                <textarea className="column" type="text" name="address" value={address} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'phone'} />

                <label className="column">Phone Number:</label>{ this.renderErrors('phone') }
                <input className="column" type="text" name="phone" value={phone} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'users'} />

                <label className="column">Users:</label>{ this.renderErrors('user') }
                <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={partial} />

                <div className="scrollable-table">
                  <table className="checkbox-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Allow Access?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(searchedUsers || users).map((user) => (
                        <tr key={user.id}>
                          <td>
                            <Link to={`/settings/users/${user.id}`}>{user.first_name} {user.last_name}</Link>
                          </td>
                          <td>
                            <Link to={`/settings/users/${user.id}`}>{user.email}</Link>
                          </td>
                          <td className="center-cell">
                            <input className="column" type="checkbox" data-name={user.email} name={user.id} data-key="user_ids" checked={user_ids.includes(user.id)} onChange={this.handleMultiCheckbox} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <HelpText page={'location'} section={'hidden'} />

                <input className="column" type="checkbox" name="hidden" id="hidden_checkbox" checked={hidden} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="hidden_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
              </div>
            </div>

            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'background_image'} />

                <label className="column checkbox-label">Background Image:</label>{ this.renderErrors('background_image') }
                { this.loadBackgroundImageBox(background_image) }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'logo'} />

                <label className="column checkbox-label">Logo:</label>{ this.renderErrors('logo') }
                { this.loadLogoBox(logo) }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'border_color'} />

                <label className="column">Device Border Colour:</label>{ this.renderErrors('border_color') }
                <input className="column" type="text" name="border_color" value={border_color} onChange={this.handleBorderColorChange} />
                <div className="color-preview" style={{ backgroundColor: border_preview }}/>
                <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="border_color">Apply Border Colour to all Locations</div>{ this.renderAppliedToAllLocations('border_color') }
              </div>
            </div>

            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'welcome'} />

                <label className="column">Welcome Message:</label>{ this.renderErrors('welcome_message') }
                <input className="column small-bottom-margin" type="text" name="welcome_message" value={welcome_message} onChange={this.handleChange} />
                <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="welcome_message">Apply Welcome Message to all Locations</div>{ this.renderAppliedToAllLocations('welcome_message') }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'help'} />

                <label className="column">Device Help Message:</label>{ this.renderErrors('device_help_message') }
                <textarea aria-required="true" name="device_help_message" value={device_help_message} onChange={this.handleChange} />
                <div className="small button" onClick={this.applyToAllLocations} name="device_help_message">Apply Device Help Message to all Locations</div>{ this.renderAppliedToAllLocations('device_help_message') }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'text_on_select'} />

                <label className="column">Text on Select:</label>{ this.renderErrors('text_on_select') }
                <textarea aria-required="true" name="text_on_select" value={text_on_select} onChange={this.handleChange} />
                <div className="small button" onClick={this.applyToAllLocations} name="text_on_select">Apply Text on Select to all Locations</div>{ this.renderAppliedToAllLocations('text_on_select') }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'arrival'} />

                <label className="column">Thank You Message on Arrival:</label>{ this.renderErrors('arrival_thank_you_message') }
                <input className="column small-bottom-margin" type="text" name="arrival_thank_you_message" value={arrival_thank_you_message} onChange={this.handleChange} />
                <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="arrival_thank_you_message">Apply Thank You Message on Arrival to all Locations</div>{ this.renderAppliedToAllLocations('arrival_thank_you_message') }
              </div>

              <div className="row">
                <HelpText page={'location'} section={'leaving'} />

                <label className="column">Thank You Message on Leaving:</label>{ this.renderErrors('departure_thank_you_message') }
                <input className="column small-bottom-margin" type="text" name="departure_thank_you_message" value={departure_thank_you_message} onChange={this.handleChange} />
                <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="departure_thank_you_message">Apply Thank You Message on Leaving to all Locations</div>{ this.renderAppliedToAllLocations('departure_thank_you_message') }
              </div>
            </div>

            {baseLicense && (
              <div className="segment">
                <div className="row">
                  <HelpText page={'location'} section={'fire_logs_email'} />

                  <label className="column">Fire Log Results Email:</label>{ this.renderErrors('fire_logs_email') }
                  <input className="column small-bottom-margin" type="text" name="fire_logs_email" value={fire_logs_email} onChange={this.handleChange} />
                  <div className="small button bottom-margin" onClick={this.applyToAllLocations} name="fire_logs_email">Apply Fire Log Results Email to all Locations</div>{ this.renderAppliedToAllLocations('fire_logs_email') }
                </div>

                <div className="row">
                  <HelpText page={'location'} section={'fire_log_access_pin'} />

                  <label className="column">Fire Log Access Pin for Devices:</label>{ this.renderErrors('fire_log_access_pin') }
                  <input className="column" type="text" name="fire_log_access_pin" value={fire_log_access_pin} placeholder="Enter a 4-digit PIN" onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'location'} section={'fire_log_access_pin_updated_at'} />
                  <label className="column">Fire Log Access Pin last updated at:</label>
                  <label className="rightcolumn" type="fire_log_access_pin_updated_at" name="fire_log_access_pin_updated_at">{fire_log_access_pin_updated_at ? fire_log_access_pin_updated_at : "Access Pin not yet updated."}</label>
                </div>

                <div className="row small-top-padding">
                  <HelpText page={'location'} section={'fire_log_users'} />

                  <label className="column">Users informed of emergency fire log use:</label>{ this.renderErrors('fire_log_user') }
                  <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={partial} />
                  {this.renderFireLogUsersSelection((searchedUsers || users).filter(user => user_ids.includes(user.id)), fire_log_user_ids)}
                </div>

                <div className="row">
                  <HelpText page={'location'} section={'email_fire_log'} />

                  <input className="column" type="checkbox" name="email_fire_log" id="email_fire_log_checkbox" checked={email_fire_log} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="email_fire_log">Include fire log in emergency link emails?</label>{ this.renderErrors('email_fire_log') }
                </div>
              </div>
            )}

            <div className="segment">
              { this.renderSaveButton() }
              { renderErrorWarning(errors) }
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          name: data.name,
          timezone: data.timezone,
          regulator_reference: data.regulator_reference,
          postcode: data.postcode,
          address: data.address,
          phone: data.phone,
          hidden: data.hidden,
          background_image: data.background_image,
          logo: data.logo,
          border_color: data.border_color || this.state.border_color,
          // Fallback to default values if `data.xxx` === null
          welcome_message: data.welcome_message || `Welcome to ${data.name}`,
          device_help_message: data.device_help_message || "Please select the type of person you are, or scan the QR code to continue. For help to know how to use a QR scanner please visit www.dreception.com/help.",
          text_on_select: data.text_on_select,
          arrival_thank_you_message: data.arrival_thank_you_message || "Thank you. Enjoy your stay.",
          departure_thank_you_message: data.departure_thank_you_message || "Thank you. Please visit again soon.",
          fire_logs_email: data.fire_logs_email,
          fire_log_access_pin: data.fire_log_access_pin,
          fire_log_access_pin_updated_at: data.fire_log_access_pin_updated_at,
          email_fire_log: data.email_fire_log,
          // escape from javascript string literal new line: "\n" at the end of encrypted strings
          autumna_shared_secret: data.autumna_shared_secret && data.autumna_shared_secret.replace(/\n/g, '\\n'),
          validated_secret_against_autumna: data.validated_secret_against_autumna,
          autumna_feedback_assigned_to_profiles_at_location: data.autumna_feedback_assigned_to_profiles_at_location,
          user_ids: data.user_ids,
          fire_log_user_ids: data.fire_log_user_ids,
          loaded: true
        })
        if (this.validateBorderColor(data.border_color)) {
          this.setState({border_preview: data.border_color})
        }
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/list_of_timezones`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ timezones: data.timezones, timezonesLoaded: true })
      })
      .catch(error => this.setState({ error, timezonesLoaded: true }))  

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ users: data, usersLoaded: true })
      })
      .catch(error => this.setState({ error, usersLoaded: true }))
  }
}

export default UpdateLocation;
