import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import UpdateWorkingPattern from '../../components/staff-fulfilment/UpdateWorkingPattern';

class StaffFulfilmentConfigurationIndex extends Component {
  constructor(props) {
    super(props);

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    roles: [],
    location: null,
    tabIndex: 0,

    editRole: null,
    editPanelToggle: false,

    rolesLoaded: false,
    locationLoaded: false,
    errors: ""
  }

  handleTabChange(index) {
    this.setState({ tabIndex: index });
  }

  handleEditPanel(event) {
    let editRole = this.state.roles.find(role => role.id === parseInt(event.currentTarget.id))

    this.setState({
      editRole: editRole,
      editPanelToggle: true
    })

    event.preventDefault();
  }

  closeEditPanel() {
    this.setState({
      editRole: null,
      editPanelToggle: false
    })

    this.componentDidMount()
  }

  renderErrors() {
    if (this.state.errors) {
      let array = []
      for (let key in this.state.errors) {
          array = array.concat(this.state.errors[key])
      }
      return(
        array.map((item, index) => (
          <label key={index} className="column red-text">{item}</label>
        ))
      )
    }
  }

  renderRole(role, index) {
    const inheritedFromLocation = role.rota_start_date === null && this.state.location.rota_start_date ? <div className="small-top-padding"><em>Inherited from Location configuration</em></div> : "";

    let rota_start_date = <div>Default</div>;
    if (role.rota_start_date || this.state.location.rota_start_date) {
      rota_start_date = <input type="date" value={role.rota_start_date || this.state.location.rota_start_date} disabled={true} />
    }

    const default_period_weeks = role.default_period_weeks || this.state.location.default_period_weeks || 1;
    const divisor = index === 0 ? "" : <div className="divisor-line" />;

    return (
      <React.Fragment key={role.id}>
        {divisor}
        <div className="role-space" key={role.id}>
          <Link to={`/configuration/roles/${role.id}`}><h4>{role.name}</h4></Link>
          <div className="indented-content">
            <div className="divided-columns">
              <div>Working pattern</div>
              <input type="text" value={`${default_period_weeks} week${default_period_weeks > 1 ? "s" : ""}`} disabled={true} />
              <div>Start date</div>
              {rota_start_date}
            </div>
            {inheritedFromLocation}
            <div className= "small button small-top-padding" id={role.id} onClick={this.handleEditPanel}>Change</div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { roles, location, rolesLoaded, locationLoaded, tabIndex, editRole, editPanelToggle, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (rolesLoaded === false || locationLoaded === false) {
      return (
        <div>Loading...</div>
      )
    }

    if (rolesLoaded && locationLoaded) {
      let location_rota_start_date = <div>Default</div>;
      if (location.rota_start_date) {
        location_rota_start_date = <input type="date" value={location.rota_start_date} disabled={true} />
      }

      return (
        <div>
          <div className="staff-fulfilment-config-explainer">By default our charts are set to a one week working pattern. You can set a custom duration here, with a choice of up to four weeks. This can be done at the Location or Role level, with Role taking precedence over Location when both are set.</div>
          <Tabs selectedIndex={tabIndex} onSelect={index => this.handleTabChange(index)}>
            <TabList>
              <Tab>Location</Tab>
              <Tab>Roles</Tab>
            </TabList>

            <TabPanel>
              <Link to={`/locations/${this.props.location_id}`}><h4>{location.name || "Location"}</h4></Link>
              <div className="indented-content">
                <div className="divided-columns">
                  <div>Working pattern</div>
                  <input type="text" value={`${location.default_period_weeks || 1} week${(location.default_period_weeks || 1) > 1 ? "s" : ""}`} disabled={true} />
                  <div>Start date</div>
                  {location_rota_start_date}
                </div>
                <div className="small button small-top-padding" onClick={this.handleEditPanel}>Change</div>
              </div>
            </TabPanel>

            <TabPanel>
              {roles.map((role, index) => (
                this.renderRole(role, index)
              ))}
            </TabPanel>
          </Tabs>

          <SlidingPane isOpen={editPanelToggle} title={`Edit ${editRole ? "Role" : "Location"} Working Pattern`} width="60%" onRequestClose={this.closeEditPanel}>
            <UpdateWorkingPattern editRole={editRole} location_id={this.props.location_id} location_default_period_weeks={location.default_period_weeks} location_rota_start_date={location.rota_start_date} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/role_configurations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/location_configuration?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location: data, locationLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))
  }
}

export default StaffFulfilmentConfigurationIndex;
