import React, { Component } from 'react';

import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import BigLogo from '../../components/header/BigLogo';
import SetTitle from '../../components/shared/SetTitle';

class ForgottenPassword extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    emailAddress: this.props.location.state === undefined ? "" : this.props.location.state.emailAddress,
    resettingPassword: false,
    updated: false,

    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    this.setState({resettingPassword: true})
    this.setState({errors: ""})
    this.setState({error: ""})

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "password_reset": {
        "email_address": this.state.emailAddress,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/request_password_reset`, requestOptions)
    .then(response => {
      this.setState({resettingPassword: false})

      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ updated: true, errors: "" })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderErrors() {
    if (this.state.errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {this.state.errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }
  }

  renderUpdated() {
    if (this.state.updated) {
      return (
        <div className="login-form forgotten-password">
          <p className="success">Thank you, your password reset email has been sent successfully.</p>
        </div>
      )
    }
  }

  renderForm() {
    if (this.state.updated === false) {
      return (
        <div className="login-form forgotten-password">
          <p>If you have forgotten your password, you may reset it below.</p>
          <p>Please enter your email address, and then follow the link we send you.</p>
          <form onSubmit={this.handleSubmit}>
            <label>
              <input placeholder=" email address" aria-required="true" type="email" name="emailAddress" value={this.state.emailAddress} onChange={this.handleChange} />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </form>

          {this.resettingPasswordMessage(this.state)}

          { this.renderErrors() }
        </div>
      )
    }
  }

  resettingPasswordMessage(state) {
    if (state.resettingPassword) {
      return (
        <div>
          Resetting password
          <span className="blink1">.</span>
          <span className="blink2">.</span>
          <span className="blink3">.</span>
        </div>
      )
    }
  }

  render() {

    return (
      <div className="login-page">
        <SetTitle title={"Forgotten Password"} />
        <LoggedOutHeader />

        <div className="center-container">
          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            {this.renderForm()}
            {this.renderUpdated()}
          </div>

        </div>

      </div>
    );
  }
}

export default ForgottenPassword;
