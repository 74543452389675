import React, { Component } from 'react';

import { docExtensionsArray, videoExtensionsArray, pdfExtensionsArray, imageExtensionsArray, csvExtensionsarray } from '../../utilities/Generic.js'

import { faFileWord, faFileVideo, faFilePdf, faFileImage, faFileExcel, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FileLink extends Component {
  render() {
    const { href, fontSize, title } = this.props;

    let icon = faFileAlt;
    if (href) {
      const extension = this.props.href.split('.').pop().toLowerCase();

      if (docExtensionsArray.includes(extension)) {
        icon = faFileWord
      }
      else if (videoExtensionsArray.includes(extension)) {
        icon = faFileVideo
      }
      else if (pdfExtensionsArray.includes(extension)) {
        icon = faFilePdf
      }
      else if (imageExtensionsArray.includes(extension)) {
        icon = faFileImage
      }
      else if (csvExtensionsarray.includes(extension)) {
        icon = faFileExcel
      }
    }

    return (
      <a href={href} style={{fontSize}} title={title}>
        <FontAwesomeIcon icon={icon} />
      </a>
    );
  }
}

export default FileLink;
