import React, { Component } from 'react';
import Header from '../../components/header/Header';
import ListOfBulletins from '../../components/bulletins/ListOfBulletins';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class BulletinsIndex extends Component {
  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Bulletins"} />
        <PopupBox />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Bulletins</h2>
          <OrganisationName />

          <div>
            <ListOfBulletins id={params.id} />
          </div>
        </div>

      </div>
    );
  }
}

export default BulletinsIndex;
