import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';

import FeedbackByPeople from '../../components/analysis/charts/FeedbackByPeople';
import FeedbackByLocation from '../../components/analysis/charts/FeedbackByLocation';
import ActiveHours from '../../components/analysis/charts/ActiveHours';
import FeedbackAnswers from '../../components/analysis/FeedbackAnswers';
import OutstandingFeedbackByLocation from '../../components/analysis/charts/OutstandingFeedbackByLocation';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import { showExcludedFeedbackTypes } from '../../utilities/Generic.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class AnalysisIndex extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    date_from: null,
    date_to: null,
    min_date: this.formatDate(new Date(), 90),
    max_date: this.formatDate(new Date(), 0),
    feedback: "",
    refresh_date_from: this.formatDate(new Date(), 7),
    refresh_date_to: this.formatDate(new Date(), 0),
    refresh_feedback: "",
    refresh_location: "",
    key: 0,

    feedbackTypes: [],
    excludedFeedbackTypes: [],
    feedbackTypesLoaded: false,
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random()
    });

    let tab = this.props.match.params.tab || "overview"

    if (tab === "overview") {
      if (name === "date_from") {
        this.props.history.push(`/analysis/overview?from=${value}&to=${this.state.date_to}&location=${this.state.location}`);
      }
      else if (name === "date_to") {
        this.props.history.push(`/analysis/overview?from=${this.state.date_from}&to=${value}&location=${this.state.location}`);
      }
      else if (name === "location") {
        this.props.history.push(`/analysis/overview?from=${this.state.date_from}&to=${this.state.date_to}&location=${value}`);
      }
    }
    else if (tab === "feedback") {
      if (name === "date_from") {
        this.props.history.push(`/analysis/feedback?from=${value}&to=${this.state.date_to}&feedback=${this.state.feedback}&location=${this.state.location}`);
      }
      else if (name === "date_to") {
        this.props.history.push(`/analysis/feedback?from=${this.state.date_from}&to=${value}&feedback=${this.state.feedback}&location=${this.state.location}`);
      }
      else if (name === "feedback") {
        this.props.history.push(`/analysis/feedback?from=${this.state.date_from}&to=${this.state.date_to}&feedback=${value}&location=${this.state.location}`);
      }
      else if (name === "location") {
        this.props.history.push(`/analysis/feedback?from=${this.state.date_from}&to=${this.state.date_to}&feedback=${this.state.feedback}&location=${value}`);
      }
    }
  }

  handleTabChange(index) {
    if (index === 0) {
      this.props.history.push(`/analysis/overview?from=${this.state.date_from}&to=${this.state.date_to}&location=${this.state.location}`);
    }
    else if (index === 1) {
      this.props.history.push(`/analysis/feedback?from=${this.state.date_from}&to=${this.state.date_to}&feedback=${this.state.feedback}&location=${this.state.location}`);
    }

    this.setState({ tabIndex: index });
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  nameOf(location) {
    return this.state.locations.find(item => item.id.toString() === location)?.name || null
  }

  render() {
    const { date_from, date_to, min_date, max_date, feedback, location, tabIndex, feedbackTypes, feedbackTypesLoaded, locations, locationsLoaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (this.state.key === 0 || feedbackTypesLoaded === false || locationsLoaded === false) {
      return (
        <div>
          <SetTitle title={"Analysis"} />
          <Header />

          <div className="main-page analysis-page">
            <h2 className="page-title">Analysis</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    if (this.state.key !== 0 && feedbackTypesLoaded === true && locationsLoaded === true) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <SetTitle title={"Analysis"} />
          <Header />

          <div className="main-page analysis-page">
            <h2 className="page-title no-print">Analysis</h2>
            <OrganisationName className="no-print" />

            <Tabs selectedIndex={tabIndex} onSelect={index => this.handleTabChange(index)}>
              <TabList className="no-print">
                <Tab>Overview</Tab>
                <Tab>Feedback</Tab>
              </TabList>

              <TabPanel>
                <div className="date-selection">
                  <label className="column">Date from:</label>
                  <input className="column" type="date" min={min_date} max={max_date} name="date_from" value={date_from} onChange={this.handleChange} />

                  <label className="column">Date to:</label>
                  <input className="column" type="date" min={min_date} max={max_date} name="date_to" value={date_to} onChange={this.handleChange} />

                  <label className="column">Location:</label>
                  <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                    <option value="">All</option>
                    {locations.map((location) => (
                      <option value={location.id} key={location.id}>{location.name}</option>
                    ))}
                  </select>
                </div>

                <div className="widgets">
                  <FeedbackByPeople person={"visitor"} date_from={date_from} date_to={date_to} key={this.state.key+1} location_id={location} />

                  {baseLicense && (
                    <>
                      <FeedbackByPeople person={"staff"} date_from={date_from} date_to={date_to} key={this.state.key+2} location_id={location} />
                      <FeedbackByPeople person={"resident"} date_from={date_from} date_to={date_to} key={this.state.key+3} location_id={location} />
                      <FeedbackByPeople person={"contractor"} date_from={date_from} date_to={date_to} key={this.state.key+4} location_id={location} />
                      <FeedbackByPeople person={"industry professional"} date_from={date_from} date_to={date_to} key={this.state.key+5} location_id={location} />
                      <FeedbackByPeople person={"other"} date_from={date_from} date_to={date_to} key={this.state.key+6} location_id={location} />
                    </>
                  )}

                  <FeedbackByLocation date_from={date_from} date_to={date_to} key={this.state.key + 7} />
                  <ActiveHours date_from={date_from} date_to={date_to} key={this.state.key + 8} location_name={this.nameOf(location)} />
                  { baseLicense && <OutstandingFeedbackByLocation /> }
                </div>
                {showExcludedFeedbackTypes(this)}
              </TabPanel>

              <TabPanel>
                <div className="date-selection">
                  <label className="column">Date from:</label>
                  <input className="column" type="date" min={min_date} max={max_date} name="date_from" value={date_from} onChange={this.handleChange} />

                  <label className="column">Date to:</label>
                  <input className="column" type="date" min={min_date} max={max_date} name="date_to" value={date_to} onChange={this.handleChange} />

                  <label className="column">Feedback:</label>
                  <select name="feedback" value={feedback} onChange={this.handleChange} data-testid="select-feedbacks">
                    <option value="">Select</option>
                    {feedbackTypes.map((feedback) => (
                      <option value={feedback.id} key={feedback.id}>{feedback.name}</option>
                    ))}
                  </select>

                  <label className="column">Location:</label>
                  <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                    <option value="">All</option>
                    {locations.map((location) => (
                      <option value={location.id} key={location.id}>{location.name}</option>
                    ))}
                  </select>
                </div>

                <FeedbackAnswers date_from={date_from} date_to={date_to} feedback={feedback} location_id={location} key={this.state.key + 9} />
              </TabPanel>
            </Tabs>
          </div>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "") {
      let tab = props.match.params.tab || "overview"
      if (tab === "overview") {
        props.history.replace(`/analysis/overview?from=${state.refresh_date_from}&to=${state.refresh_date_to}&location${state.refresh_location}`);
      }
      else {
        props.history.replace(`/analysis/feedback?from=${state.refresh_date_from}&to=${state.refresh_date_to}&feedback=${state.refresh_feedback}&location=${state.refresh_location}`);
      }

      var tabIndex = 0
      if (tab === "overview") {
        tabIndex = 0
      }
      else if (tab === "feedback") {
        tabIndex = 1
      }

      return {
        date_from: state.refresh_date_from,
        date_to: state.refresh_date_to,
        feedback: state.refresh_feedback,
        location: state.refresh_location,
        tabIndex: tabIndex,
        key: Math.random()
      }
    }
    else {
      // handle back/forward buttons
      if (props.history.action === "POP" && props.history.location.search !== "") {
        const params = new URLSearchParams(props.history.location.search)
        let date_from = params.get('from')
        let date_to = params.get('to')
        let feedback = params.get('feedback')
        let location = params.get('location')

        if (props.match.params.tab === "overview") {
          tabIndex = 0
        }
        else if (props.match.params.tab === "feedback") {
          tabIndex = 1
        }

        return {
          date_from: date_from,
          date_to: date_to,
          feedback: feedback,
          location: location,
          tabIndex: tabIndex
        }
      }
      else {
        return null
      }
    }
  }

  deepLinkPath(){
    const { match: { params } } = this.props;

    let path = ""

    if (params.chart !== undefined || params.context !== undefined) {
      path = `/${params.chart}/${params.context}`
    }

    if (path && params.context2 !== undefined) {
      path += `/${params.context2}`
    }

    return path
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      var date_from = params.get('from') || this.formatDate(new Date(), 7)
      var date_to = params.get('to') || this.formatDate(new Date(), 0)
      var feedback = params.get('feedback') || ""
      var location = params.get('location') || ""

      // set history in case one of the params was missing
      let tab = this.props.match.params.tab || "overview"
      if (tab === "overview") {
        this.props.history.replace(`/analysis/overview${this.deepLinkPath()}?from=${date_from}&to=${date_to}&location=${location}`);
      }
      else {
        this.props.history.replace(`/analysis/feedback${this.deepLinkPath()}?from=${date_from}&to=${date_to}&feedback=${feedback}&location=${location}`);
      }

      var tabIndex = 0
      if (tab === "overview") {
        tabIndex = 0
      }
      else if (tab === "feedback") {
        tabIndex = 1
      }

      this.setState({
        date_from: date_from,
        date_to: date_to,
        feedback: feedback,
        location: location,
        tabIndex: tabIndex,
        key: Math.random()
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ feedbackTypes: data, feedbackTypesLoaded: true })
      })
      .catch(error => this.setState({ error, feedbackTypesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/excluded_from_analysis`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ excludedFeedbackTypes: data, excludedFeedbackTypesLoaded: true })
      })
      .catch(error => this.setState({ error, excludedFeedbackTypesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default AnalysisIndex;
