import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, Pie, getElementAtEvent } from 'react-chartjs-2';

import EventsPanel from '../../../components/shared/EventsPanel';

import { spaceAndCapitalize } from '../../../utilities/Forms.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class FeedbackAnswer extends Component {
  static QUESTION_TYPES_WITH_ANSWERS = {
    "boolean": [
      "yes",
      "no",
    ],
    "stars": [
      "one_star",
      "two_stars",
      "three_stars",
      "four_stars",
      "five_stars",
    ],
    "smileys": [
      "very_unhappy",
      "unhappy",
      "neutral",
      "happy",
      "very_happy",
    ],
    "text": [
      "text"
    ]
  };

  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const element = getElementAtEvent(this.barGraph.current, event)[0]
    const index = this.props.answer.type === "boolean" ? element.index : element.datasetIndex
    this.openPanel(index)
  }

  openPanel(index) {
    let panelTitleOverride;

    if (this.props.answer.quantity === 4 && index > 1) {
      panelTitleOverride = FeedbackAnswer.QUESTION_TYPES_WITH_ANSWERS[this.props.answer.type][index]
      index++
    }

    let type = FeedbackAnswer.QUESTION_TYPES_WITH_ANSWERS[this.props.answer.type][index]

    this.setState({
      infoPanel: true,
      panelTitleOverride: panelTitleOverride,
      type: type
    })

    this.props.history.push(`/analysis/feedback/feedback_answer/${this.props.index}/${panelTitleOverride || type}${this.props.location.search}`)
  }

  state = {
    answers: [],
    infoPanel: false,
    type: null,
    questionIndex: null,
  };

  starsAnswer() {
    const data = {
      labels: ["results"],
      datasets: [{
        label: "1 Star",
        data: [this.props.answer.one.length],
        backgroundColor: '#ce5252',
      }, {
        label: "2 Stars",
        data: [this.props.answer.two.length],
        backgroundColor: '#f29e32',
      }, {
        label: "3 Stars",
        data: [this.props.answer.three.length],
        backgroundColor: '#eee622',
      }, {
        label: this.props.answer.quantity === 4 ? "3 Stars" : "4 Stars",
        data: [this.props.answer.four.length],
        backgroundColor: '#cbee22',
      }, {
        label: this.props.answer.quantity === 4 ? "4 Stars" : "5 Stars",
        data: [this.props.answer.five.length],
        backgroundColor: '#22ee80'
      }]
    }

    if (this.props.answer.quantity === 4) {
      data.datasets.splice(2, 1)
    }

    const options = {
      legend: {
        position: 'top',
      },
      scales: {
        yAxes: {
          ticks: {
            beginAtZero: true
          }
        }
      }
    }

    return (
      <div className="widget" key={this.props.answer.question}>
        <h3>{this.props.answer.question}</h3>
        <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
      </div>
    );
  }

  smileysAnswer() {
    const data = {
      labels: ["results"],
      datasets: [{
        label: "Very unhappy",
        data: [this.props.answer.very_unhappy.length],
        backgroundColor: '#ce5252',
      }, {
        label: "Unhappy",
        data: [this.props.answer.unhappy.length],
        backgroundColor: '#f29e32',
      }, {
        label: "Neutral",
        data: [this.props.answer.neutral.length],
        backgroundColor: '#eee622',
      }, {
        label: "Happy",
        data: [this.props.answer.happy.length],
        backgroundColor: '#cbee22',
      }, {
        label: "Very happy",
        data: [this.props.answer.very_happy.length],
        backgroundColor: '#22ee80',
      }]
    }

    if (this.props.answer.quantity === 4) {
      data.datasets.splice(2, 1)
    }

    const options = {
      legend: {
        position: 'top',
      },
      scales: {
        yAxes: {
          ticks: {
            beginAtZero: true
          }
        }
      }
    }

    return (
      <div className="widget" key={this.props.answer.question}>
        <h3>{this.props.answer.question}</h3>
        <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
      </div>
    );
  }

  booleanAnswer() {
    const data = {
      labels: ['Yes', 'No'],
      datasets: [{
        data: [
          this.props.answer.yes.length,
          this.props.answer.no.length
        ],
        backgroundColor: ['#22ee80', '#ce5252']
      }],
    }

    const options = {}

    return (
      <div className="widget" key={this.props.answer.question}>
        <h3>{this.props.answer.question}</h3>
        <Pie className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
      </div>
    );
  }

  textAnswer() {
    return (
      <div className="widget text-answer" key={this.props.answer.question}>
        <h3>{this.props.answer.question}</h3>
        <div>
          <p>No chart to show for Text question</p>
          <p>View <Link to={`/exports/feedback?question=${this.props.answer.question_id}`}>Export Feedback</Link> page instead</p>
        </div>
      </div>
    )
  }

  answerType() {
    if (this.props.answer.type === "stars") {
      return this.starsAnswer()
    }
    if (this.props.answer.type === "smileys") {
      return this.smileysAnswer()
    }
    else if (this.props.answer.type === "boolean") {
      return this.booleanAnswer()
    }
    else if (this.props.answer.type === "text") {
      return this.textAnswer()
    }
  }

  render() {
    const { infoPanel, type, panelTitleOverride } = this.state;

    const event_ids = type && this.props.answer.type === "stars" ? this.props.answer[type.split("_")[0]] : this.props.answer[type]

    return (
      <>
       {this.answerType()}

        <SlidingPane isOpen={infoPanel} title={type && `${this.props.answer.question} - ${spaceAndCapitalize(panelTitleOverride || type)} Events`} width="70%"
          onRequestClose={() => {
            this.setState({
              infoPanel: false,
              type: null
            });
            this.props.history.push(`/analysis/feedback${this.props.location.search}`)
          }}>
          <EventsPanel event_ids={event_ids}></EventsPanel>
        </SlidingPane>
      </>
    )
  }

  static getDerivedStateFromProps(props, state) {
    const validContext = parseInt(props.match.params.context) === props.index && (FeedbackAnswer.QUESTION_TYPES_WITH_ANSWERS[props.answer.type].includes(props.match.params.context2))
    let type = props.match.params.context2;
    let panelTitleOverride;

    if (props.answer.type === "stars" && props.answer.quantity === 4) {
      if ("three_stars" === props.match.params.context2) {
        panelTitleOverride = "three_stars";
        type = "four_stars"
      }

      if ("four_stars" === props.match.params.context2) {
        panelTitleOverride = "four_stars"
        type = "five_stars"
      }
    }

    if (state.infoPanel === false && props.match.params.chart === "feedback_answer" && validContext) {
      return {
        infoPanel: true,
        type: type,
        panelTitleOverride: panelTitleOverride
      }
    }
    else if (state.infoPanel && (props.match.params.chart !== "feedback_answer" || validContext === false)) {
      return {
        infoPanel: false,
        type: null,
        panelTitleOverride: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.match.params.chart === "feedback_answer" && validContext) {
      return {
        infoPanel: true,
        type: type,
        panelTitleOverride: panelTitleOverride
      }
    }
    else if (props.history.action === "POP" && (props.match.params.chart !== "feedback_answer" || validContext === false)) {
      return {
        infoPanel: false,
        type: null,
        panelTitleOverride: null
      }
    }
    else {
      return null
    }
  }
}

export default withRouter(FeedbackAnswer);
