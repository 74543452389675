import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';

class DailyArrivals extends Component {
  render() {
    const { json, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const days = json.map((day) => (day.day))
      const visitors = json.map((day) => (day.visitors))
      const residents = json.map((day) => (day.residents))
      const staff = json.map((day) => (day.staff))
      const contractors = json.map((day) => (day.contractors))
      const industry_professionals = json.map((day) => (day.industry_professionals))
      const other = json.map((day) => (day.other))

      const data = {
        labels: days,
        datasets: [{
          label: 'Visitors',
          data: visitors,

          backgroundColor: '#eee622'
        }, {
          label: 'Residents',
          data: residents,

          backgroundColor: '#22ee80'
        }, {
          label: 'Staff',
          data: staff,

          backgroundColor: '#222aee'
        }, {
          label: 'Contractors',
          data: contractors,

          backgroundColor: '#ee2290'
        }, {
          label: 'Industry Professionals',
          data: industry_professionals,

          backgroundColor: '#b80c09'
        }, {
          label: 'Other',
          data: other,

          backgroundColor: '#8826bf'
        }]
      }

      return (
        <div className="widget">
          <h3>Daily Arrivals chart</h3>
          <Bar data={data} width={500} height={300} />
        </div>
      );
    }
  }

  state = {
    json: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    var id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/daily_arrivals`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ json: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default DailyArrivals;