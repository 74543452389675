import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdatePerson from '../../components/people/UpdatePerson';
import CreatePerson from '../../components/people/CreatePerson';

import { hideElementForNonAdminsAndNonManagers } from '../../utilities/Forms.js'
import { arrayParamBuilder } from '../../utilities/Generic.js'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfPeople extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    people: [],
    loaded: false,
    error: null,
    key: 0,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false,
    previousURL: '/people',
  };

  handleNewPanel(event) {
    this.setState({
      newPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push('/people/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push(`/people/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push(this.state.previousURL);
    this.componentDidMount();
  }

  emptyEvents(people) {
    if (people.length === 0) {

      let text = "No people to show"
      this.props.page > 1 && (text+= " on this Page")

      let content = []
      this.props.location_param !== "" && content.push(" Location")
      this.props.type_of_person !== "" && content.push(" Type")

      if (content.length > 0) {
        text+= " for this"

        for (let index = content.length - 1; index > 0; index--) {
          content.splice(index, 0, " and")
        }

        for (const index of content) {
          text+= index
        }
      }

      this.props.search !== "" && (text+= " when searching by this Name")

      text+= "."

      return (
        <div className="long-tr">
          <div className="long-td">
            { text }
          </div>
        </div>
      )
    }
  }

  renderPageButtons(page, records) {
    if (parseInt(page) === 1 && records.length < 250) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.props.handleNextPage} disabled={(records.length < 250)}>Next Page</button>
          <button className="button new-button" onClick={this.props.handlePreviousPage} disabled={(this.props.page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  renderSortColumn(title, attribute){
    if (this.props.sort === attribute) {
      let directionArrow = "▲"
      if (this.props.direction === "desc") {
        directionArrow = "▼"
      }
      return (
        <div className="th sortable sorted" onClick={() => this.props.handleSortChange(attribute)}>
          <strong>{title}</strong>
          <div><strong>{directionArrow}</strong></div>
        </div>
      )
    }
    else {
      return (
        <div className="th sortable" onClick={() => this.props.handleSortChange(attribute)}>
          {title}
        </div>
      )
    }
  }

  render() {
    const { people, loaded, key, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <h3 className="inline-block">List of people</h3>

          <Link to={'/people/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdminsAndNonManagers()}`}>New Person</div>
          </Link>

          <div className="table big-table" data-testid="table">
            <div className="tr heading">
              {this.renderSortColumn("First name", "first_name")}
              {this.renderSortColumn("Last name", "last_name")}
              {this.renderSortColumn("Email", "email")}
              <div className="th not-sortable">Relationships</div>
              {this.renderSortColumn("Type", "type_of_person")}
              {this.renderSortColumn("Last login", "last_login")}
              {this.renderSortColumn("Last updated", "updated_at")}
              {baseLicense && (
                <>
                  <div className="th not-sortable">Roles</div>
                  <div className="th not-sortable">Tags</div>
                </>
              )}
              <div className="th not-sortable">Locations</div>
            </div>
            {people.map((person) => (
                <Link to={`/people/${person.id}`} onClick={this.handleEditPanel} id={person.id} key={person.id} className="tr">
                  <div className="td">{person.first_name}</div>
                  <div className="td">{person.last_name}</div>
                  <div className="td">{person.email}</div>
                  <div className="td">{person.relationships}</div>
                  <div className="td">{person.type_of_person}</div>
                  <div className="td">{person.last_login}</div>
                  <div className="td">{person.updated_at}</div>
                  {baseLicense && (
                    <>
                      <div className="td">{person.roles}</div>
                      <div className="td">{person.tags}</div>
                    </>
                  )}
                  <div className="td">{person.locations}</div>
                </Link>
            ))}
          </div>
          {this.emptyEvents(people)}
          {this.renderPageButtons(this.props.page, people)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Person" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push(this.state.previousURL);
              this.componentDidMount();
            }}>
            <CreatePerson />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Person" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdatePerson id={this.state.editId} closeEditPanel={this.closeEditPanel} previousURL={`/people?location=${this.props.location_param}&type=${this.props.type_of_person}`} key={key+1} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && props.id !== state.editId) {
      return {
        editId: props.id,
        key: Math.random()
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/people")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking edit panel
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    let params = `person[type]=${this.props.type_of_person}&person[location]=${this.props.location_param}${arrayParamBuilder("person", "tag_ids", this.props.tag_ids)}&person[search]=${this.props.search}&page=${this.props.page}&sort=${this.props.sort}&direction=${this.props.direction}`

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ people: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfPeople);
