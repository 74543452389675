import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import UpdateFireLog from '../../components/fire_logs/UpdateFireLog';
import ConditionalLink from '../../components/shared/ConditionalLink';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ListOfFireLogs extends Component {
  constructor(props) {
    super(props);

    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
  }

  state = {
    fire_logs: [],
    loaded: false,
    error: null,
    defaultEmail: "",
    showSendEmailModal: false,
    modalId: null,

    editId: null,
    editPanelToggle: false
  };

  handleEditPanel(event) {
    let id = event.currentTarget.parentNode.parentNode.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });
    this.props.history.push(`${this.locationURL()}/fire-log/history/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push(`${this.locationURL()}/fire-log/history`);
    this.componentDidMount();
  }

  openModal(event) {
    const id = event.currentTarget.id;

    this.setState({
      email: this.state.defaultEmail,
      showSendEmailModal: true,
      modalId: id
    })

    event.preventDefault();
  }

  closeModal() {
    this.setState({
      showSendEmailModal: false,
      modalId: null
    })
  }

  handleEmailChange(event) {
    const value = event.target.value;

    this.setState({email: value})

    event.preventDefault();
  }

  handleSendEmail() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "fire_log": {
        "email": this.state.email
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs/${this.state.modalId}/send_email`, requestOptions)
    .then(response => {
      if (response.ok) {
        this.closeModal()
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({errors: data})
      } else {
        this.setState({errors: ""})
      }
    })
    .catch(error => this.setState({ error }))
  }

  locationURL() {
    // This ensures that the Users stays where they are on the site.
    if (this.props.location.pathname.includes("/location")) {
      return `/location/${this.props.location_id}`
    }
    else {
      return ""
    }
  }

  readAccess() {
    const access = localStorage.getItem("access_levels");
    return access !== null && (access.includes("admin") || access.includes("reporting"))
  }

  writeAccess() {
    const access = localStorage.getItem("access_levels");
    return access !== null && (access.includes("admin") || access.includes("emergency"))
  }

  renderAmendable(amendableFireLog) {
    if (this.writeAccess() && amendableFireLog) {
      return (
        <div className="amend" onClick={this.handleEditPanel}>Amendable</div>
      )
    }
    else if (this.readAccess()) {
      return (
        <div className="green-button" onClick={this.handleEditPanel}>Viewable</div>
      )
    }
    else {
      return (
        <div className="no-access" title="Reporting or Admin level required to view Fire Logs">No Access</div>
      )
    }
  }

  renderSendEmailHeader() {
    if (this.readAccess()) {
      return(
        <div className="th">Email</div>
      )
    }
  }

  renderSendEmailLink(fire_log_id) {
    if (this.readAccess()) {
      return(
        <div className="td center" id={fire_log_id} onClick={this.openModal}>
          <div className="green-button">Send Email</div>
        </div>
      )
    }
  }

  renderSendEmailModal() {
    if (this.state.showSendEmailModal) {
      return (
        <div className="send-email-container">
          <div className="send-email-content">
            <label className="column">Email Fire Log to the following address:</label>
            <input className="value" name="value" value={this.state.email} onChange={this.handleEmailChange}/>
            <div>
              <button className="send-email-button" value="yes" onClick={this.handleSendEmail}>Send Email</button>
              <button className="send-email-button" value="no" onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { fire_logs, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of Fire Log Results</h3>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Created At</div>
              <div className="th">Submitted By</div>
              <div className="th">Status</div>
              {this.renderSendEmailHeader()}
            </div>
            {fire_logs.map((fire_log) => (
                <div className="tr" id={fire_log.id} key={fire_log.id}>
                  <ConditionalLink
                    url={`${this.locationURL()}/fire-log/history/${fire_log.id}`}
                    onClick={this.handleEditPanel}
                    className="td"
                    condition={this.readAccess()}
                  >
                    {fire_log.created_at}
                  </ConditionalLink>
                  <div className="td">{fire_log.user_name}</div>
                  <div className="td center">{this.renderAmendable(fire_log.amendable)}</div>
                  {this.renderSendEmailLink(fire_log.id)}
                </div>
            ))}
          </div>

          <SlidingPane isOpen={this.state.editPanelToggle} title="View Fire Log Result" width="50%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateFireLog id={this.state.editId} location_id={this.props.location_id} writeAccess={this.writeAccess()} readAccess={this.readAccess()} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>

          {this.renderSendEmailModal()}

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fire_log_id !== undefined) {
      return {
        editPanelToggle: true,
        editId: props.fire_log_id
      }
    }
    else if (state.editPanelToggle && props.fire_log_id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/devices")) {
      return {
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.fire_log_id !== undefined) {
      return {
        editPanelToggle: true,
        editId: props.fire_log_id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.fire_log_id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.fire_log_id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ fire_logs: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))

    if (this.readAccess()) {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs/default_email?location_id=${this.props.location_id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => this.setState({ defaultEmail: data.email, loaded: true }))
        .catch(error => this.setState({ error, loaded: true }))
    }
  }
}

export default withRouter(ListOfFireLogs);
