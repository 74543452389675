import React, { Component } from 'react';

class SetTitle extends Component {
  state = {
    currentTitle: "",
    previousTitle: ""
  }

  componentDidMount() {
    let organisationName = localStorage.getItem("organisationName") === null ? "" : `| ${localStorage.getItem("organisationName")}`

    let title = this.props.title;

    let currentTitle = `${title} ${organisationName} | Digital Reception`

    this.setState({"previousTitle": document.title})
    this.setState({"currentTitle": currentTitle})

    document.title = currentTitle
  }

  componentWillUnmount() {
    document.title = this.state.previousTitle
  }

  render() {
    return (
      <>
      </>
    );
  }
};

export default SetTitle;
