import React, { Component } from 'react';
import Header from '../../components/header/Header';
import ListOfLocations from '../../components/locations/ListOfLocations';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

class LocationIndex extends Component {
  render() {
    return (
      <div>
        <SetTitle title={"Locations"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Locations</h2>
          <OrganisationName />

          <div className="widgets">
            <ListOfLocations />
          </div>
        </div>

      </div>
    );
  }
}

export default LocationIndex;
