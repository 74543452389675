import React, { Component } from 'react';

class OrganisationName extends Component {
  render() {
    const dynamicClass = this.props.className ? ` ${this.props.className}` : "";

    return (
      <h4 className={`organisation-name${dynamicClass}`}>
        {localStorage.getItem("organisationName")}
      </h4>
    );
  }
}

export default OrganisationName;
