import React, { Component } from 'react';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.timerRef = React.createRef();
    this.endTime = Date.now() + this.props.duration * 60 * 1000; // duration in minutes
  }

  componentDidMount() {
    this.timerId = setInterval(this.updateTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  updateTimer = () => {
    const now = Date.now();
    const timeLeft = this.endTime - now;

    if (timeLeft <= 0) {
      clearInterval(this.timerId);
      this.timerRef.current.textContent = "00:00";
      return;
    }

    const minutes = Math.floor(timeLeft / 1000 / 60);
    const seconds = Math.floor((timeLeft / 1000) % 60);

    const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    this.timerRef.current.textContent = formattedTime;
  };

  render() {
    return (
      <span className="countdown" ref={this.timerRef}>{this.props.duration}:00</span>
    );
  }
}

export default Countdown;
