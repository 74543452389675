import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Header from '../components/header/Header';
import OrganisationName from '../components/organisations/OrganisationName';
import Locations from '../components/organisations/Locations';
import WeeklyFeedback from '../components/organisations/charts/WeeklyFeedback';
import OutstandingFeedbackByLocation from '../components/analysis/charts/OutstandingFeedbackByLocation';

import SetTitle from '../components/shared/SetTitle';

class Home extends Component {
  constructor(props) {
    super(props);

    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    editPanelToggle: false
  };

  handleEditPanel(event) {
    this.setState({editPanelToggle: true});
    this.props.history.push(`/edit-organisation`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
    });
    this.props.history.push('/');
  }

  updatePopupCallback(id) {
    this.setState({
      resetId: id
    })
  }

  render() {
    const { error, unauthorized } = this.state;

    const baseLicense = localStorage.license === "base"

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    return (
      <div>
        <SetTitle title={"Home"} />
        <Header />

        <div className="main-page">
          <h2><OrganisationName /></h2>

          <div className="widgets">
            <Locations />
            <WeeklyFeedback />
            { baseLicense && <OutstandingFeedbackByLocation /> }
          </div>
        </div>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.editPanelToggle && props.location.pathname.includes("/edit-organisation") === false && props.location.pathname.includes("/users") === false) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle === false && (props.location.pathname.includes("/edit-organisation") || props.location.pathname.includes("/users"))) {
      return {
        editPanelToggle: true
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if ((this.props.location.pathname.includes("/edit-organisation") || this.props.location.pathname.includes("/users")) && this.state.editPanelToggle === false) {
      this.setState({
        editPanelToggle: true
      });
    }
  }
}

export default Home;
