import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

class StaffFulfilmentShow extends Component {
  render() {
    // Probably a call to the API to check if they have RequiredAllocation data
    const firstTime = true

    const location_id = this.props.match.params.location_id
    const rota_id = this.props.match.params.rota_id

    if (firstTime) {
      if (rota_id !== undefined) {
        return <Redirect to={`/staff-fulfilment/${location_id}/rotas/${rota_id}/required-allocation`}/>
      }
      else {
        return <Redirect to={`/staff-fulfilment/${location_id}/required-allocation`}/>
      }
    }
    else {
      if (rota_id !== undefined) {
        return <Redirect to={`/staff-fulfilment/${location_id}/rotas/${rota_id}/staff-fulfilment`}/>
      }
      else {
        return <Redirect to={`/staff-fulfilment/${location_id}/staff-fulfilment`}/>
      }
    }
  }
}

export default StaffFulfilmentShow;
