import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ConditionalLink extends Component {
  render() {
    if (this.props.condition && localStorage.license === "base") {
      return (
        <Link to={this.props.url} id={this.props.id} onClick={this.props.onClick} className={this.props.className}>
          {this.props.children}
        </Link>
      )
    }
    else {
      return (
        <span id={this.props.id} className={this.props.className}>
          {this.props.children}
        </span>
      );
    }
  }
}

export default ConditionalLink;
