import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { futureEndDayOfWeek } from '../../utilities/Generic.js'

class UpdateDefaultRequiredAllocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDayOfWeekChange = this.handleDayOfWeekChange.bind(this);
    this.handleWeekChange = this.handleWeekChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

  }

  state = {
    number_of_staff: "",
    day_of_week: "",
    start_time: "",
    end_time: "",
    role_id: "",

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    showFutureAssociatedWeekliesModal: false,
    future_associated_weeklies_to_keep: [],

    roles: null,
    rolesLoaded: false,
    futureAssociatedWeeklies: null,
    futureAssociatedWeekliesLoaded: false,
    loaded: false,

    unauthorized: "",
    errors: ""
  };

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "role_id") {
      const default_period_days = (this.state.roles.find(role => (role.id === parseInt(value)))?.default_period_weeks || this.state.location_default_period_weeks || 1) * 7;

      return this.setState(prevState => {
        return ({
          [name]: value,
          day_of_week: prevState.day_of_week % default_period_days
        })
      })
    }

    this.setState({[name]: value});
  }

  handleDayOfWeekChange(event) {
    let value = parseInt(event.target.value)

    this.setState(prevState => {
      let weekNumber = Math.floor(prevState.day_of_week / 7)
      return ({day_of_week: value + (weekNumber * 7)})
    });

  }

  handleWeekChange(event) {
    let value = event.target.value;

    this.setState(prevState =>
      ({ day_of_week: (prevState.day_of_week % 7) + (value * 7) })
    );
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var future_associated_weeklies_to_keep = this.state.future_associated_weeklies_to_keep

    if (value === true) {
      if (future_associated_weeklies_to_keep.includes(name)) {
        let index = future_associated_weeklies_to_keep.indexOf(name)
        future_associated_weeklies_to_keep.splice(index, 1)
      }
    }
    else if (value === false) {
      if (!future_associated_weeklies_to_keep.includes(name)) {
        future_associated_weeklies_to_keep.push(name)
      }
    }

    this.setState({future_associated_weeklies_to_keep: future_associated_weeklies_to_keep});
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "default_required_allocation": {
        "number_of_staff": this.state.number_of_staff,
        "day_of_week": this.state.day_of_week,
        "end_day_of_week": futureEndDayOfWeek(this.state.day_of_week, this.state.start_time, this.state.end_time),
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      }
    })
    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Required Allocation updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleDeleteModal() {
    this.setState({ showFutureAssociatedWeekliesModal: true });

    // only grab this data once per page refresh
    if (!this.state.futureAssociatedWeekliesLoaded) {
      let id = this.props.id;

      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations/${id}/future_associated_weeklies`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ futureAssociatedWeeklies: data, futureAssociatedWeekliesLoaded: true })
        })
        .catch(error => this.setState({ error, peopleLoaded: true }))
    }
  }

  closeModal(event) {
    this.setState({ showFutureAssociatedWeekliesModal: false })
  }

  doNotCloseModal(event) {
    event.stopPropagation();
  }

  handleDelete(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "future_associated_weeklies_to_keep": this.state.future_associated_weeklies_to_keep
    })

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "", showFutureAssociatedWeekliesModal: false })
        sessionStorage.setItem("updateSuccess", "Required Allocation deleted successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderFutureAssociatedWeekliesModal() {
    if (this.state.showFutureAssociatedWeekliesModal) {
      if (this.state.futureAssociatedWeekliesLoaded) {
        return (
          <div className="full-modal-container" onClick={this.closeModal}>
            <div className="full-modal-content minimal" onClick={this.doNotCloseModal}>
              <div className="modal-title">Delete Future Associated Weekly Required Allocations?</div>
              <label className="column">Below is a list of every future Weekly Required Allocation assigned to this Default Shift Assignment currently on the system. Please uncheck the boxes of any Weekly Required Allocations you wish to keep.</label>

              <div className="table big-table" data-testid="table">
                <div className="tr heading">
                  <div className="th">Start Date</div>
                  <div className="th">Start Time</div>
                  <div className="th">End Date</div>
                  <div className="th">End Time</div>
                  <div className="th">Also Delete?</div>
                </div>
                {this.state.futureAssociatedWeeklies.map((weekly) => (
                    <div id={weekly.id} key={weekly.id} className="tr">
                      <div className="td center-cell">{weekly.start_date}</div>
                      <div className="td center-cell">{weekly.start_time}</div>
                      <div className="td center-cell">{weekly.end_date}</div>
                      <div className="td center-cell">{weekly.end_time}</div>
                      <div className="td center-cell">
                        <input className="column no-margin" type="checkbox" name={weekly.id} checked={!this.state.future_associated_weeklies_to_keep.includes(weekly.id)} onChange={this.handleMultiCheckbox} />
                      </div>
                    </div>
                ))}
              </div>
              <div className="center-two-buttons">
                <button className="delete modal-button red-button" onClick={this.handleDelete}>Delete</button>
                <button className="modal-button" onClick={this.closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="full-modal-container" onClick={this.closeModal}>
            <div className="full-modal-content" onClick={this.doNotCloseModal}>
              <div className="modal-title">Delete Future Associated Weekly Required Allocations?</div>
              <label className="column">Loading...</label>
              <div className="center-two-buttons">
                <button className="modal-button" value="no" onClick={this.closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderWeekSelector(default_period_weeks) {
    if (default_period_weeks === 1) {
      return
    }

    let weeks = []

    for (let i = 0; i < default_period_weeks; i++) {
      weeks.push(
        <option key={i} value={i}>Week {i + 1}</option>
      )
    }

    return (
      <div className="row">
        <select name="week" onChange={this.handleWeekChange} value={Math.floor(this.state.day_of_week / 7)}>
          {weeks}
        </select>
      </div>
    )
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <div className="button" onClick={this.handleSubmit} disabled={this.state.updating}>Save</div>
    }
    else {
      return <div className="button" disabled="disabled">Sorry, you don't have permission to save changes</div>
    }
  }

  renderDeleteButton() {
    if (adminUser() || managerUser()) {
      return <div className="button red-button right" onClick={this.handleDeleteModal} disabled={this.state.updating}>Delete</div>
    }
  }

  render() {
    const { number_of_staff, day_of_week, start_time, end_time, role_id, min_start_time, max_start_time, min_end_time, max_end_time, roles, rolesLoaded, location_default_period_weeks, locationLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false || locationLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded && locationLoaded) {
      const default_period_weeks = roles.find(role => (role.id === parseInt(role_id)))?.default_period_weeks || location_default_period_weeks || 1

      return (
        <div>
          <SetTitle title={"Edit Default Required Allocation | Staff Fulfilment"} />

          <form className="settings big-settings">
            <div className="row">
              <HelpText page={'default_required_allocation'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'day_of_week'} />

                <label className="column">Start Day of Week:</label>{ this.renderErrors('day_of_week') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <select name="day_of_week" onChange={this.handleDayOfWeekChange} value={day_of_week % 7}>
                  <option value="">Select</option>
                  <option value="0">Monday</option>
                  <option value="1">Tuesday</option>
                  <option value="2">Wednesday</option>
                  <option value="3">Thursday</option>
                  <option value="4">Friday</option>
                  <option value="5">Saturday</option>
                  <option value="6">Sunday</option>
                </select>
              </div>

              {this.renderWeekSelector(default_period_weeks)}
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'start_time'} />

                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>
              <div className="row">
                <HelpText page={'default_required_allocation'} section={'end_time'} />

                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleChange} />
              </div>

              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'default_required_allocation'} section={'number_of_staff'} />

              <label className="column">Number of Staff:</label>{ this.renderErrors('number_of_staff') }
              <input className="column" type="number" min="1" step="1" name="number_of_staff" value={number_of_staff} onChange={this.handleChange} />
            </div>

            { this.renderSaveButton() }
            { this.renderDeleteButton() }
            { renderErrorWarning(errors) }
            { this.renderFutureAssociatedWeekliesModal() }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          number_of_staff: data.number_of_staff,
          day_of_week: data.day_of_week,
          end_day_of_week: data.end_day_of_week,
          start_time: data.start_time,
          end_time: data.end_time,
          role_id: data.role_id,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/role_configurations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/location_configuration?location_id=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location_default_period_weeks: data.default_period_weeks, locationLoaded: true })
      })
      .catch(error => this.setState({ error, locationLoaded: true }))
  }
}

export default UpdateDefaultRequiredAllocation;
