import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'

import Header from '../../components/header/Header';
import EventsExport from '../../components/exports/Events';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import { debounce, optionalParam, buildParams } from '../../utilities/Generic.js'

class ExportsEvents extends Component {
  static TIME_COLUMNS = ["check_in", "check_out"];

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleDurationCheckbox = this.handleDurationCheckbox.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 500);
  }

  state = {
    date_from: null,
    date_to: null,
    location: "",
    type_of_person: "",
    custom_attribute_ids: [],
    tag_ids: [],
    showDuration: false,
    search: "",
    visited_person_search: "",
    page: 1,
    sort: "default",
    direction: "desc",

    refresh_date_from: this.formatDate(new Date(), 7),
    refresh_date_to: this.formatDate(new Date(), 0),
    refresh_location: "",
    refresh_type_of_person: "",
    refresh_tag_ids: [],
    refresh_search: "",
    refresh_visited_person_search: "",
    refresh_page: 1,
    refresh_sort: "default",
    refresh_direction: "desc",

    locations: [],
    custom_attributes: [],
    tags: [],
    locationsLoaded: false,
    customAttributesLoaded: false,
    tagsLoaded: false,
    key: 0
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random(),
      page: 1
    });

    let url = {
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      location: this.state.location,
      type_of_person: this.state.type_of_person,
      tag_ids: this.state.tag_ids
    }

    url[name] = value

    const params = buildParams(
      `from=${url.date_from}&to=${url.date_to}`,
      optionalParam("location", url.location),
      optionalParam("type", url.type_of_person),
      optionalParam("tag_ids", url.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", this.state.showDuration),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  handleMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    this.handleChange(fakeEvent)
  }

  handleMultiCheckbox(event) {
    const name = parseInt(event.target.name);
    const value = event.target.checked;

    const custom_attribute_ids = this.state.custom_attribute_ids

    if (value === true) {
      if (!custom_attribute_ids.includes(name)) {
        custom_attribute_ids.push(name)
      }
    }
    else if (value === false) {
      if (custom_attribute_ids.includes(name)) {
        const index = custom_attribute_ids.indexOf(name)
        custom_attribute_ids.splice(index, 1)
      }
    }

    this.setState({
      custom_attribute_ids: custom_attribute_ids,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", value),
      this.customAttributeHistoryBuilder(custom_attribute_ids),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  customAttributeHistoryBuilder(custom_attribute_ids) {
    if (custom_attribute_ids.length > 0) {
      return `custom_attribute_ids=${custom_attribute_ids}`
    }
    else return ""
  }

  handleDurationCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", value),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  handlePreviousPage() {
    let value = parseInt(this.state.page) - 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", this.state.showDuration),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=${value}&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  handleNextPage() {
    let value = parseInt(this.state.page) + 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", this.state.showDuration),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=${value}&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  handleSortChange(attribute) {
    let sort = this.state.sort
    let direction = this.state.direction

    if (this.state.sort !== attribute) {
      sort = attribute
      direction = ExportsEvents.TIME_COLUMNS.includes(attribute) ? "desc" : "asc"
      this.setState({
        sort: attribute,
        direction: direction,
        page: 1,
        key: Math.random()
      });
    }
    else if (this.state.direction === "asc") {
      direction = "desc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }
    else {
      direction = "asc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", this.state.showDuration),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=1&sort=${sort}&direction=${direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandleSearch()

    // This stops getDerivedStateFromProps using params to override this state change when typing immediately after hitting back/forward
    this.props.history.action = "PUSH"
  }

  handleSearch() {
    this.setState({
      page: 1,
      key: Math.random()
    })

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("tag_ids", this.state.tag_ids),
      optionalParam("search", this.state.search),
      optionalParam("visited_person_search", this.state.visited_person_search),
      optionalParam("duration", this.state.showDuration),
      this.customAttributeHistoryBuilder(this.state.custom_attribute_ids),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/events?${params}`);
  }

  renderCustomAttributes() {
    const custom_attributes = this.state.custom_attributes
    const custom_attribute_ids = this.state.custom_attribute_ids

    if (custom_attributes.length > 0 && localStorage.license === "base") {
      return (
        <div className="selection-block">
          Display Custom Attributes:
          {custom_attributes.map((custom_attribute) => (
            <div className="selection-block checkbox-block" key={"custom_attribute_"+custom_attribute.id}>
              <input className="clickable" type="checkbox" id={custom_attribute.id} name={custom_attribute.id} key={"custom_attribute_"+custom_attribute.id} checked={custom_attribute_ids.includes(custom_attribute.id)} onChange={this.handleMultiCheckbox} />
              <label className="clickable" htmlFor={custom_attribute.id} key={custom_attribute.name}>{custom_attribute.name}</label>
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    const { date_from, date_to, location, type_of_person, tag_ids, search, custom_attribute_ids, visited_person_search, locations, custom_attributes, tags, locationsLoaded, customAttributesLoaded, tagsLoaded, unauthorized, error, page, sort, direction, showDuration } = this.state;
    const { match: { params } } = this.props;

    const baseLicense = localStorage.license === "base"

    const selectOptions = tags.map(tag => ({ value: tag.id, label: tag.name }))
    const selectedTags = selectOptions.filter(option => tag_ids.includes(option.value))

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false || tagsLoaded === false || customAttributesLoaded === false) {
      return (
        <div>
          <SetTitle title={"Export Events | Exports"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Export Events</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SetTitle title={"Export Events | Exports"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Export Events</h2>
          <OrganisationName />


          <div className="export-selection">
            <div className="selection-block">
              <label className="column">Date from:</label>
              <input className="column" type="date" name="date_from" value={date_from} onChange={this.handleChange} />
            </div>

            <div className="selection-block">
              <label className="column">Date to:</label>
              <input className="column" type="date" name="date_to" value={date_to} onChange={this.handleChange} />
            </div>

            <div className="selection-block">
              <label className="column">Location:</label>
              <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                <option value="">All</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Type:</label>
              <select name="type_of_person" value={type_of_person} onChange={this.handleChange} data-testid="select-type-of-person">
                <option value="">All</option>
                <option value="visitor">Visitors</option>
                {baseLicense && (
                  <>
                    <option value="resident">Residents</option>
                    <option value="staff">Staff</option>
                    <option value="contractor">Contractors</option>
                    <option value="industry professional">Industry Professionals</option>
                    <option value="other">Other</option>
                  </>
                )}
              </select>
            </div>

            {baseLicense && (
              <div className="selection-block">
                <label className="column">Filter by Tags:</label>
                <Select onChange={this.handleMultiSelectChange} options={selectOptions} value={selectedTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"All"} />
              </div>
            )}

            <div className="selection-block">
              <label className="column">Search by Person:</label>
              <input type="text" name="search" value={search} onChange={this.handlePartialChange} />
            </div>

            {baseLicense && (
              <div className="selection-block">
                <label className="column">Search by Visited Person:</label>
                <input type="text" name="visited_person_search" value={visited_person_search} onChange={this.handlePartialChange} />
              </div>
            )}

            <div className="selection-block">
              <label className="column checkbox-label" htmlFor="showDuration">Include durations:</label>
              <input className="column" type="checkbox" name="showDuration" id="showDuration" checked={showDuration} onChange={this.handleDurationCheckbox} />
            </div>

            {this.renderCustomAttributes()}
          </div>

          <EventsExport date_from={date_from} date_to={date_to} selected_location={location} type_of_person={type_of_person} tag_ids={tag_ids} custom_attribute_ids={custom_attribute_ids} custom_attributes={custom_attributes} key={this.state.key + 1} id={params.id} search={search} visited_person_search={visited_person_search} page={page} handleNextPage={this.handleNextPage} handlePreviousPage={this.handlePreviousPage} showDuration={showDuration} sort={sort} direction={direction} handleSortChange={this.handleSortChange} />

        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "" && props.location.pathname.endsWith("/events")) {
      props.history.replace(`/exports/events?from=${state.refresh_date_from}&to=${state.refresh_date_to}&page=${state.refresh_page}&sort=${state.refresh_sort}&direction=${state.refresh_direction}`);

      return {
        date_from: state.refresh_date_from,
        date_to: state.refresh_date_to,
        location: state.refresh_location,
        type_of_person: state.refresh_type_of_person,
        tag_ids: state.refresh_tag_ids,
        custom_attribute_ids: [],
        search: state.refresh_search,
        visited_person_search: state.refresh_visited_person_search,
        page: state.refresh_page,
        sort: state.refresh_sort,
        direction: state.refresh_direction,
        key: Math.random()
      }
    }
    else {
      // handle back/forward buttons
      if (props.history.action === "POP" && props.history.location.search !== "") {
        const params = new URLSearchParams(props.history.location.search)
        let date_from = params.get('from')
        let date_to = params.get('to')
        let location = params.get('location') || ""
        let type_of_person = params.get('type') || ""
        let tag_ids = params.get('tag_ids')?.split(",").map(id => parseInt(id)) || []
        let custom_attribute_ids = params.get('custom_attribute_ids')?.split(",").map(id => parseInt(id)) || []
        let search = params.get('search') || ""
        let visited_person_search = params.get('visited_person_search') || ""
        let page = params.get('page') || state.refresh_page
        let sort = params.get('sort') || state.refresh_sort
        let direction = params.get('direction') || state.refresh_direction
        let showDuration = params.get('duration') === null ? false : true

        return {
          date_from: date_from,
          date_to: date_to,
          location: location,
          type_of_person: type_of_person,
          tag_ids: tag_ids,
          custom_attribute_ids: custom_attribute_ids,
          search: search,
          visited_person_search: visited_person_search,
          page: page,
          sort: sort,
          direction: direction,
          showDuration: showDuration,
          key: Math.random()
        }
      }
      else {
        return null
      }
    }
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      const date_from = params.get('from') || this.formatDate(new Date(), 7)
      const date_to = params.get('to') || this.formatDate(new Date(), 0)
      const location = params.get('location') || ""
      const type_of_person = params.get('type') || ""
      const tag_ids = params.get('tag_ids')?.split(",").map(id => parseInt(id)) || []
      const custom_attribute_ids = params.get('custom_attribute_ids')?.split(",").map(id => parseInt(id)) || []
      const search = params.get('search') || ""
      const visited_person_search = params.get('visited_person_search') || ""
      const showDuration = params.get('duration') === null ? false : true

      const page = params.get('page') || 1
      const sort = params.get('sort') || "default";
      const direction = params.get('direction') || "desc";

      // set history in case one of the params was missing
      const builtParams = buildParams(
        `from=${date_from}&to=${date_to}`,
        optionalParam("location", location),
        optionalParam("type", type_of_person),
        optionalParam("tag_ids", tag_ids),
        optionalParam("search", search),
        optionalParam("visited_person_search", visited_person_search),
        optionalParam("duration", showDuration),
        this.customAttributeHistoryBuilder(custom_attribute_ids),
        `page=${page}&sort=${sort}&direction=${direction}`
      )

      this.props.history.replace(`/exports/events?${builtParams}`);

      this.setState({
        date_from: date_from,
        date_to: date_to,
        location: location,
        type_of_person: type_of_person,
        tag_ids: tag_ids,
        custom_attribute_ids: custom_attribute_ids,
        search: search,
        visited_person_search: visited_person_search,
        page: page,
        sort: sort,
        showDuration: showDuration,
        key: Math.random()
      });
    }

    // populate search boxes with default params when deep linking to the ViewEvent panel
    if (this.props.location.search === "" && !this.props.location.pathname.endsWith("/events")) {
      this.setState({
        date_from: this.state.refresh_date_from,
        date_to: this.state.refresh_date_to,
        location: this.state.refresh_location,
        type_of_person: this.state.refresh_type_of_person,
        tag_ids: this.state.refresh_tag_ids
      })
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    if (localStorage.license === "base") {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/custom_attributes/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ custom_attributes: data, customAttributesLoaded: true })
        })
        .catch(error => this.setState({ error, customAttributesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ tags: data, tagsLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
    }
    else {
      this.setState({
        tags: [],
        tagsLoaded: true
      })
    }
  }
}

export default ExportsEvents;
