import React, { Component } from 'react';

class ResellerCSS extends Component {
  render() {
    const reseller = localStorage.getItem("latestReseller")

    let baseColor
    let accentColor

    if (reseller === "PCS") {
      baseColor = "#20c7d4"
      accentColor = "#70e1e9"
    }
    else if (reseller === "Cool Care") {
      baseColor = "#89D8FF"
      accentColor = "#d6f1ff"
    }
    else {
      baseColor = "#20c7d4"
      accentColor = "#70e1e9"
    }

    return (
      <style dangerouslySetInnerHTML={{
        __html: `
          :root {
            --base-color: ${baseColor};
            --accent-color: ${accentColor};
          }
        `
      }}>
      </style>
    )
  }
}

export default ResellerCSS;
