import React, { Component } from 'react';
import BtnEdit from '../../images/btn_edit_768x512.png';
import { Link } from 'react-router-dom';


class EditButton extends Component {
  render() {
    var text = this.props.text;
    var link = this.props.link;
    var extraClass = this.props.extraClass;

    return (
      <Link to={link} className={`edit-button ${extraClass}`}>
        <div>
          <h4>{text}</h4>
          <img className="edit-icon" src={BtnEdit} alt="{text}" width="40" />
        </div>
      </Link>
    );
  }
}

export default EditButton;