import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { faLaughBeam, faSmile, faMeh, faFrown, faTired, faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AlertProcessing from '../../components/feedback_questions/AlertProcessing';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'

class UpdateFeedbackQuestion extends Component {

  constructor(props) {
    super(props);

    this.setEmailsValid = this.setEmailsValid.bind(this);
    this.removeInvalidEmails = this.removeInvalidEmails.bind(this);
    this.setAlertErrors = this.setAlertErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmitAlerts = this.handleSubmitAlerts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    display: "",
    answer_type: 0,
    answer_quantity: "5",
    hidden: false,

    preview_answer: "4",
    text_answer_preview: "",

    emailsValid: true,
    removeInvalidEmailsCount: 0,
    submitAlertsCount: 0,

    loaded: false,
    unauthorized: "",
    errors: "",
    alertErrors: "",
    error: ""
  };

  setEmailsValid(invalidEmails) {
    this.setState({
      emailsValid: invalidEmails.length === 0
    })
  }

  removeInvalidEmails(event) {
    this.setState(prevState => ({
      removeInvalidEmailsCount: prevState.removeInvalidEmailsCount + 1
    }))

    event.preventDefault();
  }

  setAlertErrors(errors) {
    this.setState({ alertErrors: errors })
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleSubmitAlerts(event) {
    this.setState(prevState => ({
      submitAlertsCount: prevState.submitAlertsCount + 1
    }))

    event.preventDefault();
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "feedback_question": {
        "name": this.state.name,
        "display": this.state.display,
        "answer_type": this.state.answer_type,
        "answer_quantity": ["smileys", "stars"].includes(this.state.answer_type) ? this.state.answer_quantity : null,
        "hidden": this.state.hidden,
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        this.handleSubmitAlerts(event)
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderQuestionPreview() {
  if (this.state.answer_type === "boolean") {
    return (
      <div className="feedback-preview bottom-margin">
        <div class="small-bottom-margin">Answer Preview:</div>
        <div className="question radio">
          <input className="column" type="checkbox" name="preview_answer" value="5" id="yes" checked={this.state.preview_answer === "5"} onChange={this.handleChange} />
          <label className="longer" htmlFor="yes">Yes</label>
          <input className="column" type="checkbox" name="preview_answer" value="1" id="no" checked={this.state.preview_answer === "1"} onChange={this.handleChange} />
          <label className="longer" htmlFor="no">No</label>
        </div>
      </div>
    )
  }

  if (this.state.answer_type === "stars") {
    let numberOfStars = this.state.preview_answer
    var iconType = []

    for (var i = 0; i < numberOfStars; i++) {
      iconType.push("selected")
    }

    return (
      <div className="feedback-preview bottom-margin">
        <div class="small-bottom-margin">Answer Preview:</div>
        <div className="question radio">

          <div className="mobile-stars">
            <input className="column" type="checkbox" name="preview_answer" value="1" id="one_star" checked={this.state.preview_answer === "1"} onChange={this.handleChange} />
            <label htmlFor="one_star">
              <FontAwesomeIcon icon={iconType[0] ? solidStar : faStar} style={{color: iconType[0] ? "gold" : "#777"}} />
            </label>
            <input className="column" type="checkbox" name="preview_answer" value="2" id="two_stars" checked={this.state.preview_answer === "2"} onChange={this.handleChange} />
            <label htmlFor="two_stars">
              <FontAwesomeIcon icon={iconType[1] ? solidStar : faStar} style={{color: iconType[1] ? "gold" : "#777"}} />
            </label>
            { this.state.answer_quantity === "5" &&
              <>
                <input className="column" type="checkbox" name="preview_answer" value="3" id="three_stars" checked={this.state.preview_answer === "3"} onChange={this.handleChange} />
                <label htmlFor="three_stars">
                  <FontAwesomeIcon icon={iconType[2] ? solidStar : faStar} style={{color: iconType[2] ? "gold" : "#777"}} />
                </label>
              </>
            }
            <input className="column" type="checkbox" name="preview_answer" value="4" id="four_stars" checked={this.state.preview_answer === "4"} onChange={this.handleChange} />
            <label htmlFor="four_stars">
              <FontAwesomeIcon icon={iconType[3] ? solidStar : faStar} style={{color: iconType[3] ? "gold" : "#777"}} />
            </label>
            <input className="column" type="checkbox" name="preview_answer" value="5" id="five_stars" checked={this.state.preview_answer === "5"} onChange={this.handleChange} />
            <label htmlFor="five_stars">
              <FontAwesomeIcon icon={iconType[4] ? solidStar : faStar} style={{color: iconType[4] ? "gold" : "#777"}} />
            </label>
          </div>
        </div>
      </div>
    )
  }

  if (this.state.answer_type === "text") {
    return (
      <div className="feedback-preview bottom-margin">
        <div class="small-bottom-margin">Answer Preview:</div>
        <div className="question text form">
          <textarea aria-required="true" name="text_answer_preview" value={this.state.text_answer_preview} onChange={this.handleChange} />
        </div>
      </div>
    )
  }

  if (this.state.answer_type === "smileys") {
    return (
      <div className="feedback-preview bottom-margin">
        <div class="small-bottom-margin">Answer Preview:</div>
        <div className="question radio">

          <div className="smileys">
            <span className="very_unhappy">
              <input className="column" type="checkbox" name="preview_answer" value="1" id={`very_unhappy`} checked={this.state.preview_answer === "1"} onChange={this.handleChange} />
              <label htmlFor={`very_unhappy`}>
                <FontAwesomeIcon icon={faTired} />
              </label>
            </span>
            <span className="unhappy">
              <input className="column" type="checkbox" name="preview_answer" value="2" id={`unhappy`} checked={this.state.preview_answer === "2"} onChange={this.handleChange} />
              <label htmlFor={`unhappy`}>
                <FontAwesomeIcon icon={faFrown} />
              </label>
            </span>
            { this.state.answer_quantity === "5" &&
              <span className="yellow">
                <input className="column" type="checkbox" name="preview_answer" value="3" id={`neutral`} checked={this.state.preview_answer === "3"} onChange={this.handleChange} />
                <label htmlFor={`neutral`}>
                  <FontAwesomeIcon icon={faMeh} />
                </label>
              </span>
            }
            <span className={this.state.answer_quantity === "5" ? "light-green" : "yellow"}>
              <input className="column" type="checkbox" name="preview_answer" value="4" id={`happy`} checked={this.state.preview_answer === "4"} onChange={this.handleChange} />
              <label htmlFor={`happy`}>
                <FontAwesomeIcon icon={faSmile} />
              </label>
            </span>
            <span className="very_happy">
              <input className="column" type="checkbox" name="preview_answer" value="5" id={`very_happy`} checked={this.state.preview_answer === "5"} onChange={this.handleChange} />
              <label htmlFor={`very_happy`}>
                <FontAwesomeIcon icon={faLaughBeam} />
              </label>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      if (this.state.emailsValid) {
        if (this.props.disabled) {
          return <input type="submit" value="Save alerts" onClick={this.handleSubmitAlerts} />
        }

        return <input type="submit" value="Save" onClick={this.handleSubmit} />
      }
      else {
        return (
          <>
            <input type="submit" value="Cannot save invalid emails" disabled="disabled" />
            <br/>
            <input type="submit" value="Remove invalid emails" onClick={this.removeInvalidEmails} />
          </>
        )
      }
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const { name, display, answer_type, answer_quantity, hidden, removeInvalidEmailsCount, submitAlertsCount, loaded, unauthorized, errors, alertErrors, error } = this.state;

    const disabled = this.props.disabled;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={`Edit Feedback Question | ${name} | Feedback`} />

          <form className="settings big-settings">
            <div className="row">
              <HelpText page={'feedbackQuestion'} section={'name'} />

              <label className="column">Feedback Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} disabled={disabled} />
            </div>

            <div className="row">
              <HelpText page={'feedbackQuestion'} section={'question'} />

              <label className="column">Feedback Question:</label>{ this.renderErrors('display') }
              <input className="column" type="text" name="display" value={display} onChange={this.handleChange} disabled={disabled} />
            </div>

            <div className="row">
              <HelpText page={'feedbackQuestion'} section={'type'} />

              <label className="column">Question Type:</label>{ this.renderErrors('answer_type') }

              <div className="question-types">
                <div className="question-type">
                  <input className="column" type="radio" name="answer_type" id="boolean" value="boolean" checked={answer_type === "boolean"} onChange={this.handleChange} disabled={disabled} />
                  <label htmlFor="boolean">Yes/No</label>
                </div>

                <div className="question-type">
                  <input className="column" type="radio" name="answer_type" id="stars" value="stars" checked={answer_type === "stars"} onChange={this.handleChange} disabled={disabled} />
                  <label htmlFor="stars">Stars</label>
                </div>

                <div className="question-type">
                  <input className="column" type="radio" name="answer_type" id="smileys" value="smileys" checked={answer_type === "smileys"} onChange={this.handleChange} disabled={disabled} />
                  <label htmlFor="smileys">Smileys</label>
                </div>

                <div className="question-type">
                  <input className="column" type="radio" name="answer_type" id="text" value="text" checked={answer_type === "text"} onChange={this.handleChange} disabled={disabled} />
                  <label htmlFor="text">Text</label>
                </div>
              </div>
            </div>

            { ["stars", "smileys"].includes(answer_type) &&
              <div className="row">
                <HelpText page={'feedbackQuestion'} section={'answer_quantity'} />

                <label className="column">Answer Quantity:</label>{ this.renderErrors('answer_quantity') }

                <div className="answer-quantity">
                  <div className="question-type">
                    <input className="column" type="radio" name="answer_quantity" id="4" value="4" checked={answer_quantity === "4"} onChange={this.handleChange} disabled={disabled} />
                    <label htmlFor="stars">Four</label>
                  </div>

                  <div className="question-type">
                    <input className="column" type="radio" name="answer_quantity" id="5" value="5" checked={answer_quantity === "5"} onChange={this.handleChange} disabled={disabled} />
                    <label htmlFor="smileys">Five</label>
                  </div>
                </div>
              </div>
            }

            {this.renderQuestionPreview()}

            <div className="row">
              <HelpText page={'feedbackQuestion'} section={'hidden'} />

              <input className="column" type="checkbox" name="hidden" id="hidden_question_checkbox" checked={hidden} onChange={this.handleCheckbox} disabled={disabled} />
              <label className="column checkbox-label" htmlFor="hidden_question_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
            </div>
          </form>

          <AlertProcessing id={this.props.id} current_answer_type={answer_type} current_answer_quantity={answer_quantity} closePanel={this.props.closeEditPanel} setEmailsValid={this.setEmailsValid} setErrors={this.setAlertErrors} removeInvalidEmailsCount={removeInvalidEmailsCount} submitAlertsCount={submitAlertsCount} origin="update" />

          <form className="settings big-settings">
            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
            { renderErrorWarning(alertErrors) }
          </form>

        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          name: data.name,
          display: data.display,
          answer_type: data.answer_type,
          answer_quantity: data.answer_quantity?.toString() || this.state.answer_quantity,
          hidden: data.hidden,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default UpdateFeedbackQuestion;
