import React, { Component } from 'react';

import YAML from 'js-yaml';
import HelpTextFile from "./HelpText.yml";

import parse from 'html-react-parser';
import ReactTooltip from 'react-tooltip';
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HelpText extends Component {
  state = {
    toolTip: {},
    loaded: false
  };

  renderText(text) {
    if (text) {
      return (
        <div>
          {text.map((line, index) => (
            <p key={index}>{parse(line)}</p>
          ))}
        </div>
      )
    }
  }

  renderBulletPoints(bullets) {
    if (bullets) {
      return (
        <ul>
          {bullets.map((line, index) => (
            <li key={index}>{parse(line)}</li>
          ))}
        </ul>
      )
    }
  }

  render() {
    let section = this.props.section;

    const { toolTip, loaded } = this.state;

    if (loaded === false) {
      return (
        <>
        </>
      )
    }

    if (loaded) {
      return (
        <div className={this.props.className}>
          <a data-tip data-for={section} className="info-tooltip" href="#info"><FontAwesomeIcon icon={faInfo} /></a>
          <ReactTooltip id={section} place="left" type="dark" effect="solid">
            { this.renderText(toolTip.text) }
            { this.renderBulletPoints(toolTip.bullets) }
          </ReactTooltip>
        </div>
      )
    }
  }

  componentDidMount() {
    let page = this.props.page;
    let section = this.props.section;

    // using `fetch` to read the contents of the local YAML file
    fetch(HelpTextFile)
      .then(helpText => helpText.text())
      .then(text => {
        let yaml = YAML.safeLoad(text)

        if (yaml[page] && yaml[page][section]) {
          this.setState({
            toolTip: yaml[page][section],
            loaded: true
          })
        }
      })
      .catch(error => this.setState({ loaded: false }));
  }
}

export default HelpText;
