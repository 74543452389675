import React, { Component } from 'react';

class LoadingSpinner extends Component {
  render() {
    const condition = this.props.condition;

    if (condition) {
      return (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }
}

export default LoadingSpinner;
