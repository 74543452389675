import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';


class OutstandingFeedback extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.redirectToOutstandingActions = this.redirectToOutstandingActions.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const index = getElementAtEvent(this.barGraph.current, event)[0].index
    this.redirectToOutstandingActions(index)
  }

  redirectToOutstandingActions(index) {
    const location_id = this.props.id;

    let type;
    // eslint-disable-next-line
    switch(index) {
      case 0:
        type = "open"
        break;
      case 1:
        type = "started"
        break;
      case 2:
        type = "awaiting sign-off"
        break;
      case 3:
        type = "blocked"
        break;
    }

    this.props.history.push(`/exports/outstanding-actions?&location=${location_id}&action_statuses=${type}`)

  }

  formatDateThreeMonthsAgo() {
    let date = new Date();
    date.setMonth(date.getMonth() - 3);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  formatDateToday() {
    let date = new Date();
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  render() {
    const { json, loaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const data = {
        labels: ["Open", "Started", "Awaiting sign-off", "Blocked"],
        datasets: [{
          data: [
            json.open,
            json.started,
            json.awaiting_sign_off,
            json.blocked
          ],

          backgroundColor: ['#eee622', '#3cdaff', '#f29e32', '#ca4442']
        }]
      }

      const options = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          yAxes: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      }

      return (
        <div className="widget">
          <h3>Outstanding Actions</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
          <div>Only showing actions for feedback created in the last three months.</div>
        </div>
      );
    }
  }

  state = {
    json: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");


    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/outstanding_feedback`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ json: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(OutstandingFeedback);
