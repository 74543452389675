import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class CreateDefaultShiftAssignment extends Component {

  constructor(props) {
    super(props);

    this.addBreakTime = this.addBreakTime.bind(this);
    this.removeBreakTime = this.removeBreakTime.bind(this);
    this.handleBreakTimeChange = this.handleBreakTimeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleDurationTypeChange = this.handleDurationTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.unavailableStaffSearch = this.unavailableStaffSearch.bind(this);
    this.debouncedUnavailableStaffSearch = debounce(this.unavailableStaffSearch.bind(this), 1000);
    this.debouncedFetchPeople = debounce(this.fetchPeople.bind(this), 1000)
  }

  state = {
    person_id: this.props.clickedPerson || "",
    start_date: this.props.start_date || "",
    end_date: this.props.end_date || "",
    start_time: this.props.clickedHour || this.generateMinStartTime(),
    end_time: this.props.clickedEndHour || this.generateClickedHourEndTime(),
    duration: this.generateInitialDuration(),
    durationType: this.generateInitalDurationType(),
    role_id: this.props.role_id || "",
    break_times: [],

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    roles: null,
    people: null,
    unavailable_person_ids: [],

    rolesLoaded: false,
    peopleLoaded: false,

    unauthorized: "",
    errors: ""
  };

  generateClickedHourEndTime() {
    const startTime = this.props.clickedHour || this.generateMinStartTime()

    let time = new Date("1970-01-01T" + startTime + "Z");
    time.setMinutes(time.getMinutes() + time.getTimezoneOffset());
    time.setHours(time.getHours() + 1);

    return time.toTimeString().slice(0, 5);
  }

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  generateInitialDuration() {
    let duration = this.calculateDurationInMinutes({start_time: this.props.clickedHour || this.generateMinStartTime(), end_time: this.props.clickedEndHour || this.generateClickedHourEndTime()})
    return this.shouldDisplayInHours(duration) === true ? duration / 60 : duration
  }

  generateInitalDurationType() {
    let duration = this.calculateDurationInMinutes({start_time: this.props.clickedHour || this.generateMinStartTime(), end_time: this.props.clickedEndHour || this.generateClickedHourEndTime()})
    return this.shouldDisplayInHours(duration) ? "hours" : "minutes"
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "role_id" || name === "start_date") {
      let role_id = this.state.role_id
      if (name === "role_id") {
        role_id = value
      }

      this.debouncedFetchPeople(role_id)
    }
  }

  handleTimeChange(event) {
    let times = {
      start_time: this.state.start_time,
      end_time: this.state.end_time
    }

    let name = event.target.name;
    times[name] = event.target.value;

    let duration = this.calculateDurationInMinutes(times)
    let durationType = duration ? "minutes" : this.state.durationType;

    if (duration && this.shouldDisplayInHours(duration)) {
      duration = duration / 60
      durationType = "hours"
    }

    this.setState({
      start_time: times.start_time,
      end_time: times.end_time,
      duration: duration,
      durationType: durationType
    });

    this.debouncedUnavailableStaffSearch(times)
  }

  calculateDurationInMinutes(times) {
    if (times.start_time === "" || times.end_time === "") {
      return ""
    }

    let start_time = new Date(`01/01/2000 ${times.start_time}`)
    let end_time = new Date(`01/01/2000 ${times.end_time}`)

    if (end_time <= start_time) {
      end_time.setDate(end_time.getDate() + 1)
    }

    return Math.floor((end_time - start_time) / 60000)
  }

  shouldDisplayInHours(duration) {
    return duration % 60 === 0 || (duration > 90 && duration % 30 === 0)
  }

  handleDurationChange(event) {
    let duration = parseFloat(event.target.value);
    let end_time = this.calculateEndTime(duration);

    this.setState({
      duration: duration,
      end_time: end_time
    });

    this.debouncedUnavailableStaffSearch({start_time: this.state.start_time, end_time: end_time})
  }

  calculateEndTime(duration) {
    if (this.state.start_time === "") {
      return ""
    }

    let end_time = new Date(`01/01/2000 ${this.state.start_time}`)

    if (this.state.durationType === "hours") {
      end_time.setHours(end_time.getHours() + duration)
      end_time.setMinutes(end_time.getMinutes() + Math.round((duration % 1) * 60))
    }
    else {
      end_time.setMinutes(end_time.getMinutes() + duration)
    }
    let hours = ("0" + end_time.getHours()).slice(-2)
    let minutes = ("0" + end_time.getMinutes()).slice(-2)
    return `${hours}:${minutes}`
  }

  handleDurationTypeChange(event) {
    let durationType = event.target.value;

    this.setState((prevState) => {
      let duration = prevState.duration.valueOf();

      if (prevState.durationType === "minutes" && durationType === "hours") {
        duration = Math.round(duration / 60 * 100) / 100
      }
      else if (prevState.durationType === "hours" && durationType === "minutes") {
        duration = Math.round(duration * 60)
      }
      return ({
        duration: duration,
        durationType: durationType
      })
    })
  }

  handleBreakTimeChange(event) {
    let index = event.target.parentNode.parentNode.id
    let name = event.target.name;
    let value = event.target.value;

    this.setState((prevState) => {
      let break_times = [...prevState.break_times]
      break_times[index][name] = value
      return (
        {break_times: break_times}
      )
    });
  }

  addBreakTime(event) {
    this.setState((prevState) => ({
      break_times: [...prevState.break_times, {start_time: "", duration: ""}]
    }))
    event.preventDefault();
  }

  removeBreakTime(event) {
    let index = event.currentTarget.id;

    this.setState((prevState) => {
      let break_times = [...prevState.break_times]
      break_times.splice(index, 1)

      return ({
        break_times: break_times
      })
    })
    event.preventDefault();
  }

  unavailableStaffSearch(times) {
    if (times.start_time === "" || times.end_time === "" || times.end_time === "Inval" || this.state.start_date === "" || this.state.role_id === "") {
      return this.setState({
        unavailable_person_ids: []
      })
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let end_date = this.state.end_time > this.state.start_time ? this.state.start_date : this.formatNextDate(this.state.start_date)

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_shift_assignments/unavailable_staff?weekly_shift_assignment[start_time]=${times.start_time}&weekly_shift_assignment[end_time]=${times.end_time}&weekly_shift_assignment[start_date]=${this.state.start_date}&weekly_shift_assignment[end_date]=${end_date}&weekly_shift_assignment[role_id]=${this.state.role_id}&weekly_shift_assignment[location_id]=${this.props.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let person_id = data.unavailable_person_ids.includes(parseInt(this.state.person_id)) ? "" : this.state.person_id
        this.setState({ unavailable_person_ids: data.unavailable_person_ids, person_id: person_id })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))
  }

  handleSubmit(event) {
    if (this.state.person_id === "") {
      this.setState({"errors": {"person": "can't be blank"} });
      event.preventDefault();
      return
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let end_date = this.state.end_time > this.state.start_time ? this.state.start_date : this.formatNextDate(this.state.start_date)

    var json = JSON.stringify({
      "weekly_shift_assignment": {
        "person_id": this.state.person_id,
        "start_date": this.state.start_date,
        "end_date": end_date,
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "break_times": this.state.break_times,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_shift_assignments`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Shift Assignment created successfully!");
        this.props.closeNewPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  formatNextDate(date) {
    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 1)

    let day = ("0" + tomorrow.getDate()).slice(-2)
    let month = ("0" + (tomorrow.getMonth() + 1)).slice(-2)
    let year = tomorrow.getFullYear()

    return `${year}-${month}-${day}`
  }

  renderPeople() {
    if (this.state.role_id === "") {
      return (
        <option value="">Select a Role</option>
      )
    }

    if (this.state.peopleLoaded) {
      return (
        <>
          <option value="">Select</option>
          {this.state.people.map((person) => (
            <option value={person.id} key={person.id} disabled={this.state.unavailable_person_ids.includes(person.id)}>{person.full_name} {this.renderHoursInfo(person)}</option>
          ))}
        </>
      )
    }
    else {
      return (
        <option value="">Loading...</option>
      )
    }
  }

  renderHoursInfo(person) {
    let hoursInfo = ""
    if (this.state.start_date) {
      hoursInfo = `- ${person.tallied_hours || 0}`

      if (person.contracted_hours !== null) {
        hoursInfo += ` / ${person.contracted_hours}`
      }

      hoursInfo += " hours this week"
    }

    return hoursInfo
  }

  renderShiftHoursNotice() {
    if (!this.state.duration) {
      return
    }

    let person;

    if (this.state.peopleLoaded) {
      person = this.state.people.find(person => person.id === parseInt(this.state.person_id))
    }

    let text = `This shift as currently constructed will add ${this.state.duration} ${this.state.durationType} to `

    if (person) {
      text += person.full_name

      if (this.state.start_date) {
        let durationHours = this.state.durationType === "hours" ? this.state.duration : this.state.duration / 60
        let newTally = person.tallied_hours + durationHours

        text += `, which would bring their tallied hours for this week to ${newTally}`

        if (person.contracted_hours) {
          text +=`, which is ${newTally > person.contracted_hours ? "over" : "within"} their contracted hours (${person.contracted_hours})`
        }
      }
    }
    else {
      text += "its assigned staff member"
    }

    return <NoticeBox type="info" text={text} />
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { person_id, start_date, start_time, end_time, duration, durationType, role_id, break_times, min_start_time, max_start_time, min_end_time, max_end_time, roles, rolesLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded) {
      let maxDuration = durationType === "hours" ? 24 : 24 * 60

      return (
        <div>
          <SetTitle title={"New Weekly Shift Assignment | Staff Fulfilment"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'weekly_shift_assignment'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'weekly_shift_assignment'} section={'person'} />

              <label className="column">Staff Member:</label>{ this.renderErrors('person') }
              <select name="person_id" disabled={role_id === ""} onChange={this.handleChange} value={person_id}>
                {this.renderPeople()}
              </select>
            </div>

            {this.renderShiftHoursNotice()}

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'start_date'} />

                <label className="column">Start Date:</label>{ this.renderErrors('start_date') }
                <input type="date" name="start_date" onChange={this.handleChange} value={start_date} />
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_shift_assignment'} section={'start_time'} />
                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>
              <div className="row">
                <HelpText page={'weekly_shift_assignment'} section={'end_time'} />
                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleTimeChange} />
              </div>
              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleTimeChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'weekly_shift_assignment'} section={'duration'} />
              <label className="column">Duration:</label>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="number" name="duration" min="0" max={maxDuration} value={duration} onChange={this.handleDurationChange} />
              </div>
              <div className="row">
                <select name="durationType" onChange={this.handleDurationTypeChange} value={durationType}>
                  <option value="minutes">Minute{duration !== 1 && "s"}</option>
                  <option value="hours">Hour{duration !== 1 && "s"}</option>
                </select>
              </div>
            </div>

            <div className="row bottom-padding">
              <HelpText page={'weekly_shift_assignment'} section={'break_times'} />
              <label className="column">Break Times:</label>{ this.renderErrors('break_times') }

              <div className="table big-table break-time-bg">
                <div className="tr heading">
                  <div className="th">Start time</div>
                  <div className="th">Duration</div>
                  <div className="th">Remove</div>
                </div>
                {break_times.map((break_time, index) =>
                  <div className="tr" key={index} id={index}>
                    <div className="td full-row">
                      <input type="time" name="start_time" min={min_start_time} max={max_start_time} value={break_time.start_time} onChange={this.handleBreakTimeChange} />
                    </div>
                    <div className="td">
                      <select name="duration" onChange={this.handleBreakTimeChange} value={break_time.duration}>
                        <option value="">Select</option>
                        <option value="15">15 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="45">45 minutes</option>
                        <option value="60">1 hour</option>
                        <option value="120">2 hours</option>
                      </select>
                    </div>
                    <div className="td center">
                      <div className="button" id={index} onClick={this.removeBreakTime}>Remove</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="long-tr center" style={{backgroundColor: "#fff"}}>
                <div className="long-td">
                  <div className="button" onClick={this.addBreakTime}>Add {break_times.length > 0 ? "another" : "a"} break time +</div>
                </div>
              </div>
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  fetchPeople(role_id) {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    if (role_id) {
      this.setState({ peopleLoaded: false });

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/staff_with_weekly_hours?location_id=${this.props.location_id}&role_id=${role_id}&start_date=${this.state.start_date}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ people: data, peopleLoaded: true })
        })
        .catch(error => this.setState({ error, peopleLoaded: true }))

      this.unavailableStaffSearch({start_time: this.state.start_time, end_time: this.state.end_time})
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    this.fetchPeople(this.props.role_id)
  }
}

export default CreateDefaultShiftAssignment;
