import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';


class OutstandingFeedbackByLocation extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.redirectToOutstandingActions = this.redirectToOutstandingActions.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const locationIndex = getElementAtEvent(this.barGraph.current, event)[0].index
    const statusIndex = getElementAtEvent(this.barGraph.current, event)[0].datasetIndex
    this.redirectToOutstandingActions(locationIndex, statusIndex)
  }

  redirectToOutstandingActions(locationIndex, statusIndex) {
    const location_id = this.state.json[locationIndex].id;

    let type;
    // eslint-disable-next-line
    switch(statusIndex) {
      case 0:
        type = "open"
        break;
      case 1:
        type = "started"
        break;
      case 2:
        type = "awaiting sign-off"
        break;
      case 3:
        type = "blocked"
        break;
    }

    this.props.history.push(`/exports/outstanding-actions?&location=${location_id}&action_statuses=${type}`)

  }

  render() {
    const { json, loaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {

      const data = {
        labels: json.map((array) => ( array.location )),
        datasets: [{
          label: "Open",
          data: json.map((array) => ( array.open )),
          backgroundColor: '#eee622'
        }, {
          label: "Started",
          data: json.map((array) => ( array.started )),
          backgroundColor: '#3cdaff'
        }, {
          label: "Awaiting sign-off",
          data: json.map((array) => ( array.awaiting_sign_off )),
          backgroundColor: '#f29e32'
        }, {
          label: "Blocked",
          data: json.map((array) => ( array.blocked )),
          backgroundColor: '#ca4442'
        }]
      }

      const options = {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        scales: {
          yAxes: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            }
          },
          xAxes: {
            stacked: true,
          }
        }
      }

      return (
        <div className="widget">
          <h3>Outstanding Actions by Location</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
          <div>Only showing actions for feedback created in the last three months.</div>
        </div>
      );
    }
  }

  state = {
    json: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");


    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/analysis/outstanding_feedback_by_location`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ json: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(OutstandingFeedbackByLocation);
