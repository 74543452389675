import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SetTitle from '../../components/shared/SetTitle';

class LocationName extends Component {
  render() {
    const { location, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const name = this.props.name || location.name

      return (
        <div>
          <SetTitle title={`${name} | Locations`} />
          {name}
        </div>
      );
    }
  }

  state = {
    location: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    var id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ location: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default LocationName;
