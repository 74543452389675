import React, { Component } from 'react';
import ListOfFeedbackTypes from '../../components/feedback_types/ListOfFeedbackTypes';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class FeedbackIndex extends Component {
  constructor(props) {
    super(props);

    this.updatePopupCallback = this.updatePopupCallback.bind(this);
  }

  updatePopupCallback() {
    this.setState({
      resetId: Math.random()
    })
  }

  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Feedback"} />
        <PopupBox />

        <h2 className="page-title">Feedback</h2>
        <OrganisationName />

        <div>
          <ListOfFeedbackTypes id={params.id} question_id={params.question_id} updatePopupCallback={this.updatePopupCallback} />
        </div>
      </div>
    );
  }
}

export default FeedbackIndex;
