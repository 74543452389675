import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HelpText from '../../components/help/HelpText';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import NoticeBox from '../../components/shared/NoticeBox';
import PopupBox from '../../components/shared/PopupBox';
import SetTitle from '../../components/shared/SetTitle';

import { adminUser, managerUser } from '../../utilities/Forms.js'

class SuggestedMergeComponent extends Component {
  static VALID_REASONS = ["close name", "matching email", "matching phone", "matching details, different locations", "matching name, different logical types"];

  constructor(props) {
    super(props);

    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMergePeople = this.handleMergePeople.bind(this);
    this.handleFlip = this.handleFlip.bind(this);
    this.handleIgnore = this.handleIgnore.bind(this);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleAcceptAllModal = this.handleAcceptAllModal.bind(this);
    this.selectAllReasons = this.selectAllReasons.bind(this);
    this.unselectAllReasons = this.unselectAllReasons.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleAcceptAll = this.handleAcceptAll.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  state = {
    selectedMergeId: "",
    showExceptionModal: false,
    modalSuggestedMerge: null,
    showAcceptAllConfirmationModal: false,

    selectedReasons: SuggestedMergeComponent.VALID_REASONS,

    suggested_merges: [],
    page: 1,

    flipped: false,

    loaded: false,
    errors: [],
    acceptAllErrors: "",
    error: ""
  };

  handlePreviousPage() {
    const page = parseInt(this.state.page) - 1

    this.setState({
      page: page,
      loaded: false
    })

    this.fetchContent(page)

    let params = ""
    if (this.props.location) {
      params = `location=${this.props.location}&`
    }

    params += `page=${page}`

    this.props.history.push(`/suggested-merge-people?${params}`)
  }

  handleNextPage() {
    const page = parseInt(this.state.page) + 1

    this.setState({
      page: page,
      loaded: false
    })

    this.fetchContent(page)

    let params = ""
    if (this.props.location) {
      params = `location=${this.props.location}&`
    }

    params += `page=${page}`

    this.props.history.push(`/suggested-merge-people?${params}`)
  }

  handleSelect(event) {
    const id = event.currentTarget.parentNode.id;

    this.setState({
      selectedMergeID: id,
      flipped: false
    });
  }

  handleMergePeople(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let suggested_merge = this.state.suggested_merges.find((suggested_merge) => suggested_merge.id === parseInt(this.state.selectedMergeID))

    const target_person_id = suggested_merge.people.find(person => person.to_be_deleted === false).id
    const to_be_deleted_person_id = suggested_merge.people.find(person => person.to_be_deleted === true).id

    var json = JSON.stringify({
      "people": {
        "target_person_id": this.state.flipped ? to_be_deleted_person_id : target_person_id,
        "to_be_deleted_person_id": this.state.flipped ? target_person_id : to_be_deleted_person_id
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/merge_people`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data.errors })
      } else {
        this.setState({
          updating: false,
          errors: ""
        })
        sessionStorage.setItem("updateSuccess", "People merged successfully!");
        localStorage.removeItem("last_suggested_merges_get")
        this.componentDidMount();
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleFlip(event) {
    this.setState(prevState => ({
      flipped: !prevState.flipped
    }))
  }

  handleIgnore(event) {
    const id = event.currentTarget.parentNode.id

    const suggested_merge = this.state.suggested_merges.find((suggested_merge) => suggested_merge.id.toString() === id);

    if (suggested_merge.reason.includes("matching details")) {
      this.setState({
        showExceptionModal: true,
        modalSuggestedMerge: suggested_merge
      })
    }
    else {
      this.handleDestroy(suggested_merge, false)
    }
  }

  handleDestroy(suggested_merge, exception) {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const destroyURL = exception ? "/destroy_and_add_exceptions" : ""

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/suggested_merges/${suggested_merge.id}${destroyURL}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          showExceptionModal: false,
          modalSuggestedMerge: null,
          loaded: true
        })
        sessionStorage.setItem("deleteSuccess", this.renderDeletionFeedback(suggested_merge.reason, exception));
        localStorage.removeItem("last_suggested_merges_get")
        this.componentDidMount();
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  handleAcceptAllModal(event) {
    this.setState({
      showAcceptAllConfirmationModal: true
    });

    event.preventDefault();
  }

  selectAllReasons() {
    this.setState({
      selectedReasons: ["close name", "matching email", "matching phone", "matching details, different locations", "matching name, different logical types"]
    })
  }

  unselectAllReasons() {
    this.setState({
      selectedReasons: []
    })
  }


  handleMultiCheckbox(event) {
    let reason = event.target.id;
    let value = event.target.checked;

    var selectedReasons = this.state.selectedReasons

    if (value === true) {
      if (!selectedReasons.includes(reason)) {
        selectedReasons.push(reason)
      }
    }
    else if (value === false) {
      if (selectedReasons.includes(reason)) {
        let index = selectedReasons.indexOf(reason)
        selectedReasons.splice(index, 1)
      }
    }

    this.setState({selectedReasons: selectedReasons});
  }

  handleAcceptAll() {
    this.setState({
      updating: true
    });

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = "?"

    for (let reason of this.state.selectedReasons) {
      params += `reasons[]=${reason}&`
    }

    if (this.props.location) {
      params += `location=${this.props.location}`
    }

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/suggested_merges/accept_all${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 422) {
          errorsInResponse = true
          return response.json()
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          this.setState({ acceptAllErrors: data, updating: false })
        }
        else {
          this.setState({
            showExceptionModal: false,
            showAcceptAllConfirmationModal: false,
            modalSuggestedMerge: null,
            updating: false,
            loaded: true
          })

          sessionStorage.setItem("updateSuccess", "All suggestions will be merged shortly.");

          // Give the queue five minutes to process
          const secondsAgo = 5 * 60
          const date = new Date().valueOf() - secondsAgo * 1000
          localStorage.setItem("last_suggested_merges_get", date)
          localStorage.setItem("last_suggested_merges_count", "?")

          this.componentDidMount();
        }
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  filterDeletingMerges(suggested_merges) {
    return suggested_merges.filter(
      suggested_merge => suggested_merge.people[0] && suggested_merge.people[1] && suggested_merge.people[0].id && suggested_merge.people[1].id
    )
  }

  renderDeletionFeedback(reason, exception) {
    switch(reason) {
      case "close name":
        return "Suggested Merge deleted. You may wish to amend one of their first names or last names to avoid this appearing in the future"

      case "matching email":
        return "Suggested Merge deleted. You may wish to change one of their emails or make their names more different to one another to avoid this appearing in the future"

      case "matching phone":
        return "Suggested Merge deleted. You may wish to change one of their phone numbers or make their names more different to one another to avoid this appearing in the future"

      case "matching details, different locations":
        if (exception) {
          return "Suggested Merge deleted. Suggestions for either of these two people with anyone lacking a common Location will no longer be offered."
        }
        else {
          return "Suggested Merge deleted. This suggestion may be created in the future if these two people continue to have almost identical details."
        }

      case "matching name, different logical types":
        if (exception) {
          return "Suggested Merge deleted. Suggestions for either of these two people with anyone differing greatly from their type of person will no longer be offered."
        }
        else {
          return "Suggested Merge deleted. This suggestion may be created in the future if these two people continue to have almost identical names."
        }

      default:
        return "Suggested Merge deleted!"
    }
  }

  renderErrors() {
    if (this.state.errors.length > 0) {

      return (
        <div className="errors">
          {this.state.errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      )
    }

    if (this.state.error.length > 0) {
      return (
        <div>{this.state.error}</div>
      )
    }
  }


  renderAcceptAllErrors(field) {
    if (this.state.acceptAllErrors[field]) {
      return (
        <div className="error full-width small-top-padding">{`${this.state.acceptAllErrors[field]}`}</div>
      )
    }
  }

  renderSuggestedMerge(suggested_merge) {
    const target_person = suggested_merge.people.find((person) => person.to_be_deleted === false);
    const to_be_deleted_person = suggested_merge.people.find((person) => person.to_be_deleted === true);


    if (suggested_merge.id.toString() === this.state.selectedMergeID) {
      const display_target_person = this.state.flipped ? to_be_deleted_person : target_person
      const display_to_be_deleted_person = this.state.flipped ? target_person : to_be_deleted_person
      return (
        <div className="selected-merge top-padding" id={suggested_merge.id} key={suggested_merge.id}>
          <div>
            <Link to={`/people/${display_target_person.id}`}><strong>{display_target_person.full_name}</strong></Link>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <Link to={`/people/${display_to_be_deleted_person.id}`}><strong>{display_to_be_deleted_person.full_name}</strong></Link>
          </div>
          <div>Reason: {this.renderReason(suggested_merge.reason)}</div>
          <div/>
          <div>Created at: {suggested_merge.created_at}</div>
            <div className="selected-merge-details">
              <div></div>
              <div><strong>Target Person</strong></div>
              <div><strong>To Be Deleted Person</strong></div>

              <div><strong>First Name: </strong></div>
              <div>{display_target_person.first_name}</div>
              <div>{display_to_be_deleted_person.first_name}</div>

              <div><strong>Last Name: </strong></div>
              <div>{display_target_person.last_name}</div>
              <div>{display_to_be_deleted_person.last_name}</div>

              <div><strong>Alternate Name: </strong></div>
              <div>{display_target_person.alternate_name}</div>
              <div>{display_to_be_deleted_person.alternate_name}</div>

              <div><strong>Email: </strong></div>
              <div>{display_target_person.email}</div>
              <div>{display_to_be_deleted_person.email}</div>

              <div><strong>Phone: </strong></div>
              <div>{display_target_person.phone}</div>
              <div>{display_to_be_deleted_person.phone}</div>

              <div><strong>Type of Person: </strong></div>
              <div>{display_target_person.type_of_person}</div>
              <div>{display_to_be_deleted_person.type_of_person}</div>

              <div><strong>Created Relationships: </strong></div>
              <div>{display_target_person.relationships_count}</div>
              <div>{display_to_be_deleted_person.relationships_count}</div>

              <div><strong>Assigned Locations: </strong></div>
              {this.renderLocations(display_target_person)}
              {this.renderLocations(display_to_be_deleted_person)}

              <div><strong>Is Imported?: </strong></div>
              <div>{(display_target_person.imports_count.length > 0).toString()}</div>
              <div>{(display_to_be_deleted_person.imports_count.length > 0).toString()}</div>
            </div>
            {this.renderAcceptButton()}
            {this.renderFlipButton()}
            {this.renderIgnoreButton()}
            {this.renderUpdating(this.state.updating)}
        </div>
      )
    }
    else {
      return (
        <div className="suggested-merge top-padding" id={suggested_merge.id} key={suggested_merge.id}>
          <div className="right">Created at: {suggested_merge.created_at}</div>
          <div>
            <Link to={`/people/${target_person.id}`}><strong>{target_person.full_name}</strong></Link>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <Link to={`/people/${to_be_deleted_person.id}`}><strong>{to_be_deleted_person.full_name}</strong></Link>
          </div>
          <div className="padded">Reason: {this.renderReason(suggested_merge.reason)}</div>
          <div className="button" onClick={this.handleSelect}>View Details</div>
          {this.renderIgnoreButton()}
        </div>
      )
    }
  }

  renderLocations(person) {
    if (person.locations.length === 0) {
      return <div>0</div>
    }

    let fullText = person.locations.join(", ")

    if (fullText.length > 60) {
      return <div title={fullText}>{person.locations.length}</div>
    }

    return <div>{fullText}</div>
  }

  renderAcceptButton() {
    if (adminUser() || managerUser()) {
      return (
        <div className="button" onClick={this.handleMergePeople}>Accept Suggestion</div>
      )
    }
  }

  renderFlipButton() {
    if (adminUser() || managerUser()) {
      return (
        <div className="button" onClick={this.handleFlip}>Flip</div>
      )
    }
  }

  renderIgnoreButton() {
    if (adminUser() || managerUser()) {
      return (
        <div className="button" onClick={this.handleIgnore}>Ignore</div>
      )
    }
  }

  renderReason(reason) {
    switch(reason) {
      case "close name":
        return (<span>These two people have <strong>very similar first and last names</strong></span>)
      case "matching email":
        return (<span>These two people have <strong>matching emails</strong> and their <strong>names are not in conflict</strong></span>)
      case "matching phone":
        return (<span>These two people have <strong>matching phone numbers</strong> and their <strong>names are not in conflict</strong></span>)
      case "matching details, different locations":
        return (<span>These two people are <strong>almost identical</strong> but they have <strong>0 assigned Locations in common</strong><HelpText className="tr-helptext" page={'suggested_merges'} section={'cross_location_matching'} /></span>)
      case "matching name, different logical types":
        return (<span>These two people have <strong>very similar first and last names</strong> but they are <strong>very different types of people</strong><HelpText className="tr-helptext" page={'suggested_merges'} section={'logical_types_of_people'} /></span>)
      default:
        return "These two people have been found to be similar"
    }
  }

  renderExceptionModal() {
    if (this.state.showExceptionModal) {
      const suggested_merge = this.state.modalSuggestedMerge;
      const firstPerson = suggested_merge.people[0].full_name
      const secondPerson = suggested_merge.people[1].full_name

      return (
        <div className="modal-container">
          <div className="modal-content">
            <label className="column"><strong>Add Exception?</strong></label>

            <div className="top-padding">To avoid this Suggested Merge appearing again, you may wish to mark {firstPerson} and {secondPerson} as exempt from the following rule:</div>
            <div className="top-padding">{this.renderReason(suggested_merge.reason)}</div>
            <button className="modal-button" onClick={() => {this.handleDestroy(suggested_merge, true)}}>Add Exceptions</button>
            <button className="modal-button" onClick={() => {this.handleDestroy(suggested_merge, false)}}>Just Ignore</button>
            <button className="modal-button" onClick={this.closeModal}>Cancel</button>
          </div>
        </div>
      )
    }
  }

  renderAcceptAllConfirmationModalButtons(locationName) {
    if (adminUser() || managerUser()) {
      let buttonText;

      if (locationName) {
        buttonText = `Accept Suggested Merges for ${locationName}`
      }
      else {
        buttonText = `Accept Suggested Merges`
      }

      return (
        <div className="center-two-buttons top-padding">
          <button className="modal-button" disabled={this.state.updating} onClick={this.handleAcceptAll}>
            {buttonText}
            <LoadingSpinner condition={this.state.updating} />
          </button>
          <button className="modal-button" disabled={this.state.updating} onClick={this.closeModal}>
            Cancel
          </button>
        </div>
      )
    }
    else {
      return (
        <div className="center">
          <button className="modal-button" disabled="true" style={{width: "auto"}}>
            Sorry, you don't have permission to merge people
          </button>

          <button className="center modal-button" style={{width: "95%"}} onClick={this.closeModal}>
            Cancel
          </button>
        </div>
      )
    }
  }

  renderAcceptAllConfirmationModal() {
    if (this.state.showAcceptAllConfirmationModal) {
      if (this.props.location) {
        const selectBox = document.querySelector('select[name="location"]')
        const locationName = selectBox.options[selectBox.selectedIndex].innerText

        return (
          <div className="modal-container">
            <div className="modal-content">
              <div className="center-text"><strong>Accept multiple Suggested Merges for {locationName}?</strong></div>

              <div className="small-top-padding">
                Click the accept all button to queue all Suggested Merges for any Person assign to the {locationName} Location to be accepted in the background, in order of most recently created to least recently created (those shown at the top will be merged first).
              </div>

              {this.renderReasonSelection()}

              <div className="small-top-padding">
                <NoticeBox type={"info"} text={"Anyone who is suggested to be merged into multiple people will only be merged into the person they were most recently suggested to be merged with.<br/><br/>Anyone suggested to be merged into a person who has already been merged away will not be merged."} />
              </div>

              <div className="small-top-padding">
                <NoticeBox type={"warning"} text={"Please ensure that anyone who you wish not to be merged away has had their Suggested Merge ignored <em>before</em> you click accept all, as this process cannot be undone."} />
              </div>

              {this.renderAcceptAllConfirmationModalButtons(locationName)}
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="modal-container">
            <div className="modal-content">
              <div className="center-text"><strong>Accept multiple Suggested Merges?</strong></div>

              <div className="top-padding">
                Click the accept all button to queue all Suggested Merges to be accepted in the background, in order of most recently created to least recently created (those shown at the top will be merged first).
              </div>

              {this.renderReasonSelection()}

              <div className="top-padding">
                <NoticeBox type={"info"} text={"Anyone who is suggested to be merged into multiple people will only be merged into the person they were most recently suggested to be merged with.<br/><br/>Anyone suggested to be merged into a person who has already been merged away will not be merged."} />
              </div>

              <div className="top-padding">
                <NoticeBox type={"warning"} text={"Please ensure that anyone who you wish not to be merged away has had their Suggested Merge ignored <em>before</em> you click accept all, as this process cannot be undone."} />
              </div>

              {this.renderAcceptAllConfirmationModalButtons()}
            </div>
          </div>
        )
      }
    }
  }

  renderReasonSelection() {
    const { selectedReasons } = this.state;

    return (
      <div className="small-top-padding reason-selection">
        <div>Select the reasons you wish to accept:</div>

        {this.renderAcceptAllErrors("reasons")}

        {selectedReasons.length === SuggestedMergeComponent.VALID_REASONS.length && (<button className="modal-button right small-top-padding" onClick={this.unselectAllReasons}>Unselect all reasons</button>)}
        {selectedReasons.length !== SuggestedMergeComponent.VALID_REASONS.length && (<button className="modal-button right small-top-padding" onClick={this.selectAllReasons}>Select all reasons</button>)}

        <div className="reason-container">
          <input className="column" type="checkbox" id="close name" checked={this.state.selectedReasons.includes("close name")} onChange={this.handleMultiCheckbox} />
          <label htmlFor="close name">Very similar names</label>
        </div>

        <div className="reason-container">
          <input className="column" type="checkbox" id="matching email" checked={this.state.selectedReasons.includes("matching email")} onChange={this.handleMultiCheckbox} />
          <label htmlFor="matching email">Matching email</label>
        </div>

        <div className="reason-container">
          <input className="column" type="checkbox" id="matching phone" checked={this.state.selectedReasons.includes("matching phone")} onChange={this.handleMultiCheckbox} />
          <label htmlFor="matching phone">Matching phone</label>
        </div>

        <div className="reason-container">
          <input className="column" type="checkbox" id="matching details, different locations" checked={this.state.selectedReasons.includes("matching details, different locations")} onChange={this.handleMultiCheckbox} />
          <label htmlFor="matching details, different locations">Almost identical with 0 Locations in common</label>
        </div>

        <div className="reason-container">
          <input className="column" type="checkbox" id="matching name, different logical types" checked={this.state.selectedReasons.includes("matching name, different logical types")} onChange={this.handleMultiCheckbox} />
          <label htmlFor="matching name, different logical types">Very similar names but very different types of people</label>
        </div>

      </div>
    )
  }

  closeModal() {
    this.setState({
      showAcceptAllConfirmationModal: false,
      showExceptionModal: false,
      modalSuggestedMerge: null,
      acceptAllErrors: ""
    })
  }

  renderUpdating(updating) {
    if (updating) {
      return (
        <div>
          Merging...
        </div>
      );
    }
  }

  emptySuggestedMerges(suggested_merges) {
    if (suggested_merges.length === 0) {
      return (
        <div>No suggested merges to show!</div>
      )
    }
  }

  renderAcceptAllButton(suggested_merges) {
    if (adminUser() && suggested_merges.length > 0) {
      return (
        <button className="button" onClick={this.handleAcceptAllModal}>Open multi-accept tool</button>
      )
    }
  }

  renderPageButtons(page, suggested_merges) {
    if (parseInt(page) === 1 && suggested_merges.length < 100) {
      return (
        <div className="page-button-container">
          <div className="left-button-container">
            {this.renderAcceptAllButton(suggested_merges)}
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="page-button-container">
          <div className="left-button-container">
            {this.renderAcceptAllButton(suggested_merges)}
          </div>
          <div className="right-button-container">
            <button className="button new-button" onClick={this.handleNextPage} disabled={suggested_merges.length < 100}>Next Page</button>
            <button className="button new-button" onClick={this.handlePreviousPage} disabled={parseInt(page) < 2}>Previous Page</button>
          </div>
        </div>
      )
    }
  }

  render() {
    const { suggested_merges, page, loaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return (
        <p>Loading ...</p>
      )
    }

    if (loaded) {
      const filtered_suggested_merges = this.filterDeletingMerges(suggested_merges)

      return (
        <div>
          <PopupBox />
          <SetTitle title={`Suggested Merge People | People`} />

          <div className="settings">
            {this.renderPageButtons(page, filtered_suggested_merges)}
            {this.renderExceptionModal()}
            {this.renderAcceptAllConfirmationModal()}

            {filtered_suggested_merges.map((suggested_merge) => (
              this.renderSuggestedMerge(suggested_merge)
            ))}
            { this.emptySuggestedMerges(filtered_suggested_merges) }
            <div className="top-padding">
              {this.renderPageButtons(page, filtered_suggested_merges)}
            </div>


            { this.renderErrors() }
          </div>
        </div>
      );
    }
  }

  fetchContent(page) {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `?page=${page}`

    if (this.props.location !== "") {
      params += `&suggested_merges[location]=${this.props.location}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/suggested_merges${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ suggested_merges: data, flipped: false, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  componentDidMount() {
    this.setState({
      page: this.props.page
    })

    this.fetchContent(this.props.page)
  }

}

export default SuggestedMergeComponent;
