import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Locations extends Component {
  render() {
    const { locations, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="widget" style={{fontSize: "17px"}}>
          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Number of Devices</div>
              <div className="th">Last Contacted</div>
            </div>
            {locations.map((location) => (
              <Link to={`/location/${location.id}`} key={location.id} className="tr">
                <div className="td">{location.name}</div>
                <div className="td">{location.number_of_devices}</div>
                <div className="td">{location.last_contacted}</div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
  }

  state = {
    locations: [],
    loaded: false,
    error: null
  };

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0/locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ locations: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default Locations;