import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

import BtnFeedback from '../../images/btn_feedback_1075_725.png';
import BtnEvents from '../../images/btn_events_1075_725.png';
import BtnPeople from '../../images/btn_people_1075_725.png';
import BtnActions from '../../images/btn_actions_1075_725.png';

class ExportsIndex extends Component {
  render() {
    const baseLicense = localStorage.license === "base"

    return (
      <div>
        <SetTitle title={"Exports"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Exports</h2>
          <OrganisationName />

          <div className="widgets">

            <Link to={"/exports/events"}>
              <div className="widget exports-page" style={{ backgroundImage: `url(${BtnEvents})` }}>
                <h3 className="title">Export Events</h3>
              </div>
            </Link>

            <Link to={"/exports/feedback"}>
              <div className="widget exports-page" style={{ backgroundImage: `url(${BtnFeedback})` }}>
                <h3 className="title">Export Feedback</h3>
              </div>
            </Link>

            <Link to={"/exports/people"}>
              <div className="widget exports-page" style={{ backgroundImage: `url(${BtnPeople})` }}>
                <h3 className="title">Export People</h3>
              </div>
            </Link>

            {baseLicense && (
              <Link to={"/exports/outstanding-actions"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnActions})` }}>
                  <h3 className="title">Outstanding Actions</h3>
                </div>
              </Link>
            )}

          </div>
        </div>

      </div>
    );
  }
}

export default ExportsIndex;
