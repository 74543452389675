import React, { Component } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';

import ViewEvent from '../../components/exports/ViewEvent';
import { renderCheckOutWithContext, renderVisitedPeopleWithContext } from '../../utilities/Events.js'

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class PersonEvents extends Component {

  constructor(props) {
    super(props);

    this.handleEventPanel = this.handleEventPanel.bind(this);
  }

  state = {
    events: [],

    eventPanelToggle: false,
    eventId: null,
    loaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleEventPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      eventId: id,
      eventPanelToggle: true
    });
    this.props.history.push(`/people/${this.props.person_id}/events/${id}`)
  }

  emptyEvents() {
    if (this.state.events.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            There are no events over the last 7 days.
          </div>
        </div>
      )
    }
  }

  render() {
    const { events, loaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return (
        <div>
          {error["errors"].map((errors, index) => (
            <div className="warning-box" key={index}>{errors}</div>
          ))}
        </div>
      )
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      let person_id = this.props.person_id;

      return (
        <div>
          <h3 className="inline-block">Recent Events</h3>

          <div className="table big-table" data-testid="table">
            <div className="tr heading">
              <div className="th">Arrived</div>
              <div className="th">Left</div>
              <div className="th">Location</div>
              <div className="th">Visited People</div>
              <div className="th">Photo Taken?</div>
            </div>
            {events.map((event) => (
              <Link to={`/people/${person_id}/events/${event.id}`} onClick={this.handleEventPanel} id={event.id} key={event.id} className="tr event">
                <div className="td">{event.check_in}</div>
                {renderCheckOutWithContext(event)}
                <div className="td">{event.location}</div>
                {renderVisitedPeopleWithContext(event)}
                <div className="td center">{event.photo_taken ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
              </Link>
            ))}
          </div>
          {this.emptyEvents()}

          <Link to={`/exports/events/?search=${this.props.person_name}`} className="centered button hidden-link">Click here to show all events</Link>

          <SlidingPane isOpen={this.state.eventPanelToggle} title="View Event" width="55%"
            onRequestClose={() => {
              this.setState({ eventPanelToggle: false });
              this.setState({ eventId: null });
              this.props.history.push(`/people/${person_id}`);
              this.componentDidMount();
            }}>
            <ViewEvent id={this.state.eventId} />
          </SlidingPane>
        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.eventPanelToggle && props.match.params.event_id === undefined) {
      return {
        eventPanelToggle: false
      }
    }
    else if (state.eventPanelToggle && props.match.params.event_id !== state.eventId) {
      return {
        eventId: props.match.params.event_id,
        key: Math.random()
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.match.params.event_id === undefined) {
      return {
        eventPanelToggle: false,
        eventId: null
      }
    }
    else if (props.history.action === "POP" && props.match.params.event_id !== undefined) {
      return {
        eventPanelToggle: true,
        eventId: props.match.params.event_id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    let person_id = this.props.person_id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${person_id}/events`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ events: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(PersonEvents);
