import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';

import PlannedEventsCalendar from '../../components/planned-events/PlannedEventsCalendar';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class PlannedEventsIndex extends Component {
  constructor(props) {
    super(props);

    this.viewParser = this.viewParser.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleJumpToDate = this.handleJumpToDate.bind(this);
    this.handleJumpToDateTooltip = this.handleJumpToDateTooltip.bind(this);
  }

  state = {
    date: null,
    location: "",
    jumpToDate: false,
    jumpToDateTooltip: false,

    locations: [],
    locationsLoaded: false,
    key: 0
  };

  viewParser() {
    return this.props.match.params.view || "month"
  }

  handleLocationChange(event) {
    const value = event.target.value;

    if (value === "") {
      this.setState({
        location: value,
        date: null,
        key: Math.random()
      });

      this.props.history.push(`/planned-events/`);
    }
    else if (this.state.date === null) {
      const date = new Date();

      this.setState({
        location: value,
        date: date,
        key: Math.random()
      })

      this.props.history.push(`/planned-events/${value}/${this.viewParser()}/${this.formatDate(date)}`);
    }

    else {
      this.setState({
        location: value,
        key: Math.random()
      })

      this.props.history.push(`/planned-events/${value}/${this.viewParser()}/${this.formatDate(this.state.date)}`);
    }
  }

  handleDateChange(event) {
    const value = event.target.value;
    if (value.length > 0) {
      const date = new Date(value)

      this.setState({
        date: date,
        jumpToDate: false,
        jumpToDateTooltip: false,
        key: Math.random()
      })

      this.props.history.push(`/planned-events/${this.state.location}/${this.viewParser()}/${this.formatDate(date)}`);
    }
  }

  handleJumpToDate(event) {
    this.setState({jumpToDate: true})

    event.preventDefault();
  }

  handleJumpToDateTooltip(event) {
    if (event.target.value.length > 0) {
      this.setState({jumpToDateTooltip: true})
    }
    else {
      this.setState({jumpToDateTooltip: false})
    }
    event.preventDefault();
  }

  formatDate(date) {
    const day = ("0" + (date.getDate())).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    return this.props.match.params.view === ("week" || "day") ? `${year}-${month}-${day}` : `${year}-${month}`
  }

  renderDateSelect() {
    if (this.state.location !== "") {
      const hidden = this.state.jumpToDate ? "" : "hidden "
      return (
        <div className="selection-block">
          <div className="small button" onClick={this.handleJumpToDate}>Jump to Date:</div>
          <div className="jump-to-date-tooltip-container">
            <input className={hidden + "contextual-date"} type="date" name="date" onChange={this.handleJumpToDateTooltip} onBlur={this.handleDateChange} />
            {this.renderJumpToDateTooltip()}
          </div>
        </div>
      )
    }
  }

  renderJumpToDateTooltip() {
    if (this.state.jumpToDateTooltip) {
      return (
        <div className="jump-to-date-tooltip">Click away from the Date selector to confirm your pick when ready.</div>
      )
    }
  }

  render() {
    const { key, date, location, locations, locationsLoaded, unauthorized, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false) {
      return (
        <div>
          <SetTitle title={"Planned Events"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Planned Events</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SetTitle title={"Planned Events"} />
        <PopupBox />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Planned Events</h2>
          <OrganisationName />


          <div className="export-selection">
            <div className="selection-block">
              <label className="column">Location:</label>
              <select name="location" value={location} onChange={this.handleLocationChange} data-testid="select-locations">
                <option value="">Please select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            {this.renderDateSelect()}

          </div>

          <PlannedEventsCalendar key={key} date={date} location_id={location} />

        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.history.location.pathname.endsWith("/planned-events")) {
      return {
        location: "",
        date: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && (props.history.location.pathname.includes("/day"))) {
      return {
        dayPanelToggle: true,
        day: props.day
      }
    }
    else if (props.history.action === "POP" && props.day !== undefined) {
      return {
        dayPanelToggle: true,
        day: props.day
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep link params
    const { match: { params } } = this.props;
    if (params.location_id !== undefined) {
      // interpret date from URL or set default date
      let date = "";
      if (params.date === undefined) {
        date = new Date()
      }
      else if (params.date.length === 7) {
        date = new Date(params.date.replace("-", "/") + "/01")
        // if deep linking to this month, set date to today.
        if (date.getFullYear() === new Date().getFullYear() && date.getMonth() === new Date().getMonth()) {
          date = new Date()
        }
      }
      else {
        date = new Date(params.date.replace(new RegExp('-', 'g'), '/'))
        // if deep linking to this week, set date to today.
        if (params.view === "week" && date < new Date() && new Date() < new Date(date.setDate(date.getDate() + 7))) {
          date = new Date()
        }
      }
      this.setState({
        location: params.location_id,
        date: date,
        key: Math.random()
      })

      // add date to URL if set automatically
      if (params.date === undefined) {
        this.props.history.push(`/planned-events/${params.location_id}/${this.viewParser()}/${this.formatDate(date).slice(0, 7)}`)
      }
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })

        // automatically select Location when there is only 1 Location to choose from
        if (data.length === 1 && params.location_id === undefined) {
          const date = new Date();

          this.props.history.push(`/planned-events/${data[0].id}/${this.viewParser()}/${this.formatDate(date)}`);
          this.setState({
            location: data[0].id + "",
            date: date,
            key: Math.random()
          })
        }
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default PlannedEventsIndex;
