import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';
import ListOfImports from '../../components/imports/ListOfImports';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';

class ImportsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    location: "",
    refresh_location: "",

    locations: [],
    locationsLoaded: false
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random()
    });

    if (value === "") {
      value = "all"
    }

    this.props.history.push(`/imports?location=${value}`);
  }

  render() {
    const { location, locations, locationsLoaded, unauthorized, error } = this.state;
    const { match: { params } } = this.props;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false) {
      return (
        <div>
          <SetTitle title={"Imports"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Imports</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SetTitle title={"Imports"} />
        <PopupBox />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Imports</h2>
          <OrganisationName />

          <div>
            <label className="column">Location:</label>
            <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
              <option value="">All</option>
              {locations.map((location) => (
                <option value={location.id} key={location.id}>{location.name}</option>
              ))}
            </select>

            <ListOfImports id={params.id} selected_location={location} key={this.state.key} />
          </div>
        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "" && props.location.pathname.endsWith("/imports")) {
      let location = "all"

      props.history.replace(`/imports?location=${location}`);

      return {
        location: state.refresh_location,
        key: Math.random()
      }
    }
    else {
      // handle back/forward buttons
      if (props.history.action === "POP" && props.history.location.search !== "") {
        const params = new URLSearchParams(props.history.location.search)
        let location = params.get('location')

        return {
          location: location === "all" ? "" : location,
          key: Math.random()
        }
      }
      else {
        return null
      }
    }
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      var location = params.get('location') || "all"

      // set history in case location param is missing
      this.props.history.replace(`/imports?location=${location}`);

      this.setState({
        location: location === "all" ? "" : location,
        key: Math.random()
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default ImportsIndex;
