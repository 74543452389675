import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class OrganisationName extends Component {
  render() {
    const { organisation, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      localStorage.setItem('organisationName', organisation.name);

      return (
        <div>
          {organisation.name}
        </div>
      );
    }
  }

  state = {
    organisation: [],
    loaded: false,
    error: null,
    unauthorized: null
  };

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        localStorage.setItem('organisationName', data.name);
        localStorage.setItem('enrolmentCode', data.enrolment_code);
        this.setState({ organisation: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default OrganisationName;
