import React from 'react';

export function renderCheckOutWithContext(event) {
  // no context required if check out is device only
  if (event.reported_check_out_present === false){
    return <div className="td">{event.check_out}</div>
  }

  let title = "";

  if (event.device_check_out_present === false) {
    title = "This Left time was not sourced from a Device. It could have been caused by the Auto Checkout process or a manual Left time using this app."
  }
  else {
    title = "Both a Device Left time and a Reported Left time have been applied to this Event, but only the Device Left time is being displayed currently."
  }
  return <div className="td" title={title}>{event.check_out}*</div>
}

export function renderVisitedPeopleWithContext(event) {
  // no context required if no unassigned visited people
  if (event.unassigned_visited_people === 0) {
    return <div className="td center">{event.visited_people}</div>
  }
  else {
    return <div className="td center red-text" title={`This event has ${event.unassigned_visited_people} unassigned visited ${event.unassigned_visited_people === 1 ? "person" : "people"}.`}>{event.visited_people}</div>
  }
}
