import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import { getContrastYIQ } from '../../utilities/Generic.js'
import CreateDefaultShiftAssignment from '../../components/staff-fulfilment/CreateDefaultShiftAssignment';
import UpdateDefaultShiftAssignment from '../../components/staff-fulfilment/UpdateDefaultShiftAssignment';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class DefaultShiftAssignment extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    workStartsAt: 0,
    durationInHours: 24,

    editId: null,
    clickedRole: null,
    clickedDayOfWeek: null,
    clickedHour: null,
    clickedPerson: null,
    newPanelToggle: false,
    editPanelToggle: false,

    rolesLoaded: false,
    shiftsLoaded: false,

    roles: null,
    allocations: null,
    shifts: null
  }

  handleNewPanel(event) {
    const clickedRole = event.currentTarget.dataset.role_id;
    const clickedDayOfWeek = event.currentTarget.dataset.day_of_week
    const clickedHour = event.currentTarget.dataset.start_hour

    const role = this.state.roles.find(role => role.id === parseInt(clickedRole))
    const personHeight = event.target.offsetHeight / role.people.length
    const clickedPerson = role.people[(Math.floor(event.nativeEvent.layerY / personHeight))].id

    this.setState({
      newPanelToggle: true,
      clickedRole: clickedRole,
      clickedDayOfWeek: clickedDayOfWeek,
      clickedHour: clickedHour,
      clickedPerson: clickedPerson
    });
    this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/shift-assignment/template/new`)
    event.stopPropagation();
  }

  handleEditPanel(event) {
    var id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });
    this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/shift-assignment/template/${id}`)
    event.stopPropagation();
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/shift-assignment/`);
    this.componentDidMount();
  }

  render() {
    const { match: { params } } = this.props;
    const { workStartsAt, durationInHours, clickedDayOfWeek, clickedRole, clickedHour, clickedPerson, editId, rolesLoaded, shiftsLoaded, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false || shiftsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded && shiftsLoaded) {
      return (
        <div className="fulfilment">
          <SetTitle title={"Staff Fulfilment"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Staff Fulfilment</h2>
            <OrganisationName />

            <div>
              <div className="switch-buttons-container">
                <div className="switch-buttons default-or-weekly">
                  <div className="active">Default</div>
                  <Link to={`/staff-fulfilment/${params.location_id}/shift-assignment/weekly`} className="">Weekly</Link>
                </div>

                <div className="switch-buttons align-right">
                  <Link to={`/staff-fulfilment/${params.location_id}/required-allocation`}>Required Allocation</Link>
                  <Link to={`/staff-fulfilment/${params.location_id}/staff-fulfilment`}>Staff Fulfilment</Link>
                  <div className="active">Shift Assignnment</div>
                </div>
              </div>

              {
                this.renderDailyRotas()
              }
            </div>
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Default Shift Assignment" width="60%"
            onRequestClose={() => {
              this.setState({
                newPanelToggle: false,
                clickedRole: null,
                clickedDayOfWeek: null,
                clickedHour: null,
                clickedPerson: null
              });
              this.props.history.push(`/staff-fulfilment/${params.location_id}/working-pattern`);
              this.componentDidMount();
            }}>
            <CreateDefaultShiftAssignment origin="shift-assignment" workStartsAt={workStartsAt} dayDuration={durationInHours} clickedHour={clickedHour} day_of_week={clickedDayOfWeek} role_id={clickedRole} clickedPerson={clickedPerson} location_id={params.location_id} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Default Shift Assignment" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateDefaultShiftAssignment id={editId} closeEditPanel={this.closeEditPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} location_id={params.location_id} />
          </SlidingPane>

        </div>
      );
    }
  }

  renderDailyRotas() {
    let dates = []
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);

      dates.push(date)
    }

    return (
      <div className="rotas">
        {dates.map((date) => (
          this.renderIndividualDay(date)
        ))}
      </div>
    )
  }

  renderIndividualDay(date) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = daysOfWeek[date.getDay()]

    const { workStartsAt, durationInHours } = this.state;
    const workingHours = Array.from({length: durationInHours}, (_, i) => workStartsAt + i);

    const roles = this.state.roles;

    return (
      <div key={date} className="container">
        <div className="graph">

          {/* Header */}
          <div className="roles with-people">
            <div className="left">
              <div className="role-label">People</div>
            </div>
            <div className="right">
              <div className="title">{day}</div>
              <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
                {workingHours.map((hour, index) => (
                  <div key={hour} className="hour-title">{hour}</div>
                ))}
              </div>
            </div>
          </div>

          {roles.map((role) => (
            this.renderIndividualRole(role, workingHours, date)
          ))}
        </div>
      </div>
    )
  }

  renderIndividualRole(role, workingHours, date) {
    const jsDayOfWeek = date.getDay()
    const day_of_week = jsDayOfWeek === 0 ? 6 : jsDayOfWeek - 1;

    let shiftsToday = this.state.shifts[role.id] && this.state.shifts[role.id].filter(shift => shift.day_of_week === day_of_week) // day_of_week will need to be <= shift.start_day_of_week and >= shift.end_day_of_week

    if (!shiftsToday) {
      // THIS SHOULD BE if (role.people.length === 0)
      return (
        <div className="roles with-people" key={`role${role.id}`}>
          <div className="left">
            <div className="role-title-container">
              <div className="role-title sideways"><div style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div></div>
            </div>
            <div className="row-labels" style={{gridTemplateRows: `repeat(${role.people.length}, 1fr)`}}>
              {role.people.map((person) =>
                this.renderPersonName(person)
              )}
            </div>
          </div>
          <div className="right">
            <div className="assigned-hours">
            </div>
            <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
              {workingHours.map(hour => (
                <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={day_of_week}><wbr/></div>
              ))}
            </div>
          </div>
          <div className="new-shift">
            <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={day_of_week} data-role_id={role.id}>+</div>
          </div>
        </div>
      )
    }

    let shiftsTodayByPerson = Object.groupBy(shiftsToday, ({person_id}) => person_id)

    return (
      <div className="roles with-people" key={`role${role.id}`}>
        <div className="left">
          <div className="role-title-container">
            <div className="role-title sideways"><div style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div></div>
          </div>
          <div className="row-labels" style={{gridTemplateRows: `repeat(${role.people.length}, 1fr)`}}>
            {role.people.map((person) =>
              this.renderPersonName(person)
            )}
          </div>
        </div>
        <div className="right">
          <div className="assigned-hours">
            {role.people.map((person, index) => (
              <React.Fragment key={person.id}>
                {this.renderShiftsByPerson(shiftsTodayByPerson[person.id], index, role.people.length, role.color)}
              </React.Fragment>
            ))}
          </div>
          <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
            {workingHours.map(hour => (
              <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={day_of_week}><wbr/></div>
            ))}
          </div>
        </div>
        <div className="new-shift">
          <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={day_of_week} data-role_id={role.id}>+</div>
        </div>
      </div>
    )
  }

  renderPersonName(person) {
    let name = person.full_name
    if (name.length > 20) {
      return <div className="person" title={name} key={person.id}>{name.slice(0, 18)}...</div>

    }
    return <div className="person" key={person.id}>{name}</div>
  }

  renderShiftsByPerson(shiftsByPerson, index, number_of_people, role_color) {
    if (!shiftsByPerson) {
      return ""
    }

    return (
      shiftsByPerson.map((shift) => {
        const [startHours, startMinutes] = shift.start_time.split(":").map(Number);
        const [endHours, endMinutes] = shift.end_time.split(":").map(Number);

        const startMinute = startHours * 60 + startMinutes;
        const endMinute = endHours * 60 + endMinutes;

        return (
          <div key={shift.id} id={shift.id} title={`Starts at ${shift.start_time}, ends at ${this.minuteToTime(endMinute)}`} style={{ backgroundColor: role_color, clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, index, number_of_people)})` }} onClick={this.handleEditPanel}><wbr/></div>
        )
      })
    )
  }
  minuteToTime(minute) {
    return `${("0" + Math.floor(minute/60)).slice(-2)}:${("0" + minute % 60).slice(-2)}`
  }

  calculateCoordinates(startMinute, endMinute, index, number_of_people) {
    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const startPercentage = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const endPercentage = ((endMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;

    const topOfArea = 100 / number_of_people * index
    const bottomOfArea = (100 / number_of_people) * (index + 1)

    const spacing = (bottomOfArea - topOfArea) / 3
    const blockHeight = topOfArea + spacing
    const bottomOfBlock =  bottomOfArea - spacing

    let points = []

    points.push(`${startPercentage}% ${bottomOfBlock}%`)
    points.push(`${startPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${bottomOfBlock}%`)

    // Important note for debugging:
    //
    // The CSS attribute clip-path that we use to drive this feature has a wonky axis.
    //
    // If the calculations seem surprising, this is why:
    //
    //      0%                   100%
    //   0% ┌───────────────────────┐ 0%
    //      │                       │
    //      │                       │
    //    Y │                       │
    //    - │                       │
    //    a │                       │
    //    x │                       │
    //    i │                       │
    //    s │                       │
    //      │                       │
    //      │                       │
    // 100% └───────────────────────┘ 100%
    //      0%        X-axis      100%
    //

    return points
  }

  static getDerivedStateFromProps(props, state) {
    const id = props.match.params.id
    if (state.newPanelToggle && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else if (state.editPanelToggle && id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && id !== state.editId) {
      return {
        editId: id
      }
    }
    else if (state.editPanelToggle === false && id !== undefined) {
      return {
        editPanelToggle: true,
        editId: id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/shift-assignment")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${this.props.match.params.location_id}/roles_and_people`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_shift_assignments/?location_id=${this.props.match.params.location_id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ shifts: data, shiftsLoaded: true })
      })
      .catch(error => this.setState({ error, shiftsLoaded: true }))
  }
}

export default DefaultShiftAssignment;
